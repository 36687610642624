import React from "react";

const StatusModal = ({ header, isOpen, goNext, retake, success }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50">
      <div className="p-6 rounded  w-[500px] flex flex-col justify-center items-center">
        <div className="flex items-center mb-2 ">
          {success ? (
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4993 30.0834C7.44886 30.0746 0.924857 23.5506 0.916016 15.5001V15.2084C1.07633 7.19422 7.67479 0.811711 15.6899 0.918058C23.705 1.02441 30.1318 7.57973 30.0795 15.5954C30.0271 23.611 23.5152 30.0818 15.4993 30.0834ZM8.8056 14.9022L6.74935 16.9584L12.5827 22.7918L24.2493 11.1251L22.1931 9.05427L12.5827 18.6647L8.8056 14.9022Z"
                fill="#5B85D8"
              />
            </svg>
          ) : (
            <svg
              width="31"
              height="30"
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4994 29.5834C11.6306 29.5869 7.9192 28.0516 5.18354 25.3159C2.44788 22.5802 0.912541 18.8689 0.916022 15.0001V14.7084C1.0353 8.86373 4.63156 3.65447 10.0545 1.4712C15.4774 -0.71206 21.6799 0.55221 25.8156 4.68383C29.99 8.85478 31.2394 15.1304 28.9807 20.582C26.722 26.0337 21.4004 29.5869 15.4994 29.5834ZM15.4994 17.0563L19.2764 20.8334L21.3327 18.7772L17.5556 15.0001L21.3327 11.223L19.2764 9.16674L15.4994 12.9438L11.7223 9.16674L9.66603 11.223L13.4431 15.0001L9.66603 18.7772L11.7223 20.8334L15.4994 17.0578V17.0563Z"
                fill="#D80808"
              />
            </svg>
          )}

          <h2 className="text-black font-bold text-xl ms-4">
            {header}
            <span>{success ? "Complete" : "Unsuccessful"}</span>
          </h2>
        </div>
        <div className="w-[250px]">
          <div className={"flex justify-between items-center"}>
            <button
              onClick={retake}
              className={
                success
                  ? `px-2 py-1 w-[200px] text-black border-2 border-[gray] rounded mr-2`
                  : `px-2 py-1 bg-lightblue2 w-[200px] text-white rounded mr-2`
              }
            >
              <div className="flex items-center justify-center">
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5.75C2 3.92525 3.02575 2.35425 4.5 1.648C5.04608 1.3857 5.64419 1.24967 6.25 1.25C8.59725 1.25 10.5 3.26475 10.5 5.75"
                    stroke="#212B36"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4.5L2 5.75L0.5 3.75"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="ms-1">Retake test</span>
              </div>
            </button>
            {success && (
              <button
                onClick={goNext}
                className={`px-2 py-1 bg-lightblue2 w-20 text-white rounded mr-2`}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
