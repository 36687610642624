import React from "react";

function TikTokIcon({ width, height }) {
  return (
    <svg
      width={width ?? "48"}
      height={height ?? "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3534 17.3269C37.4401 19.5413 41.2215 20.8442 45.3055 20.8442V12.9572C44.5326 12.9574 43.7617 12.8765 43.0055 12.7157V18.9239C38.9218 18.9239 35.1409 17.621 32.0534 15.4068V31.5017C32.0534 39.5533 25.5496 46.0798 17.5272 46.0798C14.5338 46.0798 11.7516 45.1716 9.44043 43.6141C12.0783 46.3208 15.7569 48 19.8267 48C27.8496 48 34.3537 41.4734 34.3537 33.4215V17.3269H34.3534ZM37.1907 9.36999C35.6133 7.64045 34.5775 5.40535 34.3534 2.93435V1.91992H32.1738C32.7224 5.06056 34.5938 7.74374 37.1907 9.36999ZM14.5146 37.4356C13.6333 36.2759 13.157 34.8572 13.1591 33.3985C13.1591 29.716 16.1339 26.7302 19.804 26.7302C20.488 26.7301 21.1679 26.8352 21.8197 27.0427V18.9795C21.058 18.8748 20.2892 18.8303 19.5207 18.8466V25.1226C18.8684 24.915 18.1882 24.8096 17.504 24.8103C13.8339 24.8103 10.8593 27.7957 10.8593 31.4787C10.8593 34.0828 12.3462 36.3373 14.5146 37.4356Z"
        fill="#FF004F"
      />
      <path
        d="M32.0529 15.4066C35.1404 17.6208 38.9213 18.9237 43.005 18.9237V12.7156C40.7255 12.2283 38.7075 11.0328 37.1903 9.36999C34.5931 7.74358 32.722 5.0604 32.1733 1.91992H26.4482V33.4212C26.4352 37.0936 23.4655 40.0672 19.8032 40.0672C17.6451 40.0672 15.7279 39.0348 14.5136 37.4356C12.3454 36.3373 10.8585 34.0826 10.8585 31.4788C10.8585 27.7962 13.8331 24.8104 17.5032 24.8104C18.2064 24.8104 18.8842 24.9203 19.5199 25.1228V18.8468C11.6384 19.0102 5.2998 25.473 5.2998 33.4214C5.2998 37.3892 6.87827 40.9861 9.44013 43.6143C11.7513 45.1716 14.5335 46.08 17.5268 46.08C25.5494 46.08 32.0531 39.5531 32.0531 31.5017V15.4066H32.0529Z"
        fill="black"
      />
      <path
        d="M43.0051 12.7156V11.037C40.9495 11.0401 38.9343 10.4624 37.1903 9.36987C38.7342 11.0661 40.7671 12.2357 43.0051 12.7156ZM32.1734 1.91997C32.1211 1.61982 32.0809 1.3177 32.053 1.01443V0H24.148V31.5016C24.1354 35.1735 21.1658 38.1471 17.5033 38.1471C16.428 38.1471 15.4128 37.891 14.5137 37.4358C15.7279 39.0349 17.6452 40.0671 19.8033 40.0671C23.4652 40.0671 26.4354 37.0938 26.4482 33.4214V1.91997H32.1734ZM19.5203 18.8468V17.0598C18.8598 16.9692 18.1938 16.9237 17.5271 16.924C9.50383 16.9239 3 23.4508 3 31.5016C3 36.5491 5.55612 40.9974 9.44034 43.614C6.87848 40.986 5.30002 37.3889 5.30002 33.4213C5.30002 25.473 11.6385 19.0102 19.5203 18.8468Z"
        fill="#00F2EA"
      />
    </svg>
  );
}

export default TikTokIcon;
