import React from "react";

function Published({ handleGoToAnalytics }) {
  return (
    <>
      <div className="w-full flex justify-center my-20">
        <div className="flex flex-col gap-2 p-5 border rounded w-[70%] justify-center shadow drop-shadow items-center my-2">
          <h1 className="font-bold">Votre compagne a été sauvegardée au nom</h1>
          <div className="w-[50%] mb-10">
            <p className="text-md">Compagne 1</p>
          </div>
          <h1 className="font-bold mb-5">
            Vos annonces ont été publiées avec succès
          </h1>
          <button
            className="px-2 py-1 bg-lightblue2 text-white rounded mt-2 mb-2"
            onClick={handleGoToAnalytics}
          >
            Voir Analytiques
          </button>
        </div>
      </div>
    </>
  );
}

export default Published;
