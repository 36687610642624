import React from "react";
import "./index.css";

const VideoRecordingIcon = ({ isRecording }) => {
  return (
    <div className="video-recording-icon">
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={isRecording ? "recording" : ""}
      >
        <circle cx="12" cy="12" r="8" stroke="#FF0000" strokeWidth="2" />
        {isRecording && (
          <>
            <circle cx="12" cy="12" r="6" fill="#FF0000" />
            <circle cx="12" cy="12" r="4" fill="#FFF" />
            <circle cx="12" cy="12" r="2" fill="#FF0000" />
          </>
        )}
      </svg>
    </div>
  );
};

export default VideoRecordingIcon;
