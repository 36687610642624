import React from "react";

function AddPersonIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 23.75H2.5C2.5 19.6079 5.85786 16.25 10 16.25C14.1421 16.25 17.5 19.6079 17.5 23.75H15C15 20.9886 12.7614 18.75 10 18.75C7.23858 18.75 5 20.9886 5 23.75ZM23.75 20H21.25V16.25H17.5V13.75H21.25V10H23.75V13.75H27.5V16.25H23.75V20ZM10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C14.9966 12.76 12.76 14.9966 10 15ZM10 7.5C8.63424 7.50138 7.52236 8.59856 7.50279 9.96418C7.48322 11.3298 8.56321 12.4584 9.92836 12.4989C11.2935 12.5394 12.4385 11.4769 12.5 10.1125V10.6125V10C12.5 8.61929 11.3807 7.5 10 7.5Z"
        fill="#2E3A59"
      />
    </svg>
  );
}

export default AddPersonIcon;
