import {
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_EXISTS,
  FIELDS_ERRORS,
  IS_LOADING,
} from "./actionTypes";
import axios from "axios";
import Swal from "sweetalert2";
export const regSuccess = (payload) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: payload,
  };
};
export const isLoading = () => {
  return {
    type: IS_LOADING,
    payload: false,
  };
};
export const regfailure = (error) => {
  return {
    type: REGISTER_USER_EXISTS,
    payload: error,
  };
};
export const fieldError = (error) => {
  return {
    type: FIELDS_ERRORS,
    payload: error,
  };
};
export const registerUser =
  ({ firstName, lastName, email, password, inviteToken: token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      firstName,
      lastName,
      email,
      password,
    });
    dispatch(isLoading(true));
    try {
      await axios
        .post(
          `${process.env.REACT_APP_AUTH_SERVICE}/api/register`,
          body,
          config
        )
        .then((res) => {
          Swal.fire({
            title: "Verification du compte",
            text: "Veuillez vérifier votre boîte mail",
            type: "cancel",
            confirmButtonColor: "#4368B1",
            confirmButtonText:
              '<a target="_blank" href="https://mail.google.com/">Click Here</a>',
            showCloseButton: true,
          });
          dispatch(regSuccess(res.data));
          dispatch(isLoading(false));
        });
    } catch (error) {
      dispatch(isLoading(false));
      if (error.response.status === 400) {
        dispatch(fieldError(error.response.data.errors));
      } else if (error.response.status === 401) {
        dispatch(regfailure(error.response.data.error));
        Swal.fire("Error", error.response.data.error, "error");
      }
      dispatch(isLoading(false));
    }
  };

export const registerInvitedUser =
  ({ firstName, lastName, email, password, invitedBy, role, inviteToken: token }) =>
    async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        userId: invitedBy._id,
        role,
        token
      });
      dispatch(isLoading(true));
      try {
        await axios
          .post(
            `${process.env.REACT_APP_AUTH_SERVICE}/api/register/invited-user`,
            body,
            config
          )
          .then((res) => {
            Swal.fire({
              title: "Verification du compte",
              text: "Veuillez vérifier votre boîte mail",
              type: "cancel",
              confirmButtonColor: "#4368B1",
              confirmButtonText:
                '<a target="_blank" href="https://mail.google.com/">Click Here</a>',
              showCloseButton: true,
            });
            dispatch(regSuccess(res.data));
            dispatch(isLoading(false));
          });
      } catch (error) {
        dispatch(isLoading(false));
        if (error.response.status === 400) {
          dispatch(fieldError(error.response.data.errors));
        } else if (error.response.status === 401) {
          dispatch(regfailure(error.response.data.error));
          Swal.fire("Error", error.response.data.error, "error");
        }
        dispatch(isLoading(false));
      }
    };
