// import { Fragment, useEffect, useState } from "react";
// import axios from "axios";
// const StatCubes = () => {
//   const [carNumber, setCartNumber] = useState("");
//   // const [sellers, setSellers] = useState("");
//   // const [buyers, setBuyers] = useState("");
//   const getCarNumber = async () => {
//     let carNumber = {
//       method: "get",
//       url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/getUserAds?userIds`,
//       headers: {
//         "x-auth-token": localStorage.getItem("token"),
//       },
//     };

//     await axios(carNumber)
//       .then(function (response) {
//         setCartNumber(response.data.count);
//       })
//       .catch(function (error) {});
//   };
//   // const getSellers = async () => {
//   //   let sellers = {
//   //     method: "get",
//   //     url: `${process.env.REACT_APP_STATISTIC_SERVICE}/api/statistic/sellers`,

//   //     headers: {
//   //       "x-auth-token": `${localStorage.getItem("token")}`,
//   //     },
//   //   };
//   //   const res = await axios(sellers);
//   //   setSellers(res.data);
//   // };
//   // const getBuyers = async () => {
//   //   let sellers = {
//   //     method: "get",
//   //     url: `${process.env.REACT_APP_STATISTIC_SERVICE}/api/statistic/buyers`,
//   //     headers: {
//   //       "x-auth-token": `${localStorage.getItem("token")}`,
//   //     },
//   //   };
//   //   const res = await axios(sellers);
//   //   setBuyers(res.data);
//   // };
//   useEffect(() => {
//     // getSellers();
//     // getBuyers();
//     getCarNumber();
//   }, [carNumber]);

//   return (
//     <Fragment>
//       <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8 w-full md:py-3 px-5 md:p-0">
//         {[
//           { name: "Vendeurs", value: 0 },
//           { name: "Voitures inspectées", value: `${carNumber}` },
//           { name: "Voitures vendues", value: 0 },
//           { name: "Acheteurs", value: 0 },
//         ].map((cube, key) => {
//           return (
//             <div
//               key={key}
//               className="rounded-md flex-grow flex items-center justify-center bg-white0 border border-grey2 lg:h-[150px] xxl:h-[150px] h-[150px]"
//             >
//               <div className="flex flex-col items-center  ">
//                 <h2 className="text-[13px] md:text-[16px] lg:text-[19px] font-bold text-center">
//                   {cube.name}
//                 </h2>

//                 <h1 className="text-[13px]  md:text-[30px]  lg:text-[40px] font-bold">
//                   {cube.value}
//                 </h1>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </Fragment>
//   );
// };
// export default StatCubes;

import React from "react";

const StatCubes = ({ title, value, change, period }) => (
  <div className="bg-white p-4 rounded-lg shadow rounded-md flex-grow bg-white0 border border-grey2 lg:h-[150px] xxl:h-[150px] h-[150px] m-1">
    {/* // <div className="bg-white p-4 rounded-lg shadow rounded-md flex-grow bg-white0  sm:bg-white0 sm:border sm:border-grey2 lg:h-[150px] xxl:h-[150px] h-[150px] "> */}
    <div className="flex flex-grow items-center justify-between">
      {/* <p className="text-sm text-gray-500">{title}</p> */}
      <div>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="8" fill="#F7F8FC" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.7615 25.9168H14.805C12.2496 25.9168 10.2893 24.9939 10.8461 21.2791L11.4945 16.2448C11.8377 14.3913 13.0201 13.6819 14.0574 13.6819H22.5395C23.5921 13.6819 24.7058 14.4447 25.1024 16.2448L25.7508 21.2791C26.2237 24.5743 24.3168 25.9168 21.7615 25.9168Z"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.8759 13.4988C21.8759 11.5104 20.264 9.89847 18.2756 9.89847V9.89847C17.3181 9.89441 16.3984 10.2719 15.7199 10.9476C15.0414 11.6232 14.66 12.5413 14.66 13.4988V13.4988"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.747 17.2517H20.7089"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.8881 17.2517H15.85"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="flex">
        <p className="text-[12px] text-[#BEC0CA] px-2">{period}</p>
        {/* <svg
          sidebar-toggle-item="true"
          className="w-6 h-7 "
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            fill="#4368B1"
          />
        </svg> */}
        <svg
          className="w-4 h-4 "
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6L8 10L12 6"
            stroke="#BEC0CA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <p className="text-sm text-[#8B8D97] mt-5">{title}</p>
    <div className="flex flex-grow items-center justify-start mt-2">
      <p className="text-[20px] font-semibold">{value}</p>
      <p className="text-[12px] text-gray-400  ml-3 ">{change}</p>
    </div>
  </div>
);
export default StatCubes;
