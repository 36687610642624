import React from "react";

function LabelSvg() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_89_2110)">
        <path
          d="M34.9859 27.4782L38.9257 35.5606C39.1151 35.949 39.0947 36.4104 38.8726 36.7795C38.6498 37.1482 38.2576 37.3729 37.8346 37.3729H31.908L28.3522 42.2368C28.1211 42.554 27.7585 42.7376 27.3767 42.7376C26.8698 42.7376 26.4703 42.4272 26.2847 42.0457L22.5721 34.4279C27.5916 33.9355 32.017 31.3244 34.9859 27.4782Z"
          fill="#4368B1"
        />
        <path
          d="M2.75463 35.5606L6.69442 27.4782C9.66326 31.3244 14.0887 33.9355 19.1082 34.4279L15.3953 42.0457C15.21 42.4272 14.8105 42.7376 14.3035 42.7376C13.9218 42.7376 13.5589 42.554 13.3278 42.2368L9.77227 37.3729H3.84573C3.4227 37.3729 3.0305 37.1482 2.8077 36.7795C2.58554 36.4104 2.5652 35.949 2.75463 35.5606Z"
          fill="#4368B1"
        />
        <path
          d="M18.7968 12.3241L20.8395 8.97644L22.8815 12.3241C23.049 12.5987 23.3138 12.7959 23.6198 12.8745L27.354 13.8328L24.8819 16.86C24.6792 17.1081 24.5781 17.4273 24.6 17.7504L24.866 21.6899L21.2959 20.2135C21.1262 20.1434 20.7791 20.0498 20.3834 20.2135L16.8129 21.6899L17.0786 17.7504C17.1002 17.4273 16.9992 17.1081 16.7964 16.86L14.3243 13.8328L18.0585 12.8745C18.3646 12.7959 18.6293 12.5987 18.7968 12.3241Z"
          fill="#4368B1"
        />
        <path
          d="M5.24014 16.0044C5.24014 7.17949 12.2384 -6.10352e-05 20.8404 -6.10352e-05C29.4424 -6.10352e-05 36.4407 7.17949 36.4407 16.0044C36.4407 24.8289 29.4424 32.0088 20.8404 32.0088C12.2384 32.0088 5.24014 24.8292 5.24014 16.0044ZM11.202 13.9075L14.6103 18.081L14.2442 23.5126C14.2153 23.943 14.4041 24.3584 14.7441 24.6121C15.0845 24.8654 15.5279 24.9215 15.9179 24.7604L20.8404 22.7248L25.7629 24.7604C25.8948 24.8149 26.4202 24.9968 26.9367 24.6121C27.2767 24.3584 27.4655 23.943 27.4366 23.5126L27.0698 18.081L30.4782 13.9078C30.7484 13.5768 30.8336 13.1269 30.7036 12.7167C30.5736 12.3065 30.2468 11.9935 29.8388 11.8888L24.6906 10.5676L21.8749 5.9522C21.6518 5.58668 21.2609 5.36463 20.8404 5.36463C20.4199 5.36463 20.029 5.58668 19.8059 5.9522L16.9896 10.5676L11.8414 11.8888C11.4333 11.9935 11.1066 12.3065 10.9766 12.7167C10.8466 13.1269 10.9318 13.5768 11.202 13.9075Z"
          fill="#4368B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_89_2110">
          <rect
            width="41.6583"
            height="42.7376"
            fill="white"
            transform="matrix(-1 0 0 1 41.6584 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabelSvg;
