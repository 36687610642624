import * as type from "./types";
import axios from "axios";
import { profileSuccess } from "../profile/actions";
export const loginSuccess = (isLoggedIn) => {
  return {
    type: type.LOGIN_SUCCESS,
    isLoggedIn: isLoggedIn,
  };
};

export const loginFailure = (error) => {
  return {
    type: type.LOGIN_FAILURE,
    error: error,
  };
};
export const logOutDispatch = (isLoggedIn) => {
  return {
    type: type.LOGOUT,
    isLoggedIn: isLoggedIn,
  };
};

export const authentication = (payload) => {
  return async (dispatch) => {
    const parseJwt = (token) => {
      if (!token) {
        return;
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    };
    await axios
      .post(
        `${process.env.REACT_APP_AUTH_SERVICE}/api/auth`,

        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let verified = parseJwt(response.data.token)?.user?.verified;
        if (verified) {
          localStorage.setItem("token", response.data.token);
          dispatch(loginSuccess(true));
          axios
            .get(`${process.env.REACT_APP_AUTH_SERVICE}/api/auth`, {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": localStorage.getItem("token"),
              },
            })
            .then((response) => {
              dispatch(profileSuccess(response.data));
            });
        }
      })
      .catch((err) => {
        dispatch(loginFailure(err.response.data?.errors));
      });
  };
};

export const signOut = () => {
  localStorage.clear();
};
