import React from "react";

function NoteIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 16C11 15.4477 11.4477 15 12 15H20C20.5523 15 21 15.4477 21 16C21 16.5523 20.5523 17 20 17H12C11.4477 17 11 16.5523 11 16Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 20C11 19.4477 11.4477 19 12 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H12C11.4477 21 11 20.5523 11 20Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.58579 4.58579C5.96086 4.21071 6.46957 4 7 4H25C25.5304 4 26.0391 4.21071 26.4142 4.58579C26.7893 4.96086 27 5.46957 27 6V25C27 26.0609 26.5786 27.0783 25.8284 27.8284C25.0783 28.5786 24.0609 29 23 29H9C7.93913 29 6.92172 28.5786 6.17157 27.8284C5.42143 27.0783 5 26.0609 5 25V6C5 5.46957 5.21071 4.96086 5.58579 4.58579ZM7 6L7 25C7 25.5304 7.21071 26.0391 7.58579 26.4142C7.96086 26.7893 8.46957 27 9 27H23C23.5304 27 24.0391 26.7893 24.4142 26.4142C24.7893 26.0391 25 25.5304 25 25V6L7 6Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 2C10.5523 2 11 2.44772 11 3V7C11 7.55228 10.5523 8 10 8C9.44772 8 9 7.55228 9 7V3C9 2.44772 9.44772 2 10 2Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2C16.5523 2 17 2.44772 17 3V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V3C15 2.44772 15.4477 2 16 2Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 2C22.5523 2 23 2.44772 23 3V7C23 7.55228 22.5523 8 22 8C21.4477 8 21 7.55228 21 7V3C21 2.44772 21.4477 2 22 2Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default NoteIcon;
