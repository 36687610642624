import { Slider } from "@material-tailwind/react";
import React, { useState } from "react";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import SnapchatIcon from "./icons/SnapchatIcon";
import TikTokIcon from "./icons/TikTokIcon";
import NeedHelp from "../../needHelp/NeedHelp";

const platforms = [
  {
    label: "FB",
    component: <FacebookIcon width={24} height={24} />,
    value: 2.3,
    disabled: false,
  },
  {
    label: "IN",
    component: <InstagramIcon width={24} height={24} />,
    value: 2.0,
    disabled: false,
  },
  {
    label: "Tk",
    component: <TikTokIcon width={24} height={24} />,
    value: 2.0,
    disabled: true,
  },
  {
    label: "SP",
    component: <SnapchatIcon width={24} height={24} />,
    value: 2.0,
    disabled: true,
  },
];
function BudgetConfigure() {
  const [budget, setBudget] = useState({
    budget: 50,
    duration: 50,
  });

  const handleChangeBudget = (key) => (e) =>
    setBudget((prevBudget) => {
      const updatedBudget = { ...prevBudget };
      updatedBudget[key] = e.target.value;
      return updatedBudget;
    });

  return (
    <>
      <NeedHelp />
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-center mb-4 mt-4">
          Définir votre budget global
        </h1>
      </div>
      <div>
        <div className="flex items-center mb-5 ">
          <p className="text-textgrey1 w-2/6 text-right mr-4">Budget:</p>
          <div className=" w-2/6 p-2 text-center flex flex-col gap-2 justify-center -mt-4">
            <span className="text-sm">{budget.budget} €</span>
            <Slider
              color="indigo"
              value={budget.budget}
              step={0.1}
              onChange={handleChangeBudget("budget")}
            />
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-textgrey1 w-2/6 text-right mr-4">Duration:</p>
          <div className=" w-2/6 p-2 text-center flex flex-col gap-2 justify-center -mt-4">
            <span className="text-sm">{budget.duration} jours</span>
            <Slider
              color="indigo"
              step={1}
              value={budget.duration}
              onChange={handleChangeBudget("duration")}
            />
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-textgrey1 w-2/6 text-right mr-4">
            Budget estimé par jour :
          </p>
          <div className=" w-2/6 p-2 text-center">
            <input
              disabled
              className="rounded p-1 border-grey3 border-2 text-[#686868]"
              value={"2.00€"}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-center mb-4 mt-4">
            Choisir la répartition du budget par jour sur les plateformes qui
            bénéficierons du sponsoring
          </h1>
        </div>
        <div className="w-full justify-center flex items-center">
          <div className="flex w-[50%] justify-around items-center">
            <div className="flex flex-col justify-start gap-2">
              {platforms.map((platform, index) => (
                <div key={index} className="flex items-center gap-2">
                  {platform.component}
                  <input
                    disabled={platform.disabled}
                    className="rounded-xl p-1 border-grey3 border-2 text-[#686868] w-[100px]"
                    value={
                      !platform.disabled
                        ? platform.value.toFixed(2) + "€"
                        : undefined
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="flex flex-col gap-2 p-5 border rounded w-[70%] justify-center shadow drop-shadow items-center my-2">
          <h1 className="font-bold">Sauvegarder votre configuration</h1>
          <p className="text-md">
            Donner un nom à votre configuration de budget
          </p>
          <input type="text" placeholder="Budget 1" className="px-1" />
          <button className="px-2 py-1 bg-lightblue2 text-white rounded">
            Sauvegarder
          </button>
        </div>
      </div>
    </>
  );
}

export default BudgetConfigure;
