import * as type from "./actionTypes";
const formInitState = {
  overviewImgs: [],
  adNumber: "",
  make: "",
  model: "",
  price: "",
  overviewYear: "",
  location: "",
  km: "",
  seat: "",
  gearBox: "",
  fuel: "",
  engineDisplacement: "",
  outside: "",
  inside: "",
  numberCV: "",
  averageConsumption: "",
  immatricule: "",
  functionalities: [],
};
const initialState = {
  cars: [],
  totalCarsCount: null,
  success: {},
  car: {},
  analysedCarImages: [],
  analysedCarImagesLading: false,
  processedImg: [],
  damagedCarImages: [],
  tempoDamagedCarImages: [],
  analysedCarImagesError: null,
  retries: 3,
};
const formReducer = (state = formInitState, action) => {
  switch (action.type) {
    case "INIT_FORM":
      return {
        ...state,
        ...action.payload,
      };
    case "OVERVIEW_IMGS":
      return {
        ...state,
        overviewImgs: [...state.overviewImgs, action.payload],
      };
    case "REMOVE_IMG":
      return {
        ...state,
        overviewImgs: state.overviewImgs.filter(
          (image) => image.filename !== action.payload
        ),
      };

    case "ADD_CAR_NUMBER":
      return {
        ...state,
        adNumber: action.adNumber + 1,
      };
    case "UPDATE_FORM":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "RESET_FORM":
      return formInitState;
    default:
      return state;
  }
};

const cars = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_CARS:
      state = {
        ...state,
        cars: action.cars,
      };
      break;
    case type.GET_CAR:
      state = {
        ...state,
        car: action.car,
      };
      break;

    case type.GET_CARS_COUNT:
      state = {
        ...state,
        totalCarsCount: action.totalCarsCount,
      };
      break;

    case type.ADD_CAR_SUCCESS:
      state = {
        ...state,
        success: action.success,
      };
      break;
    case type.EDIT_CAR_SUCCESS:
      state = {
        ...state,
        success: action.success,
      };
      break;

    case type.DAMAGED_CAR_IMAGE_TEMPORARY:
      state = {
        ...state,
        tempoDamagedCarImages: [
          ...state.tempoDamagedCarImages,
          action.tempoDamagedCarImages,
        ],
      };
      break;
    case type.DAMAGED_CAR_IMAGE:
      state = {
        ...state,
        damagedCarImages: action.damagedCarImages,
      };
      break;
    case type.CLEAR_DAMAGED_CAR_IMAGE:
      state = {
        ...state,
        damagedCarImages: [],
      };
      break;
    case type.UPDATE_DAMAGED_CAR_IMAGE:
      state = {
        ...state,
        damagedCarImages: [...state.damagedCarImages, action.damagedCarImages],
      };
      break;
    case type.DELETE_DAMAGED_CAR_IMAGE:
      return {
        // returning a copy of orignal state
        ...state, //copying the original state
        damagedCarImages: state.damagedCarImages.filter(
          (damagedCarImages) =>
            damagedCarImages.filename !== action.payload.filename
        ),
        // returns a new filtered todos array
      };
    case type.UPLOAD_START_DAMAGED_CAR_IMAGE:
      return {
        ...state,
        damagedCarImages: state.damagedCarImages.find(
          (item) => item.filename === action.payload
        )
          ? state.damagedCarImages.map((item) =>
              item.filename === action.payload
                ? { filename: action.payload }
                : item
            )
          : [...state.damagedCarImages, { filename: action.payload }],
      };
    case type.UPLOAD_FINISH_DAMAGED_CAR_IMAGE:
      return {
        ...state,
        damagedCarImages: state.damagedCarImages.map((item) =>
          item.filename === action.payload.filename
            ? { ...item, image_link: action.payload.image_link }
            : item
        ),
      };
    case type.EDIT_CDD_IMAGE:
      state = {
        ...state,
        processedImg: [...state.processedImg, action.processedImg],
      };
      break;

    case type.ANALYZED_CARS_IMAGES:
      state = {
        ...state,
        analysedCarImages: action.analysedCarImages,
        analysedCarImagesLading: false,
      };
      break;
    case type.ANALYZED_CARS_IMAGES_LOADING:
      state = {
        ...state,
        analysedCarImagesLading: true,
      };
      break;
    case type.ANALYZED_CARS_IMAGES_ERROR:
      state = {
        ...state,
        analysedCarImagesError: action.analysedCarImagesError,
      };
      break;
    case type.CLEARSTORE:
      // eslint-disable-next-line default-case
      switch (action.payload) {
        case "ADDED_CAR":
          state = { ...state, addCarData: {} };
          break;
        case "EDITED_CAR":
          state = { ...state, car: {} };
          break;
        case "ABORTED":
          state = { ...state, car: {}, addCarData: {} };
          break;
      }

      break;
    case type.REMAINING_ANALYSIS_RETRY_NUMBER:
      state = {
        ...state,
        retries: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { formReducer, cars };
