import React, { Fragment } from "react";
import Analytics from "../../Components/analytics";

function DisplayAnalytics() {
  return (
    <Fragment>
      <Analytics />
    </Fragment>
  );
}

export default DisplayAnalytics;
