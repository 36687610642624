import React, { useState, useEffect } from "react";

const TextCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative w-full max-w-xl mx-auto">
      <div className="pt-8 px-8 bg-[#5B85D826] rounded-md">
        <p className=" text-center text-l font-semibold text-darkblue1">
          {slides[currentSlide]}
        </p>
        <p className="text-tiny cursor-pointer text-center p-1">{`[...] En savoir plus`}</p>
      </div>

      <div className="flex justify-center mt-4 space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-2 h-2 rounded-full ${
              currentSlide === index ? "bg-darkblue1" : "bg-gray-400"
            } focus:outline-none`}
          />
        ))}
      </div>
    </div>
  );
};
export default TextCarousel;
