import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RiseLoader from "react-spinners/RiseLoader";
import * as type from "../../Redux/users/actionTypes";
import WebUsersList from "../../Components/user/webUsersList";
import {UserDetailDialog} from "../../Components/user/detailDialog";
import {UserRole} from "../../Constants/users";
import MobileList from "../../Components/common/mobileList";
import checkIcon from "../../Assets/check-circle.svg";
import closeIcon from "../../Assets/close-circle.svg";
import searchIcon from "../../Assets/search.svg";

function UserList() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile).profile;
  const users = useSelector((state) => state.users).users;
  const totalCount = useSelector((state) => state.users).totalCount;
  const authUser = useSelector((state) => state.profile).profile;
  const [filteredUsers, setFilteredUsers] = useState(users);
  const id = user._id;

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(null);

  const isLastPage = (page, totalPages) => page === totalPages;
  const totalPages = Math.ceil(totalCount / pageSize);
  const isLast = isLastPage(page, totalPages);
  const riseLoaderCss = {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-100px",
  };

  const mobileColumns = [
    { key: 'email', label: 'Email', type: 'main' },
    { key: 'role', label: 'Role', type: 'sub' },
    { key: 'firstName', label: 'First Name', type: 'collapse' },
    { key: 'lastName', label: 'Last Name', type: 'collapse' },
    { key: 'verified', label: "Verified", type: 'collapse', render: (value) => <img src={value ? checkIcon : closeIcon} alt="verified-icon" /> }
  ];

  const getUsers = async () => {
    setIsLoading(true);

    const { roles, verified } = filters;

    const cancelTokenSource = axios.CancelToken.source();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTH_SERVICE}/api/users?pageSize=${pageSize}&page=${page}${
        roles?.length ? `&roles=${roles.join(',')}` : ''
      }${
        verified?.length ? `&verified=${verified.join(',')}` : ''
      }${
        search ? `&search=${search}` : ''
      }`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      cancelToken: cancelTokenSource.token,
    };
    try {
      const getUsers = await axios(config).catch(function (error) {
        // to enhance
      });
      dispatch({
        type: "GET_USERS",
        users: getUsers.data.users,
      });
      dispatch({
        type: type.GET_USERS_COUNT,
        totalCount: getUsers.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      await Swal.fire(error.response || "Failed to fetch users");
      setIsLoading(false);
    }
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenAddDialog = () => {
    setOpen(true);
  };

  const handleCloseAddDialog = async ({ refresh: shouldRefresh }) => {
    setOpen(false);
    if (shouldRefresh) {
      await getUsers();
    }
  };

  const handleRemoveSelectedUsers = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover theses users!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_AUTH_SERVICE}/api/users/bulk-delete`,
          headers: {
            "x-auth-token": `${localStorage.getItem("token")}`,
          },
          data: { userIds: selectedUserIds },
        };

        return axios(config)
          .then(() => {
            return true;
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to remove users",
              icon: "warning",
              iconColor: "#4368B1",
              confirmButtonColor: "#4368B1",
              confirmButtonText: "quitter",
            });
          });
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Swal.fire({
          title: "Suprimer!",
          text: "Users removed successfully!",
          iconColor: "#4368B1",
          icon: "success",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "Finir",
        });
        await getUsers();
      }
    })
  };

  const handleRemoveUser = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const config = {
          method: "delete",
          url: `${process.env.REACT_APP_AUTH_SERVICE}/api/users/${id}`,
          headers: {
            "x-auth-token": `${localStorage.getItem("token")}`,
          },
        };

        return axios(config)
          .then(() => {
            return true;
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to remove user",
              icon: "warning",
              iconColor: "#4368B1",
              confirmButtonColor: "#4368B1",
              confirmButtonText: "quitter",
            });
          });
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Swal.fire({
          title: "Suprimer!",
          text: "User removed successfully!",
          iconColor: "#4368B1",
          icon: "success",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "Finir",
        });
        await getUsers();
      }
    })
  };

  const handleGetUser = (id) => {
    return axios.get(
      `${process.env.REACT_APP_AUTH_SERVICE}/api/users/${id}`,
      { headers: { "x-auth-token": `${localStorage.getItem("token")}` } }
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      await getUsers();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    (async () => await getUsers())();
    // eslint-disable-next-line
  }, [id, pageSize, page, filters]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  return (
    <>
      {open && (
        <UserDetailDialog
          selectedUser={null}
          open={open}
          onClose={handleCloseAddDialog}
        />
      )}
      <h3 className="font-bold text-[24px] mb-6 hidden md:block">Users</h3>
      <div className="flex flex-col sm:border sm:border-grey2 flex-grow bg-white0 sm:w-full sm:rounded-lg overflow-hidden">
        <div className=" rounded-md lg:rounded-md sm:rounded-lg">
          <div
            className={
              "flex md:flex lg:flex justify-between items-center space-x-6 p-6 "
            }
          >
            <div className="flex justify-start items-center ">
              <h1 className={"font-bold  text-[19px] "}>Users</h1>
              <div className="relative">
                <input
                  className="ml-4 pl-10 bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={search || ''}
                  onChange={handleChangeSearch}
                />
                <img className="absolute top-4 left-8" src={ searchIcon } alt="search-icon" />
              </div>
            </div>
            <div className="lg:flex md:flex xxl:flex hidden">
              {!!(user.role === UserRole.SUPER_ADMIN && selectedUserIds?.length) && (
                <button
                  className="p-2 text-center  rounded bg-[#dc362e] text-white0 mr-2 flex items-center gap-2"
                  onClick={handleRemoveSelectedUsers}
                >
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1875 3.5H10.9375V2.09375C10.9375 1.47324 10.433 0.96875 9.8125 0.96875H4.1875C3.56699 0.96875 3.0625 1.47324 3.0625 2.09375V3.5H0.8125C0.501367 3.5 0.25 3.75137 0.25 4.0625V4.625C0.25 4.70234 0.313281 4.76562 0.390625 4.76562H1.45234L1.88652 13.959C1.91465 14.5584 2.41035 15.0312 3.00977 15.0312H10.9902C11.5914 15.0312 12.0854 14.5602 12.1135 13.959L12.5477 4.76562H13.6094C13.6867 4.76562 13.75 4.70234 13.75 4.625V4.0625C13.75 3.75137 13.4986 3.5 13.1875 3.5ZM9.67188 3.5H4.32812V2.23438H9.67188V3.5Z"
                      fill="white"
                    />
                  </svg>
                  Remove selected users
                </button>
              )}
              {(!users.length || user.role !== UserRole.SUPER_ADMIN) ? null : (
                <button
                  className="px-10 py-[5px] text-center rounded-[8px] bg-blue1 text-white0"
                  onClick={handleOpenAddDialog}
                >
                  <b>+</b>  Add
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-col overflow-auto">
            <div className="sm:hidden h-[calc(100vh-200px)] sm:h-[calc(100vh-400px)] overflow-scroll">
              {filteredUsers ? (
                <MobileList
                  title="User"
                  loading={loading}
                  data={filteredUsers}
                  getSingleItem={handleGetUser}
                  removeItem={handleRemoveUser}
                  columns={mobileColumns}
                  editable={authUser.role === UserRole.SUPER_ADMIN}
                />
              ) : (
                <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
              )}
            </div>

            {/* Web View */}
            <div className="h-[calc(100vh-300px)] mt-2 flex-col sm:flex lg:flex xxl:flex hidden">
              {filteredUsers ? (
                <WebUsersList
                  loading={loading}
                  userData={filteredUsers}
                  filters={filters}
                  onChangeFilters={setFilters}
                  refresh={getUsers}
                  removeItem={handleRemoveUser}
                  selectedUserIds={selectedUserIds}
                  onChangeSelectedUserIds={setSelectedUserIds}
                ></WebUsersList>
              ) : (
                <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
              )}
              {/* Web View */}
            </div>
          </div>
        </div>

        <div className=" flex sm:w-full space-x-5  p-3  bottom-0 justify-end bg-white0  ">
          <div className="shrink">
            <select
              className={" text-grey1 text-[15px] border-none rounded"}
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 20, 30, 40].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Element par page : {pageSize}
                </option>
              ))}
            </select>
          </div>

          <div className="shrink">
          <span className={"text-grey1 text-[15px]"}>
            {(page - 1) * pageSize + 1}-{Math.min(pageSize * page, totalCount)} of {totalCount}
          </span>{" "}
          </div>

          <div className="shrink space-x-10">
            <button
              onClick={() => setPage(page - 1)}
              disabled={page <= 1}
            >
              <svg
                width="8"
                height="16"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.535 0.514893L0.0500031 8.99989L8.535 17.4849L9.95 16.0709L2.878 8.99989L9.95 1.92889L8.535 0.514893Z"
                  fill="#2E3A59"
                />
              </svg>
            </button>
            <button onClick={() => setPage(page + 1)} disabled={isLast}>
              <svg
                width="8"
                height="16"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.465 17.4851L9.95 9.00011L1.465 0.515106L0.0499973 1.92911L7.122 9.00011L0.0499973 16.0711L1.465 17.4851Z"
                  fill="#2E3A59"
                />
              </svg>
            </button>
          </div>

          <br />
        </div>
      </div>
    </>
  );
}

export default UserList;