import React, { useState } from "react";
const configuration = [
  {
    id: 1,
    name: "Configuration 1",
  },
  {
    id: 2,
    name: "Configuration 2",
  },
];
function ChooseConfigurationModal({
  isOpen,
  onClose,
  handleChooseConfiguration,
}) {
  const [chosenConfiguration, setChosenConfiguration] = useState(undefined);
  const handleChooseConfig = (configuration) => {
    return (e) =>
      setChosenConfiguration(
        chosenConfiguration && configuration.id === chosenConfiguration.id
          ? undefined
          : configuration
      );
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[550px] flex flex-col justify-center items-center">
        <h2 className="text-black font-bold text-xl mb-4 text-center">
          Choisir une configuration de population existante:
        </h2>
        <div className="px-10 mb-5">
          {configuration.map((config) => {
            return (
              <div
                key={config.id}
                className={`flex justify-between items-center cursor-pointer py-1 px-[150px] rounded ${
                  chosenConfiguration &&
                  chosenConfiguration.id === config.id &&
                  "bg-grey6"
                } hover:bg-grey6 rounded`}
                onClick={handleChooseConfig(config)}
              >
                <p>{config.name}</p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row-reverse gap-4 w-full">
          <button
            className="px-4 py-1 bg-lightblue2 font-bold text-white rounded-lg"
            onClick={handleChooseConfiguration(chosenConfiguration)}
          >
            Choisir
          </button>
          <button
            className="px-4 py-1 text-back font-bold bg-transparent border border-grey2 rounded-lg"
            onClick={onClose}
          >
            Revenir
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChooseConfigurationModal;
