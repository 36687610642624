import React from "react";
import CarLinedIcon from "./icons/CarLinedIcon";
import PassedIcon from "./icons/PassedIcon";
import WarningIcon from "./icons/WarningIcon";

const suspensionReport = [
  {
    title: "Hauteur de suspension",
    passed: true,
    value: "Normal",
  },
  {
    title: "Ratio d'amortissement",
    passed: true,
    value: "0,65",
  },
  {
    title: "Fréquence naturelle",
    passed: true,
    value: "Normal",
  },
  {
    title: "Pression des pneus (AVG/AVD/ARG/ARD)",
    passed: false,
    value: "1,8/2,2/2,5/2 Bar",
  },
  {
    title: "Alignement des roues",
    passed: true,
    value: "Dans les normes",
  },
  {
    title: "Inclinaison de la carrosserie",
    passed: true,
    value: "2,1°",
  },
  {
    title: "Déplacement de la suspension",
    passed: true,
    value: "Normal",
  },
];

const suspensionDetailedReport = {
  frontLeft: 90,
  backLeft: 95,
  frontRight: 97,
  backRight: 92,
};

function SuspensionDetailed() {
  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-center text-grey1 m-5">
        État général de chaque suspension
      </h1>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-[100px]">
          <div className="flex flex-col gap-3 p-2 m-2 w-[200px]">
            <span className="pl-10 xxl:pl-0 xl:ml-0 ml-5 font-bold">
              Avant gauche
            </span>
            <div className="pl-10 xl:ml-0 ml-5 flex items-center gap-1">
              <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                <div
                  className="bg-[#118D57] h-1.5 rounded-full"
                  style={{ width: `${suspensionDetailedReport.frontLeft}%` }}
                ></div>
              </div>
              <small className="font-bold">
                {suspensionDetailedReport.frontLeft}%
              </small>
            </div>
          </div>
          <div className="flex flex-col gap-3 p-2 m-2 w-[200px]">
            <span className="pl-10 xxl:pl-0 xl:ml-0 ml-5 font-bold">
              Arrière gauche
            </span>
            <div className="pl-10 xl:ml-0 ml-5 flex items-center gap-1">
              <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                <div
                  className="bg-[#118D57] h-1.5 rounded-full"
                  style={{ width: `${suspensionDetailedReport.backLeft}%` }}
                ></div>
              </div>
              <small className="font-bold">
                {suspensionDetailedReport.backLeft}%
              </small>
            </div>
          </div>
        </div>
        <div className="w-[160px] sm:w-[120px] lg:w-[150px] md:w-[180px] xl:w-[120px] xxl:w-[220px]">
          <CarLinedIcon />
        </div>
        <div className="flex flex-col gap-[100px]">
          <div className="flex flex-col gap-3 p-2 m-2 w-[200px]">
            <span className="pr-10 xxl:pr-0 xl:mr-0 mr-5  font-bold text-end">
              Avant droit
            </span>
            <div className="pr-10 xl:mr-0 mr-5 flex items-center gap-1">
              <small className="font-bold">
                {suspensionDetailedReport.frontRight}%
              </small>
              <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                <div
                  className="bg-[#118D57] h-1.5 rounded-full"
                  style={{ width: `${suspensionDetailedReport.frontRight}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 p-2 m-2 w-[200px]">
            <span className="pr-10 xxl:pr-0 xl:mr-0 mr-5 font-bold text-end">
              Arrière droit
            </span>
            <div className="pr-10 xl:mr-0 mr-5 flex items-center gap-1">
              <small className="font-bold">
                {suspensionDetailedReport.backRight}%
              </small>
              <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                <div
                  className="bg-[#118D57] h-1.5 rounded-full"
                  style={{ width: `${suspensionDetailedReport.backRight}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-5 px-5 my-10">
        {suspensionReport.map((report, index) => (
          <div key={index} className="w-full flex justify-between items-center">
            <h1 className="font-bold text-l">{report.title}</h1>
            <div className="flex gap-2 items-center">
              <p>{report.value}</p>
              {report.passed ? <PassedIcon /> : <WarningIcon />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SuspensionDetailed;
