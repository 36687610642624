import { ReactComponent as MinusIcon } from "./../../../Assets/minus_circle.svg";
import {CarFunctionalities} from "../../../Constants/cars";

export const Functionalities = ({ data }) => {
  // Split the values into two separate arrays
  const midIndex = Math.ceil(CarFunctionalities.length / 2);
  const column1 = CarFunctionalities.slice(0, midIndex);
  const column2 = CarFunctionalities.slice(midIndex);

  return (
    <>
      <div className="flex md:hidden flex-row space-x-8">
        <div className="flex flex-col space-y-3">
          {CarFunctionalities.map((option) => (
            <div
              key={option.value}
              className="flex flex-row items-center space-x-3"
            >
              {data?.functionalities?.includes(option.value) ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00002 17.3333C4.39974 17.3283 0.671739 13.6003 0.666687 9V8.83334C0.758297 4.25378 4.52884 0.606635 9.10891 0.667405C13.689 0.728175 17.3614 4.47408 17.3315 9.05445C17.3016 13.6348 13.5805 17.3324 9.00002 17.3333ZM5.17502 8.65834L4.00002 9.83334L7.33335 13.1667L14 6.5L12.825 5.31667L7.33335 10.8083L5.17502 8.65834Z"
                    fill="#00BC62"
                  />
                </svg>
                ) : <MinusIcon />
              }
              <p className={data?.functionalities?.includes(option.value) ? "" : "text-grey2"}>{option.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="hidden md:flex flex-row space-x-8">
        <div className="flex flex-col space-y-3">
          {column1.map((option) => (
            <div
              key={option.value}
              className="flex flex-row items-center space-x-3"
            >
              {data?.functionalities?.includes(option.value) ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00002 17.3333C4.39974 17.3283 0.671739 13.6003 0.666687 9V8.83334C0.758297 4.25378 4.52884 0.606635 9.10891 0.667405C13.689 0.728175 17.3614 4.47408 17.3315 9.05445C17.3016 13.6348 13.5805 17.3324 9.00002 17.3333ZM5.17502 8.65834L4.00002 9.83334L7.33335 13.1667L14 6.5L12.825 5.31667L7.33335 10.8083L5.17502 8.65834Z"
                    fill="#00BC62"
                  />
                </svg>
              ) : (
                <MinusIcon />
              )}
              <p className={data?.functionalities?.includes(option.value) ? "" : "text-grey2"}>{option.label}</p>
            </div>
          ))}
        </div>

        <div className="flex flex-col space-y-3">
          {column2.map((option) => (
            <div
              key={option.value}
              className="flex flex-row items-center space-x-3"
            >
              {data?.functionalities?.includes(option.value) ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00002 17.3333C4.39974 17.3283 0.671739 13.6003 0.666687 9V8.83334C0.758297 4.25378 4.52884 0.606635 9.10891 0.667405C13.689 0.728175 17.3614 4.47408 17.3315 9.05445C17.3016 13.6348 13.5805 17.3324 9.00002 17.3333ZM5.17502 8.65834L4.00002 9.83334L7.33335 13.1667L14 6.5L12.825 5.31667L7.33335 10.8083L5.17502 8.65834Z"
                    fill="#00BC62"
                  />
                </svg>
              ): (
                <MinusIcon />
              )}
              <p className={data?.functionalities?.includes(option.value) ? "" : "text-grey2"}>{option.label}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
