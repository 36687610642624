import React, { useState } from "react";

function CardInformation({
  label,
  value,
  subValue,
  subValueColor,
  icon: Icon,
  options,
  selectedOption,
  setSelectedOption,
  customHeight,
  controller,
}) {
  return (
    <div
      className={`${
        !customHeight && "h-[160px]"
      } w-full rounded-xl border border-grey2 bg-white p-5`}
    >
      <div className="flex justify-between items-center mb-4">
        <div className={`${Icon && "bg-background"} p-2 rounded-xl`}>
          {Icon && <Icon width="24" height="24" />}
        </div>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block bg-transparent border-0 p-2.5"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <span className="text-[#8B8D97] mb-2">{label}</span>
      <div className="flex gap-2 mt-2 items-start">
        {controller ? (
          controller
        ) : (
          <>
            <span className="text-xxl font-bold text-[#45464E]">{value}</span>
            <small className={`text-[${subValueColor}]`}>{subValue}</small>
          </>
        )}
      </div>
    </div>
  );
}

export default CardInformation;
