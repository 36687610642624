import React from "react";
import Swal from "sweetalert2";
import { ClearStoreData } from "../../../Redux/cars/actions";

/**
 * Stepper Component
 * Renders a 4-step progress bar with Next and Finish buttons.
 *
 * @param {Object} props Component properties.
 * @param {number} props.next The current step in the stepper (from 0 to 3).
 * @param {Function} props.handleNext Callback function to handle the next step.
 * @param {boolean} props.stateRedux Redux state to determine the button's disabled state.
 */
const Stepper = ({
  next,
  handleNext,
  stateRedux,
  customText,
  handleRetry,
  retries,
}) => {
  /**
   * Clears store data and triggers an alert when the process is finished.
   */
  const finish = () => {
    ClearStoreData("ADD_CAR");
    Swal.fire({
      title: `Voiture ajoutée avec succés`,
      confirmButtonText: '<a href="/addCar">Ajouter une voiture</a>',
      cancelButtonText: '<a href="/cars">Aller au tableau des voitures</a>',
      showCancelButton: true,
      showCloseButton: true,
    });
  };

  /**
   * Renders a step based on the provided index.
   *
   * @param {number} step Index of the step to render.
   * @returns {JSX.Element} Step element.
   */
  const renderStep = (step) => (
    <div className="flex items-center text-gray-500 relative px-1">
      <div
        className={`rounded transition duration-500 ease-in-out w-12 xs:w-20 py-1 ${
          next === step ? "bg-blue1" : "bg-grey6"
        }`}
      ></div>
    </div>
  );

  /**
   * Renders the Next or Finish button based on the current step.
   *
   * @returns {JSX.Element|null} Button element or null if the step is out of bounds.
   */
  const renderButton = () => {
    if (next < 7) {
      return (
        <div>
          <button
            onClick={(e) => handleNext(e)}
            disabled={stateRedux}
            className={`mt-2 box-border h-[52px] w-full rounded-lg text-white0 bg-blue1 font-bold text-[20px] border-blue1 drop-shadow-md ${
              !stateRedux ? "bg-blue1 text-white0" : "bg-grey6 text-blue1"
            }`}
          >
            {customText ?? "Suivant"}
          </button>
          {handleRetry && retries > 0 ? (
            <button
              onClick={handleRetry}
              className="mt-2 box-border h-[52px] w-full border-1 rounded-lg text-white0 border  px-4"
            >
              <p className="text-black font-bold text-md">
                Redémarrer le processus
              </p>
              <p className="text-grey1 text-tiny">{`(${retries} tentatives restantes)`}</p>
            </button>
          ) : null}
        </div>
      );
    }

    if (next === 7) {
      return (
        <button
          onClick={finish}
          className="mt-2 box-border h-[52px] w-full border-1 rounded-lg text-white0 bg-blue1 font-bold text-[20px] px-4"
        >
          Terminer
        </button>
      );
    }

    return null;
  };

  return (
    <div className="z-10 bottom-5 right-1 left-1 md:bottom-0 md:left-0 md:right-0 fixed bg-white0 md:p-3">
      <div className="flex">
        {
          // This code generates an array of four steps by calling the renderStep function with indices from 0 to 3.
          // Array.from is used to create a new array instance from an array-like or iterable object.
          // In this case, an object with a property "length" set to 4 is used to create an array of four undefined elements.
          // Then, the second argument is a map function that takes the index of each element and calls renderStep with it.
        }
      </div>
      {renderButton()}
    </div>
  );
};

export default Stepper;
