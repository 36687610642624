import { Radio } from "@material-tailwind/react";
import React from "react";

function RadioButton({
  checked,
  onChange,
  name,
  value,
  label,
  color,
  labelPosition,
}) {
  return (
    <div
      className={`flex ${
        labelPosition === "left"
          ? "flex-row-reverse items-center"
          : labelPosition === "right"
          ? "items-center"
          : "flex-col"
      }`}
    >
      {labelPosition === "top" && <small className="mb-1">{label}</small>}

      <Radio
        checked={checked}
        name={name}
        onChange={onChange}
        value={value}
        color={color}
      />

      {labelPosition === "bottom" && <small className="mt-1">{label}</small>}
      {(labelPosition === "left" || labelPosition === "right") && (
        <small>{label}</small>
      )}
    </div>
  );
}

export default RadioButton;
