import React from "react";

function ConnectedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="#00A76F" />
      <path
        d="M8.21625 14.9999C7.98528 14.9992 7.76498 14.9026 7.60792 14.7333L3.55792 10.4249C3.24266 10.089 3.25945 9.56104 3.59542 9.24578C3.93139 8.93052 4.45932 8.94731 4.77459 9.28328L8.20792 12.9416L15.2163 5.27495C15.41 5.03363 15.7216 4.91959 16.0254 4.97879C16.3292 5.03799 16.5751 5.26068 16.6641 5.55711C16.7531 5.85354 16.6705 6.17487 16.4496 6.39162L8.83292 14.7249C8.67732 14.8974 8.45683 14.9971 8.22459 14.9999H8.21625Z"
        fill="white"
      />
    </svg>
  );
}

export default ConnectedIcon;
