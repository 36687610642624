import React from "react";
import AgentIaLayout from "../../Layout/AgentAi/AgentIaLayout";
import AgentAi from "../../Components/AgentAi/Chat/AgentAi";

const Chat = () => {
  return (
    <AgentIaLayout title={"Bonjour Michael!"}>
      <AgentAi />
    </AgentIaLayout>
  );
};

export default Chat;
