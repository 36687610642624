import React, { useState } from "react";
import WhatsappIcon from "../icons/WhatsappIcon";

const steps = [
  {
    title: "Merci de suivre les étapes pour archiver un appel WhatsApp",
  },
  {
    title: "Merci de suivre les étapes pour archiver un appel WhatsApp",
    subTitle:
      "Merci d’entrer le numéro WhatsApp utilisé par le client lors de l’appel",
    number: "N° de téléphone",
  },
  {
    title: "Merci de suivre les étapes pour archiver un appel WhatsApp",
    subTitle:
      "Il s'agit d'un nouveau client. Merci de bien vouloir lui attribuer un Prénom et Nom",
    input: "Prénom Nom",
  },
  {
    title: "Merci de suivre les étapes pour archiver un appel WhatsApp",
    subTitle: "Il s’agit d’un client déjà existant dans la base de données:",
    label: "Patrick Dupont",
  },
  {
    title: "Archiver un appel client",
    subTitle: "Archiver l’appel",
    multiline: "Ecrire içi",
  },
  {
    title: "Archiver un appel client",
    subTitle: "Votre activité a été ajoutée avec succès",
    finish: true,
  },
];
function WhatsappModal({ isOpen, onClose, handleCompleted }) {
  const [step, setStep] = useState(0);
  const [currentObject, setCurrentObject] = useState(steps[step]);
  if (!isOpen) return null;
  const finish = steps.length - 1 === step;
  const handleContinue = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
      setCurrentObject(steps[step + 1]);
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[500px] flex flex-col justify-center items-center">
        <div className="w-full my-1">
          <h2 className="text-black font-bold text-xl mb-2">
            {`Archiver un appel WhatsApp`}
          </h2>
        </div>
        <div className="px-10 mb-5 flex flex-col items-center gap-2">
          <WhatsappIcon height={60} active />
          {/* TODO:: get values from global states during stepper */}
          <h1 className="text-center">{currentObject.title}</h1>
          {currentObject.subTitle && (
            <span className="text-sm text-grey1 text-center">
              {currentObject.subTitle}
            </span>
          )}
          {currentObject.label && (
            <span className="text-sm  italic">{currentObject.label}</span>
          )}
          {currentObject.number && (
            <div className="bg-background flex items-center gap-3 p-2 rounded-xl border-grey3 border-2">
              <span className="font-bold">+33</span>
              <input
                className="border-none bg-transparent  focus:outline-none"
                placeholder={currentObject.number}
              />
            </div>
          )}
          {currentObject.multiline && (
            <textarea
              className="border-none bg-background p-1 text-tiny w-[400px] focus:outline-none"
              placeholder={currentObject.multiline}
            />
          )}
          {currentObject.input && (
            <input
              className="border-grey3 border-2 bg-background rounded-l p-2 focus:outline-none w-[300px]"
              placeholder={currentObject.input}
            />
          )}
          {currentObject.finish && (
            <span className="my-2">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.6667 63.3334C14.1856 63.3142 0.0191975 49.1478 0 31.6667V31.0334C0.348116 13.6311 14.6762 -0.228081 32.0805 0.00284436C49.4847 0.23377 63.44 14.4682 63.3263 31.8736C63.2126 49.2791 49.0725 63.3299 31.6667 63.3334ZM17.1317 30.3684L12.6667 34.8334L25.3333 47.5001L50.6667 22.1667L46.2017 17.6701L25.3333 38.5384L17.1317 30.3684Z"
                  fill="#282F75"
                />
              </svg>
            </span>
          )}
        </div>
        <div className="flex flex-row-reverse gap-4 w-full">
          <button
            className="px-4 py-1 bg-lightblue2 font-bold text-white rounded-lg"
            onClick={
              finish
                ? handleCompleted({
                    icon: WhatsappIcon,
                    title: "Appel WhatsApp",
                    description: "Archivé le 27 Octobre 2025 à 10:15",
                  })
                : handleContinue
            }
          >
            {finish ? "Terminer" : "Continuer"}
          </button>
          {step === 3 && (
            <button
              className="px-4 py-1 text-back font-bold bg-transparent border border-grey2 rounded-lg"
              onClick={handleContinue}
            >
              Non ou ne je sais pas
            </button>
          )}
          <button
            className="px-4 py-1 text-back font-bold bg-transparent border border-grey2 rounded-lg"
            onClick={() => {
              setStep(0);
              onClose();
            }}
          >
            {steps.length - 1 === step
              ? "Retour à la page précédente"
              : "Annuler"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhatsappModal;
