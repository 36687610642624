import React from "react";

function Seat() {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8668 11.2167H8.31241L5.89812 2.19027C5.68497 1.42993 5.18064 0.784281 4.49453 0.393382C3.80842 0.00248337 2.99586 -0.102146 2.2331 0.10219C1.47035 0.306526 0.818907 0.803349 0.420095 1.48489C0.0212821 2.16643 -0.0927433 2.97773 0.102752 3.7428L3.40882 16.1045C3.4942 16.4236 3.6825 16.7056 3.9445 16.9067C4.2065 17.1078 4.52756 17.2168 4.85785 17.2168H11.2502V18.7168H0V20.2169H11.2502C11.6481 20.2169 12.0296 20.0588 12.3109 19.7775C12.5922 19.4962 12.7503 19.1147 12.7503 18.7168V17.2168H15.0003C15.4194 17.2162 15.8337 17.128 16.2167 16.9577C16.5996 16.7874 16.9427 16.5388 17.2239 16.228C17.505 15.9172 17.718 15.551 17.8491 15.153C17.9803 14.7549 18.0267 14.3338 17.9854 13.9167C17.8891 13.1617 17.5177 12.4688 16.9422 11.9706C16.3668 11.4724 15.6278 11.2039 14.8668 11.2167ZM15.0003 15.7168H4.85785L1.55253 3.35504C1.4972 3.16344 1.48062 2.96272 1.50378 2.76464C1.52694 2.56656 1.58937 2.37508 1.68741 2.20141C1.78545 2.02774 1.91714 1.87537 2.07477 1.7532C2.23241 1.63103 2.41282 1.54152 2.60546 1.48991C2.7981 1.4383 2.99909 1.42561 3.19669 1.45261C3.39429 1.4796 3.58452 1.54572 3.75625 1.64711C3.92799 1.7485 4.07779 1.88312 4.19688 2.04309C4.31597 2.20306 4.40197 2.38517 4.44984 2.57877L7.16039 12.7167H15.0003C15.3981 12.7167 15.7797 12.8748 16.061 13.1561C16.3423 13.4374 16.5003 13.8189 16.5003 14.2168C16.5003 14.6146 16.3423 14.9961 16.061 15.2774C15.7797 15.5587 15.3981 15.7168 15.0003 15.7168Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default Seat;
