import React, { Fragment } from "react";
import { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import emptySign from "../../Assets/emptySign.svg";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  DropdownIndicator,
  Option,
} from "../car/common/subComponents/dropDown";
import { RoleOptions, UserRole } from "../../Constants/users";
import checkIcon from "./../../Assets/check-circle.svg";
import closeIcon from "./../../Assets/close-circle.svg";
import { UserDetailDialog } from "./detailDialog";
import Checkbox from "../carsDisplay/subComponents/checkbox";
import { Chip } from "./chip";

const VerifiedOptions = [
  { value: "true", label: "Verified" },
  { value: "false", label: "Not verified" },
];

const WebUsersList = ({
  userData,
  loading,
  filters,
  onChangeFilters,
  refresh,
  removeUser,
  selectedUserIds,
  onChangeSelectedUserIds,
}) => {
  const authUser = useSelector((state) => state.profile).profile;
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { roles, verified } = filters;

  const handleDeleteAd = async (e, id) => {
    e.stopPropagation();
    removeUser(id);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleChangeRole = (selectedRoles) => {
    onChangeFilters({
      ...filters,
      roles: selectedRoles.map((role) => role.value),
    });
  };

  const handleChangeVerified = (selectedValues) => {
    onChangeFilters({
      ...filters,
      verified: selectedValues.map((item) => item.value),
    });
  };

  const Skeleton = () => {
    return <div className="bg-[#f0f0f0] h-12 rounded animate-pulse m-2" />;
  };

  const handleOpenDetailDialog = (user) => {
    setOpen(true);
    setSelectedUser(user);
  };

  const handleCloseDetailDialog = ({ refresh: shouldRefresh }) => {
    setOpen(false);
    if (shouldRefresh) {
      refresh();
    }
  };

  const handleSelectUser = (id, value) => {
    if (value) {
      if (!selectedUserIds?.includes(id)) {
        onChangeSelectedUserIds([...(selectedUserIds || []), id]);
      }
    } else {
      onChangeSelectedUserIds(
        selectedUserIds?.filter((userId) => userId !== id) || []
      );
    }
  };

  const handleSelectedAllUser = (value) => {
    if (value) {
      onChangeSelectedUserIds(userData.map((user) => user.id));
    } else {
      onChangeSelectedUserIds([]);
    }
  };

  const isAllChecked = userData.every((user) =>
    selectedUserIds?.includes(user.id)
  );

  return (
    <div className="shadow rounded-lg overflow-x-auto overflow-y-auto h-full">
      <UserDetailDialog
        selectedUser={selectedUser}
        open={open}
        onClose={handleCloseDetailDialog}
      />
      {!loading && userData.length === 0 ? (
        <div className="grid justify-items-center items-center md:h-2/3 ">
          <div className="flex flex-col ">
            <img src={emptySign} alt="empty sign" />
            <p className="text-[18px] font-bold">Pas encore de voitures</p>
            <NavLink
              className={
                " text-[16px] mx-3 bg-warning1 p-2 text-center text-white0"
              }
              to="/addCar"
            >
              + Ajouter
            </NavLink>
          </div>
        </div>
      ) : (
        <table className="min-w-full divide-y divide-grey2 dark:divide-grey1 table-auto  overflow-hidden">
          <thead>
            <tr>
              <th>
                <Checkbox
                  value={isAllChecked}
                  onChange={(e) => {
                    handleSelectedAllUser(e.target.checked);
                  }}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px] ">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                Firstname
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                Lastname
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                <div className="flex items-center relative cursor-pointer">
                  <div className="absolute flex items-center">
                    Role
                    <span className="ml-3 text-[10px] rounded-full bg-blue1 text-white0 w-4 h-4 flex justify-center items-center">
                      {roles?.length || 0}
                    </span>
                  </div>
                  <Select
                    className="w-full"
                    hideSelectedOptions={false}
                    isMulti
                    value={
                      roles &&
                      roles.map((key) =>
                        RoleOptions.find((option) => option.value === key)
                      )
                    }
                    options={RoleOptions}
                    onChange={handleChangeRole}
                    components={{
                      Option,
                      DropdownIndicator,
                    }}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    placeholder=""
                    isSearchable={false}
                    styles={{
                      control: (baseStyles, state) => ({
                        borderColor: state.isFocused ? "grey" : "red",
                        display: "block",
                        "> div:last-child": {
                          display: "none",
                        },
                      }),
                    }}
                  />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1  capitalize md:text-[14px] cursor-pointer">
                <div className="flex items-center relative cursor-pointer">
                  <div className="absolute flex items-center">
                    Verified
                    <span className="ml-3 text-[10px] rounded-full bg-blue1 text-white0 w-4 h-4 flex justify-center items-center">
                      {verified?.length || 0}
                    </span>
                  </div>
                  <Select
                    className="w-full"
                    hideSelectedOptions={false}
                    isMulti
                    value={
                      verified &&
                      verified.map((key) =>
                        VerifiedOptions.find((option) => option.value === key)
                      )
                    }
                    options={VerifiedOptions}
                    onChange={handleChangeVerified}
                    components={{
                      Option,
                      DropdownIndicator,
                    }}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    placeholder=""
                    isSearchable={false}
                    styles={{
                      control: (baseStyles, state) => ({
                        borderColor: state.isFocused ? "grey" : "red",
                        display: "block",
                        "> div:last-child": {
                          display: "none",
                        },
                      }),
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading &&
              Array(5)
                .fill("")
                .map((_, index) => (
                  <tr key={index}>
                    <td colSpan={6}>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
            {!loading &&
              userData.map((user, index) => {
                return (
                  <tr
                    key={index}
                    className={`bg-white0 hover:bg-grey3 text-black1 `}
                  >
                    <td className="border-b border-grey3 bg-white text-sm">
                      <Checkbox
                        value={!!selectedUserIds?.includes(user?.id)}
                        onChange={(e) =>
                          handleSelectUser(user?.id, e.target.checked)
                        }
                      />
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className=" whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold">
                        {user?.email}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap   text-[14px] md:text-[14px] font-mulish font-semibold">
                        {user?.firstName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-spacing-0 border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold">
                        {user?.lastName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-spacing-0 border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap border-spacing-0 text-[14px] md:text-[14px] font-mulish font-semibold">
                        {
                          RoleOptions.find((role) => role.value === user?.role)
                            .label
                        }
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <Chip verified={user?.verified}>
                        {user?.verified ? "Verified" : "Pending"}
                      </Chip>
                    </td>
                    {authUser.role === UserRole.SUPER_ADMIN && (
                      <td className="px-5 py-5 border-b border-grey3 bg-white text-sm w-[78px]">
                        <Menu as="div" className="flex flex-row text-left ">
                          <Menu.Button
                            onClick={(e) => e.stopPropagation()}
                            className="inline-flex w-full justify-center  bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                          >
                            <svg
                              width="4"
                              height="16"
                              viewBox="0 0 4 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                                fill="#9FA2B4"
                              />
                            </svg>
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white0 shadow-lg  focus:outline-none drop-shadow-md">
                              <div className="py-1">
                                <Menu.Item
                                  onClick={() => handleOpenDetailDialog(user)}
                                >
                                  {({ active }) => (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      <div className="flex flex-row p-2 items-center ">
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14.4688 13.6953H1.53125C1.22012 13.6953 0.96875 13.9467 0.96875 14.2578V14.8906C0.96875 14.968 1.03203 15.0312 1.10938 15.0312H14.8906C14.968 15.0312 15.0312 14.968 15.0312 14.8906V14.2578C15.0312 13.9467 14.7799 13.6953 14.4688 13.6953ZM3.52988 12.2188C3.56504 12.2188 3.6002 12.2152 3.63535 12.21L6.59199 11.6914C6.62715 11.6844 6.66055 11.6686 6.68516 11.6422L14.1365 4.19082C14.1528 4.17456 14.1657 4.15524 14.1746 4.13398C14.1834 4.11271 14.1879 4.08992 14.1879 4.06689C14.1879 4.04387 14.1834 4.02108 14.1746 3.99981C14.1657 3.97855 14.1528 3.95923 14.1365 3.94297L11.215 1.01973C11.1816 0.986328 11.1377 0.96875 11.0902 0.96875C11.0428 0.96875 10.9988 0.986328 10.9654 1.01973L3.51406 8.47109C3.4877 8.49746 3.47187 8.5291 3.46484 8.56426L2.94629 11.5209C2.92919 11.6151 2.9353 11.712 2.96409 11.8033C2.99288 11.8945 3.04349 11.9774 3.11152 12.0447C3.22754 12.1572 3.37344 12.2188 3.52988 12.2188Z"
                                            fill="black"
                                          />
                                        </svg>

                                        <p className="ml-2">Edit</p>
                                      </div>
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item
                                  onClick={(e) => handleDeleteAd(e, user._id)}
                                >
                                  {({ active }) => (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      <div className="flex flex-row p-2 items-center">
                                        <svg
                                          width="14"
                                          height="16"
                                          viewBox="0 0 14 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M13.1875 3.5H10.9375V2.09375C10.9375 1.47324 10.433 0.96875 9.8125 0.96875H4.1875C3.56699 0.96875 3.0625 1.47324 3.0625 2.09375V3.5H0.8125C0.501367 3.5 0.25 3.75137 0.25 4.0625V4.625C0.25 4.70234 0.313281 4.76562 0.390625 4.76562H1.45234L1.88652 13.959C1.91465 14.5584 2.41035 15.0312 3.00977 15.0312H10.9902C11.5914 15.0312 12.0854 14.5602 12.1135 13.959L12.5477 4.76562H13.6094C13.6867 4.76562 13.75 4.70234 13.75 4.625V4.0625C13.75 3.75137 13.4986 3.5 13.1875 3.5ZM9.67188 3.5H4.32812V2.23438H9.67188V3.5Z"
                                            fill="black"
                                          />
                                        </svg>
                                        <p className="ml-2">Delete</p>
                                      </div>
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default WebUsersList;
