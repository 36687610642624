import { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import showPass from "../../../Assets/Auth/showpass.svg";
import hidePass from "../../../Assets/Auth/hidepass.svg";
import logo from "../../../Assets/pictures/logo.png";
import { useSelector, useDispatch } from "react-redux";
import {registerInvitedUser, registerUser} from "../../../Redux/auth/register/actions";
import RotateLoader from "react-spinners/RotateLoader";
import axios from "axios";
import Swal from "sweetalert2";

const Registration = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const location = useLocation();

  const [, setCPasswordClass] = useState("form-control");
  const [isCPasswordDirty] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    cPassword: "",
    invitedBy: null,
    role: "",
    inviteToken: ""
  });

  const { email, password, lastName, firstName, cPassword } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();

    if (formData.invitedBy) {
      dispatch(registerInvitedUser(formData));
    } else {
      dispatch(registerUser(formData));
    }

    if (state.Register.message) {
      // setTimeout(() => {
      //   window.location.replace("/");
      // }, 5000);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);
  const showPassword = () => {
    setPasswordShown(!passwordShown);
  };
  const showPasswordConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };
  useEffect(() => {
    if (isCPasswordDirty) {
      if (password === cPassword) {
        setShowErrorMessage(false);
        setCPasswordClass("form-control is-valid");
      } else {
        setShowErrorMessage(true);
        setCPasswordClass("form-control is-invalid");
      }
    }
    // eslint-disable-next-line
  }, [cPassword]);

  useEffect(() => {
    const query = location.search.replace('?', '');
    if (query) {
      const token = query.split('&')[0].replace('token=', '');
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_AUTH_SERVICE}/api/user/verify`,
          { token },
          config
        )
        .then((res) => {
          const { data } = res;
          setFormData({
            ...formData,
            email: data.user.email,
            invitedBy: data.invitedBy,
            role: data.user.role,
            inviteToken: token,
          });
        })
        .catch((err) => {
          Swal.fire({
            text: "Invalid Token",
            icon: "warning",
            iconColor: "#4368B1",
            confirmButtonColor: "#4368B1",
            confirmButtonText: "quitter",
          });
        });
    }
    // eslint-disable-next-line
  }, [location]);

  const override = {
    position: "absolute",
    top: "50%",
    left: "50%",
  };

  return (
    <Fragment>
      {state.Register.loading ? <RotateLoader cssOverride={override} /> : ""}

      <div
        className={
          state.Register.loading
            ? "w-screen h-screen flex items-center justify-center opacity-50"
            : "w-screen h-screen flex items-center justify-center"
        }
      >
        <form
          onSubmit={(e) => onSubmit(e)}
          className="font-mulish tablet:box-border tablet:w-96 tablet:h-50 tablet:border-2 tablet:p-10 tablet:rounded-lg tablet:border-grey3  "
        >
          <div className=" space-y-10">
            <div className=" flex flex-col justify-center items-center ">
              <div className="m-5">
                <img className="w-25 h-16 my-5" src={logo} alt="logo" />
              </div>

              <div className="space-y-12">
                <div>
                  <p className="font-bold text-[24px]">S'enregistrer</p>
                </div>
              </div>
              {formData.invitedBy && (
                <div>
                  <p className="font-normal text-[16px]">
                    Invited as {formData.role} by {formData.invitedBy.email}
                  </p>
                </div>
              )}
              <div>
                <p className="font-normal text-[14px] text-grey1 ">
                  Entrez vos information ci-dessous
                </p>
              </div>
            </div>

            <div className=" flex-col space-y-5 grid   ">
              <div>
                <div>
                  <label className="font-bold text-[12px] text-grey1  ">
                    PRÉNOM
                  </label>
                </div>
                <div>
                  <input
                    className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => onChange(e)}
                    placeholder={"Prénom"}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className="font-bold text-[12px] text-grey1  ">
                    NOM
                  </label>
                </div>
                <div>
                  <input
                    className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => onChange(e)}
                    placeholder={"Nom"}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className="font-bold text-[12px] text-grey1  ">
                    EMAIL
                  </label>
                </div>
                <div>
                  <input
                    className="bg-background focus:bg-white0 active:bg-white0 border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="Email"
                    name="email"
                    disabled={!!formData.invitedBy}
                    value={email}
                    onChange={(e) => onChange(e)}
                    placeholder={"Adresse e-mail"}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-row justify-between ">
                  <label className="font-bold text-[12px] text-grey1 pb-2">
                    CRÉER UN MOT DE PASSE
                  </label>
                </div>
                <div className="flex relative flex-row w-full items-center box-border h-10 border-2 rounded-lg border-grey3 ">
                  <img
                    width={40}
                    className="absolute inset-y-0 right-0 px-2 py-2"
                    src={!passwordShown ? showPass : hidePass}
                    onClick={showPassword}
                    alt="show password"
                  />

                  <input
                    id="password-input"
                    className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    placeholder={"Mot de passe"}
                    onKeyUp={(e) => {
                      var span = document.getElementById("errorSpan");
                      var input = document.getElementById("password-input");
                      let password = e.target.value;
                      if (password.length >= 12) {
                        span.style.color = "green";
                        span.innerHTML = "Forte";
                        input.style.border = "solid green";

                        return;
                      } else if (password.length >= 4) {
                        span.style.color = "#FFC55C";
                        span.innerHTML = "Moyenne";
                        input.style.border = "solid #FFC55C";

                        return;
                      } else if (password.length < 4 && password.length > 0) {
                        span.style.color = "red";
                        span.innerHTML = "Faible";
                        input.style.border = "solid red";
                        return;
                      } else if (password.length === 0) {
                        span.style.color = "transparent";
                        span.innerHTML = "Sécurité Mot de passe";
                        input.style.border = "solid transparent";
                        return;
                      }
                    }}
                  />
                </div>
              </div>
              <span
                id="errorSpan"
                className="text-[12px] text-grey1 flex flex-row justify-end bottom-5"
              >
                Sécurité Mot de passe
              </span>
              <div>
                <div className="flex flex-row justify-between pb-2">
                  <label className="font-bold text-[12px] text-grey1">
                    CONFIRMER LE MOT DE PASSE
                  </label>
                </div>
                <div className="flex relative flex-row items-center box-border h-10 w-full border-2 rounded-lg border-grey3 ">
                  <img
                    width={40}
                    className="absolute inset-y-0 right-0 px-2 py-2"
                    src={!passwordShownConfirm ? showPass : hidePass}
                    onClick={showPasswordConfirm}
                    alt="show password"
                  />
                  <input
                    className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full min-w-[300px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type={passwordShownConfirm ? "text" : "password"}
                    name="cPassword"
                    value={cPassword}
                    onChange={(e) => onChange(e)}
                    placeholder={"Confirmation du mot de passe"}
                  />
                </div>
                {showErrorMessage && isCPasswordDirty ? (
                  <div className="text-grey1 text-[10px]">
                    {" "}
                    Passwords did not match{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {
                ("state",
                state.Register.errors ? (
                  <p className=" text-[15px]" style={{ color: "red" }}>
                    Erreur : tous les champs sont obligatoire
                  </p>
                ) : (
                  ""
                ))
              }
              <div className="flex">
                <button className="box-border h-10 w-full  border-1 rounded-lg text-white0 bg-blue1">
                  S'enregistrer
                </button>
              </div>
              <div>
                <p className="font-normal text-sm text-grey1">
                  J'ai déja un compte.{" "}
                  <Link className="font-normal text-sm text-blue1" to="/">
                    Se Connecter
                  </Link>
                </p>
              </div>
              {/* <p className="absolute inset-x-0 bottom-0 h-max text-[10px] text-grey4 text-center tablet:invisible ">
                By continuing you agree to Steer’s Terms of use and confirm that
                you have read Steer’s Privacy policy
              </p> */}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Registration;
