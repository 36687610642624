import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import RiseLoader from "react-spinners/RiseLoader";
import Swal from "sweetalert2";
import dropDownIconDown from "../../../Assets/dropdown-down.svg";
import dropDownIconUp from "../../../Assets/dropdown-up.svg";
import emptySign from "../../../Assets/emptySign.svg";

const MobileList = ({
  title,
  loading,
  data,
  getSingleItem,
  removeItem,
  columns,
  editable,
}) => {
  const [singleAd, setSingleAd] = useState([]);
  const [singleAdsLoading, setSingleAdsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const riseLoaderCss = {
    marginTop: "50%",
    marginBottom: "50%",
    display: "flex",
    alignSelf: "center",
  };

  const handleDeleteAd = (e, id) => {
    e.stopPropagation();
    removeItem(id);
  };

  async function getItem(id) {
    // I THINK THIS COULD BE DONE IN A DIFFERENT WAY
    try {
      const singleAdData = await getSingleItem(id);
      setSingleAd(singleAdData.data);

      setSingleAdsLoading(false);
    } catch (error) {
      setSingleAdsLoading(false);
      await Swal.fire(error.response);
    }
  }

  function closeModal() {
    setSelectedItemId(null);
  }

  function openModal(e, userId) {
    e.stopPropagation();
    setSelectedItemId(userId);
  }

  return (
    <div className="flex flex-col md:hidden  space-y-auto overflow-scroll">
      {!loading ? (
        !data?.length ? (
          <div className="flex flex-col justify-center items-center">
            <img src={emptySign} alt="empty-sign" />
            <p className="text-[18px] font-bold">Pas encore de voitures</p>
            <NavLink
              className={
                " text-[16px] mx-3 bg-warning1 p-2 text-center text-white0"
              }
              to="/addCar"
            >
              + Ajouter
            </NavLink>
          </div>
        ) : (
          data.map((item, key) => {
            return (
              <div className="flex flex-col border-b border-b-grey2" key={key}>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex  py-2 justify-between w-full">
                        <div className="flex items-center w-full px-4">
                          <div className="flex items-center ">
                            <img
                              className="m-2"
                              src={!open ? dropDownIconUp : dropDownIconDown}
                              onClick={() => getItem(item._id)}
                              alt="drop-down-icon"
                            />
                            <h2 className="m-2 font-bold text-[#9FA2B4] hidden xs:block">
                              {title}
                            </h2>
                          </div>
                          <div className="flex items-center flex-1 ml-4 xs:ml-10">
                            <div className="flex flex-col items-start">
                              <p className="whitespace-no-wrap font-semibold text-[16px] text-black1 text-left">
                                {
                                  item[
                                    columns.find((col) => col.type === "main")
                                      ?.key
                                  ]
                                }
                              </p>
                              <p className="whitespace-no-wrap text-[12px] text-grey1 items-start text-left">
                                {
                                  item[
                                    columns.find((col) => col.type === "sub")
                                      ?.key
                                  ]
                                }
                              </p>
                            </div>
                            {editable && (
                              <div
                                className="flex ml-auto"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <svg
                                  width="4"
                                  height="16"
                                  viewBox="0 0 4 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={(e) => openModal(e, item?._id)}
                                >
                                  <path
                                    d="M2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                                    fill="#2E3A59"
                                  />
                                </svg>

                                <Transition
                                  appear
                                  show={!!selectedItemId}
                                  as={Fragment}
                                >
                                  <Dialog
                                    as="div"
                                    className="absolute inset-0 top-1/3 z-10 overflow-y-auto "
                                    onClose={closeModal}
                                  >
                                    <div className=" px-4 text-center">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Dialog.Overlay className="fixed inset-0" />
                                      </Transition.Child>

                                      {/* This element is to trick the browser into centering the modal contents. */}
                                      <span
                                        className="inline-block align-middle"
                                        aria-hidden="true"
                                      >
                                        &#8203;
                                      </span>
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                      >
                                        <div className="flex  bg-white0  max-w-md p-6  transition-all transform  drop-shadow-sm rounded-lg divide-x text-grey1 justify-center ">
                                          <Link
                                            className={
                                              "p-3  text-center text-[12px] rounded text-blue1 "
                                            }
                                          >
                                            <div className="flex flex-row space-x-3">
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 16 16"
                                                fill="#4368B1"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M14.4688 13.6953H1.53125C1.22012 13.6953 0.96875 13.9467 0.96875 14.2578V14.8906C0.96875 14.968 1.03203 15.0312 1.10938 15.0312H14.8906C14.968 15.0312 15.0312 14.968 15.0312 14.8906V14.2578C15.0312 13.9467 14.7799 13.6953 14.4688 13.6953ZM3.52988 12.2188C3.56504 12.2188 3.6002 12.2152 3.63535 12.21L6.59199 11.6914C6.62715 11.6844 6.66055 11.6686 6.68516 11.6422L14.1365 4.19082C14.1528 4.17456 14.1657 4.15524 14.1746 4.13398C14.1834 4.11271 14.1879 4.08992 14.1879 4.06689C14.1879 4.04387 14.1834 4.02108 14.1746 3.99981C14.1657 3.97855 14.1528 3.95923 14.1365 3.94297L11.215 1.01973C11.1816 0.986328 11.1377 0.96875 11.0902 0.96875C11.0428 0.96875 10.9988 0.986328 10.9654 1.01973L3.51406 8.47109C3.4877 8.49746 3.47187 8.5291 3.46484 8.56426L2.94629 11.5209C2.92919 11.6151 2.9353 11.712 2.96409 11.8033C2.99288 11.8945 3.04349 11.9774 3.11152 12.0447C3.22754 12.1572 3.37344 12.2188 3.52988 12.2188Z"
                                                  fill="#4368B1"
                                                />
                                              </svg>
                                              <p className="text-[16px] text-black1">
                                                Modifier
                                              </p>
                                            </div>
                                          </Link>
                                          <div className="flex flex-row justify-center items-center space-x-3">
                                            <button
                                              className="  text-center text-[12px] rounded text-blue1 pl-3"
                                              onClick={(e) =>
                                                handleDeleteAd(e, item._id)
                                              }
                                            >
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 16 16"
                                                fill="#4368B1"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M13.1875 3.5H10.9375V2.09375C10.9375 1.47324 10.433 0.96875 9.8125 0.96875H4.1875C3.56699 0.96875 3.0625 1.47324 3.0625 2.09375V3.5H0.8125C0.501367 3.5 0.25 3.75137 0.25 4.0625V4.625C0.25 4.70234 0.313281 4.76562 0.390625 4.76562H1.45234L1.88652 13.959C1.91465 14.5584 2.41035 15.0312 3.00977 15.0312H10.9902C11.5914 15.0312 12.0854 14.5602 12.1135 13.959L12.5477 4.76562H13.6094C13.6867 4.76562 13.75 4.70234 13.75 4.625V4.0625C13.75 3.75137 13.4986 3.5 13.1875 3.5ZM9.67188 3.5H4.32812V2.23438H9.67188V3.5Z"
                                                  fill="#cd0f0f"
                                                />
                                              </svg>
                                            </button>
                                            <p className="text-[16px] text-black1">
                                              Suprimer
                                            </p>
                                          </div>
                                        </div>
                                      </Transition.Child>
                                    </div>
                                  </Dialog>
                                </Transition>
                              </div>
                            )}
                          </div>
                        </div>
                      </Disclosure.Button>

                      <Disclosure.Panel className="text-gray-500">
                        <table className="ml-12">
                          {!singleAdsLoading ? (
                            <tbody>
                              {columns
                                .filter((column) => column.type === "collapse")
                                .map((column) => (
                                  <tr>
                                    <td className="px-5 py-2 text-grey1 font-bold">
                                      {column.label}
                                    </td>
                                    <td className="font-semibold">
                                      {column.render
                                        ? column.render(
                                            singleAd[column.key],
                                            singleAd
                                          )
                                        : singleAd[column.key]}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <RiseLoader cssOverride={riseLoaderCss} />
                            </tbody>
                          )}
                        </table>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })
        )
      ) : (
        <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
      )}
    </div>
  );
};
export default MobileList;
