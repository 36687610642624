import React from "react";

function FolderIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.169 14.482C20.169 18.06 18.06 20.169 14.482 20.169H6.7C3.113 20.169 1 18.06 1 14.482V6.682C1 3.109 2.314 1 5.893 1H7.893C8.611 1.001 9.287 1.338 9.717 1.913L10.63 3.127C11.062 3.701 11.738 4.039 12.456 4.04H15.286C18.873 4.04 20.197 5.866 20.197 9.517L20.169 14.482Z"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.23145 13.2129H14.9664"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default FolderIcon;
