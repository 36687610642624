import React from "react";

function Inactive({ isOpen, onClose }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[400px] ">
        <h2 className="text-black font-bold text-xl mb-4">Need Assistance</h2>
        <div className="flex flex-col ">
          <div className="flex justify-center">
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M69.8329 50.8332C69.8329 61.3148 61.3146 69.8332 50.8329 69.8332V65.0832C58.7495 65.0832 65.0829 58.7498 65.0829 50.8332H69.8329ZM0.166224 19.1665C0.166224 8.68484 8.68456 0.166504 19.1662 0.166504V4.9165C11.2496 4.9165 4.91622 11.2498 4.91622 19.1665H0.166224ZM22.3329 10.6798L7.79789 25.2465C-2.39878 35.4432 -2.39878 52.0048 7.79789 62.2015C17.9946 72.3982 34.5562 72.3982 44.7529 62.2015L67.1412 39.7498C68.6929 38.2615 68.6929 35.7598 67.1412 34.2082C66.7735 33.8395 66.3367 33.5471 65.8558 33.3475C65.3749 33.148 64.8594 33.0453 64.3387 33.0453C63.8181 33.0453 63.3025 33.148 62.8216 33.3475C62.3407 33.5471 61.9039 33.8395 61.5362 34.2082L47.5396 48.2048L45.2912 45.9565L66.0012 25.2465C67.5529 23.6948 67.5529 21.1932 66.0012 19.6415C64.4496 18.0898 61.9162 18.0898 60.3329 19.6415L41.9346 38.1665L39.7496 35.8548L61.5046 14.0365C63.0562 12.4848 63.0562 9.98317 61.5046 8.4315C59.9529 6.87984 57.4512 6.87984 55.8996 8.4315L34.0812 30.2498L31.8329 28.0332L49.2496 10.6798C50.8329 9.12817 50.8329 6.6265 49.2496 5.07484C47.6662 3.52317 45.1962 3.52317 43.6446 5.07484L19.5146 29.2048C21.4059 31.6412 22.3433 34.684 22.1509 37.7623C21.9585 40.8406 20.6495 43.7429 18.4696 45.9248L16.2212 43.6765C18.0003 41.8952 18.9995 39.4807 18.9995 36.9632C18.9995 34.4457 18.0003 32.0311 16.2212 30.2498L15.1129 29.1415L28.0012 16.2532C29.5529 14.7015 29.5529 12.1998 28.0012 10.6482C26.4179 9.12817 23.9162 9.12817 22.3329 10.6798Z"
                fill="#5B85D8"
              />
            </svg>
          </div>
          <p className="text-grey-300">
            It looks like you've been here for a while. Do you need any help?
          </p>
        </div>
        <div className="flex justify-end gap-4">
          <button
            className={`px-2 border py-1 rounded w-20 text-sm text-back rounded float-right`}
          >
            <small className="font-bold">Get Help</small>
          </button>
          <button
            onClick={onClose}
            className={`px-2 bg-lightblue2 py-1 w-[120px] text-white rounded float-right`}
          >
            <small className="font-bold"> Thanks, I got it</small>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Inactive;
