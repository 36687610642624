import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function SimpleBarChart({ labels, datasets, showLegend = false, max = 100 }) {
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    barPercentage: 0.1,
    plugins: {
      legend: {
        display: showLegend,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        borderRadius: 10,
        max,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
}

export default SimpleBarChart;
