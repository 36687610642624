import React, { memo, useState } from "react";
import { Handle, Position } from "@xyflow/react";
import { ReactComponent as MoreIcon } from "../../../Assets/agent-ai/more.svg";
import { ReactComponent as WarningIcon } from "../../../Assets/agent-ai/warning.svg";

const PopoverMenu = ({ items }) => {
  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border border-gray-200 z-10 mt-[100px]">
      {items.map((item, index) => (
        <div
          key={index}
          className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
          onClick={item.onClick}
        >
          {item.icon && <span className="mr-2">{item.icon}</span>}
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
function CustomNode({ data }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedExecutor, setSelectedExecutor] = useState();
  const [disabledTopHandler, setDisabledTopHandler] = useState(false);
  const handleShowTopHandler = () => {
    setDisabledTopHandler((prev) => !prev);
    setIsPopoverOpen(false);
  };
  const menuItems = [
    {
      title: "Delete",
      icon: <i className="fas fa-trash" />,
      onClick: data.deleteHandler,
    },
    {
      title: disabledTopHandler ? "Enable top handler" : "Disable top handler",
      icon: <i className="fas fa-trash" />,
      onClick: handleShowTopHandler,
    },
  ];
  const handleIconClick = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const handleSelectExecutor = (e) => {
    const executor = e.target.value;
    console.log(executor);
    setSelectedExecutor(Number.parseInt(executor, 10));
    data.syncDataNode(data.id, {
      selectedExecutor: Number.parseInt(executor, 10),
    });
  };
  return (
    <div className="px-3 py-3 shadow-md rounded-md bg-white border-2 border-dashed border-grey3 hover:border-blue1 w-[320px] relative">
      <div className="flex flex-row gap-3 justify-between items-center pb-2 border-b-2 border-gray-200 mb-2">
        <div className="flex flex-row items-center gap-2">
          <data.icon />
          <p className="text-black">{data.title}</p>
        </div>
        <MoreIcon className="cursor-pointer" onClick={handleIconClick} />
        {isPopoverOpen && <PopoverMenu items={menuItems} />}
      </div>

      <div className="flex flex-row justify-between items-center">
        <select
          className="flex-1 appearance-none outline-none"
          value={selectedExecutor || ""}
          onChange={handleSelectExecutor}
        >
          <option value="">{data.executorPlaceholder}</option>
          {data.options?.map((option) => (
            <option className="p-2" key={option.id} value={option.id}>
              {option.text}
            </option>
          ))}
        </select>
        <div>
          <WarningIcon />
        </div>
      </div>
      {!disabledTopHandler && (
        <Handle
          type="target"
          id={data.id}
          position={Position.Top}
          className="w-16 !bg-teal-500"
        />
      )}
      <Handle
        type="source"
        id={data.id}
        position={Position.Bottom}
        className="w-16 !bg-teal-500"
      />
    </div>
  );
}

export default memo(CustomNode);
