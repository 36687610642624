import React from "react";

function CalendarIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.75 27.5H6.25C4.86929 27.5 3.75 26.3807 3.75 25V7.5C3.75 6.11929 4.86929 5 6.25 5H8.75V2.5H11.25V5H18.75V2.5H21.25V5H23.75C25.1307 5 26.25 6.11929 26.25 7.5V25C26.25 26.3807 25.1307 27.5 23.75 27.5ZM6.25 12.5V25H23.75V12.5H6.25ZM6.25 7.5V10H23.75V7.5H6.25ZM16.25 22.5H13.75V20H11.25V17.5H13.75V15H16.25V17.5H18.75V20H16.25V22.5Z"
        fill="#2E3A59"
      />
    </svg>
  );
}

export default CalendarIcon;
