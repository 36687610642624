import React, { useState } from "react";
import RadioButton from "../../RadioButton/RadioButton";
import TextChooser from "../../car/TextChooser/TextChooser";
import NeedHelp from "../../needHelp/NeedHelp";

function TargetPopulation({
  chosenConfiguration,
  handleOpenChooseConfigurationModal,
}) {
  const [selectedGender, setSelectedGender] = useState("");
  const [locations, setLocations] = useState([
    "Paris",
    "Marseille",
    "Ile-de France",
    "Toulouse",
  ]);
  const [chosenLocation, setChosenLocation] = useState("");

  const [types, setTypes] = useState(["Jeune", "Senior", "Passioné de l'auto"]);

  const [chosenType, setChosenType] = useState("");

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  return (
    <>
      <NeedHelp />
      <div className="w-full flex justify-center">
        <div className="flex flex-col gap-2 p-5 border rounded w-[70%] justify-center shadow drop-shadow items-center my-2">
          <h1 className="font-bold">
            Vous avez déjà une configuration de population sauvegardée ?
          </h1>
          <button
            className="px-2 py-1 bg-lightblue2 text-white rounded"
            onClick={handleOpenChooseConfigurationModal}
          >
            Importer
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-center mb-4">
          Choisir la population pour les réseaux sociaux
        </h1>

        <div className="grid grid-cols-1 gap-4 w-full max-w-xl">
          <div className="flex items-center">
            <p className="text-textgrey1 w-1/4 text-right mr-4">Minimum age:</p>
            <input
              className="border w-1/4 p-2 text-center"
              type="number"
              maxLength="4"
            />
          </div>

          <div className="flex items-center">
            <p className="text-textgrey1 w-1/4 text-right mr-4">Maximum age:</p>
            <input
              className="border w-1/4 p-2 text-center"
              type="number"
              maxLength="4"
            />
          </div>

          <div className="flex items-start">
            <p className="text-textgrey1 w-1/4 text-right  mr-4 mt-2">Sexe:</p>
            <div className="w-3/4 grid grid-cols-2 gap-4">
              <RadioButton
                onChange={handleGenderChange}
                checked={selectedGender === "male"}
                color={"indigo"}
                label={"Hommes uniquement"}
                name={"male"}
                value={"male"}
                labelPosition={"right"}
              />
              <RadioButton
                onChange={handleGenderChange}
                checked={selectedGender === "female"}
                color={"indigo"}
                label={"Femmes uniquement"}
                name={"female"}
                value={"female"}
                labelPosition={"right"}
              />
              <RadioButton
                onChange={handleGenderChange}
                checked={selectedGender === "other"}
                color={"indigo"}
                label={"Autre"}
                name={"other"}
                value={"other"}
                labelPosition={"right"}
              />
              <RadioButton
                onChange={handleGenderChange}
                checked={selectedGender === "all"}
                color={"indigo"}
                label={"Peu importe"}
                name={"all"}
                value={"all"}
                labelPosition={"right"}
              />
            </div>
          </div>
          <div className="flex items-start">
            <p className="text-textgrey1 w-1/4 text-right  mr-4 mt-2">
              Location:
            </p>
            <div className="flex flex-col flex-1">
              <TextChooser
                chosenText={chosenLocation}
                setChosenText={setChosenLocation}
                texts={locations}
                setTexts={setLocations}
              />
            </div>
          </div>

          <div className="flex items-start">
            <p className="text-textgrey1 w-1/4 text-right  mr-4 mt-2">Type:</p>
            <div className="flex flex-col flex-1">
              <TextChooser
                chosenText={chosenType}
                setChosenText={setChosenType}
                texts={types}
                setTexts={setTypes}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="flex flex-col gap-2 p-5 border rounded w-[70%] justify-center shadow drop-shadow items-center my-2">
            <h1 className="font-bold">Sauvegarder votre configuration</h1>
            <p className="text-md">
              Donner un nom à votre configuration de population
            </p>
            <input type="text" placeholder="Configuration 1" className="px-1" />
            <button className="px-2 py-1 bg-lightblue2 text-white rounded">
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TargetPopulation;
