import { Fragment } from "react";
import CarsDisplay from "../../Components/carsDisplay";
import { DisplayCars } from "../../Layout/Container/CarsScreens";

function Cars() {
  return (
    <Fragment>
      <DisplayCars cars={<CarsDisplay />} />
    </Fragment>
  );
}

export default Cars;
