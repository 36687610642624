export const UserRole = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  OWNER: 'owner',
  MEMBER: 'member'
};

export const RoleOptions = [
  { value: UserRole.ADMIN, label: 'Admin' },
  { value: UserRole.SUPER_ADMIN, label: 'Super Admin' },
  { value: UserRole.OWNER, label: 'Owner' },
  { value: UserRole.MEMBER, label: 'Member' },
];
