import Car360ViewIcon from "../../stepper/horizentalStepper/icons/Car360ViewIcon";
import CarAnalysisIcon from "../../stepper/horizentalStepper/icons/CarAnalysisIcon";
import CarDamageIcon from "../../stepper/horizentalStepper/icons/CarDamageIcon";
import CardDetailsIcon from "../../stepper/horizentalStepper/icons/CarDetailsIcon";
import CarPricingIcon from "../../stepper/horizentalStepper/icons/CarPricingIcon";
import DataUploadIcon from "../../stepper/horizentalStepper/icons/DataUploadIcon";
import PreviewIcon from "../../stepper/horizentalStepper/icons/PreviewIcon";

export const steps = [
  {
    label: "Get ready!",
    description: `Get your OBD2 connector ready and hop into the driver's seat.`,
    withNext: true,
    withBack: false,
  },
  {
    label: "Connect to the Car's CAN System",
    description: "Follow the instructions on the illustration.",
    withNext: true,
    withBack: true,
  },
  {
    label: "Acceleration Test",
    description: `Follow the instructions on the illustration.`,
    withNext: false,
    withBack: true,
  },
  {
    label: "U-Turn Test",
    description: `Follow the instructions on the illustration.`,
    withNext: false,
    withBack: true,
  },
  {
    label: "Brake Test",
    description: `Follow the instructions on the illustration.`,
    withNext: false,
    withBack: true,
  },
  {
    label: "Finish",
    description: `Woo-hoo! The preview of your report is ready.`,
    withNext: false,
    withBack: true,
  },
];
export const baseSteps = [
  {
    label: "Car Details",
    component: CardDetailsIcon,
  },
  {
    label: "Car Damage",
    component: CarDamageIcon,
  },
  {
    label: "360° View",
    component: Car360ViewIcon,
  },
  {
    label: "Car Analysis",
    component: CarAnalysisIcon,
  },
  {
    label: "Car pricing",
    component: CarPricingIcon,
  },
  {
    label: "Data upload",
    component: DataUploadIcon,
  },
  {
    label: "Preview",
    component: PreviewIcon,
  },
];
