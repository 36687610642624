import React, { useRef, useCallback, useEffect } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Controls,
  Background,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Sidebar from "./Sidebar";
import { DnDProvider, useDnD } from "./DndContext";
import "./index.css";
import CustomNode from "./CustomNode";
import SimpleNode from "./SimpleNode";

const nodeTypes = {
  custom: CustomNode,
  simple: SimpleNode,
};

//TODO change id generator logic
let id = 0;
const getId = (name) => `${name}_${id++}`;

const FlowBuilder = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();
  const { type, data } = useDnD();

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, type: "straight" }, eds)),
    [type]
  );
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  useEffect(() => {
    const element = document.getElementsByClassName(
      "react-flow__panel react-flow__attribution bottom right"
    );

    if (element.length > 0) {
      element[0].style.display = "none";
    }
  }, []);

  const syncDataNode = (id, newObject) => {
    setNodes((prevNodes) => {
      const nodeIndex = prevNodes.findIndex((node) => node.id === id);
      if (nodeIndex !== -1) {
        const updatedNodes = [...prevNodes];
        updatedNodes[nodeIndex] = {
          ...updatedNodes[nodeIndex],
          data: { ...updatedNodes[nodeIndex].data, ...newObject },
        };
        return updatedNodes;
      }
      return prevNodes;
    });
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (!type) {
      return;
    }
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    const id = getId(data.id);
    const { _, ...dataWithoutId } = data;
    const newNode = {
      id,
      type,
      position,
      data: {
        ...dataWithoutId,
        id,
        deleteHandler: () => removeNode(id),
        syncDataNode,
      },
    };

    setNodes((nds) => nds.concat(newNode));
  };

  const removeNode = (id) => {
    setNodes((nodes) => nodes.filter((node) => node.id != id));
    setEdges((edgs) =>
      edgs.filter((edg) => {
        return edg.source != id && edg.target != id;
      })
    );
  };

  return (
    <div className="dndflow">
      <div className="reactflow-wrapper w-[200px]" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          className="bg-teal-50"
          minZoom={0.1}
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      <Sidebar />
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <DnDProvider>
      <FlowBuilder />
    </DnDProvider>
  </ReactFlowProvider>
);
