import React from "react";
import PassedIcon from "./icons/PassedIcon";
import WarningIcon from "./icons/WarningIcon";
const overViewDirectionReport = [
  {
    title: "Pompe de direction assistée",
    passed: true,
    value: 95,
  },
  {
    title: "Crémaillère de direction",
    passed: true,
    value: 93,
  },
  {
    title: "Barres de liaison",
    passed: true,
    value: 95,
  },
  {
    title: "Rotules",
    passed: true,
    value: 89,
  },
  {
    title: "Liquide de direction",
    passed: true,
    value: 97,
  },
];

const directionReport = [
  {
    title: "Rapport de direction",
    passed: true,
    value: "14,5:1",
  },
  {
    title: "Rayon de virage",
    passed: true,
    value: "5,5 m",
  },
  {
    title: "Effort de direction",
    passed: true,
    value: "Clair",
  },
  {
    title: "Retour au centre",
    passed: false,
    value: "Précis",
  },
  {
    title: "Mouvement du volant",
    passed: true,
    value: "< 2 cm",
  },
  {
    title: "Bruit de direction assistée",
    passed: true,
    value: "Aucun",
  },
  {
    title: "Dérive de l'alignement",
    passed: true,
    value: "Faible",
  },
];
function SteeringDetailed() {
  return (
    <div className="flex flex-col gap-10 px-5 my-10">
      <div class="flex p-4 flex-col gap-5">
        {overViewDirectionReport.map((report) => (
          <div class="w-full flex justify-between gap-3 px-3 items-center ">
            <span className="font-bold">{report.title}</span>
            <div className="flex items-center gap-2">
              <div className="bg-gray-200 rounded-full h-2 w-[250px] dark:bg-gray-700">
                <div
                  className={`h-2  rounded-full ${
                    report.passed ? "bg-[#118D57]" : "bg-[#FFAB00]"
                  }`}
                  style={{ width: `${report.value}%` }}
                ></div>
              </div>

              <small className="font-bold"> {report.value}</small>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col gap-7 px-5 pl-10 my-5">
        {directionReport.map((report) => (
          <div className="w-full flex justify-between items-center">
            <h1 className="font-bold text-l">{report.title}</h1>
            <div className="flex gap-2 items-center">
              <p>{report.value}</p>
              {report.passed ? <PassedIcon /> : <WarningIcon />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SteeringDetailed;
