import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ClearStoreData } from "../../../Redux/cars/actions";
import { useEffect } from "react";
import { dispatchNextStep } from "../../../Redux/camera/actions";
const StepsNav = ({ next, back }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dispatchNextStep(next));
  }, []);
  const cancel = () => {
    Swal.fire({
      title: "Vouler vous vraiment quitter?",
      position: "center",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: "#4368B1",
      cancelButtonText: "Non, continuer",
      confirmButtonText: "Oui",
      confirmButtonColor: " #4368B1",
    }).then((result) => {
      dispatch(ClearStoreData("ABORT"));
      if (result.isConfirmed) {
        navigate("/cars");
      }
    });
  };
  return (
    <div className="w-full px-3 py-2 ">
      <div className=" flex flex-row justify-between items-center ">
        <div className="flex flex-row items-center">
          {next === 0 ? (
            <>
              <button
                className="flex "
                disabled={true}
                onClick={(e) => back(e, next)}
              >
                <p
                  className=" text-[18px] px-3 font-bold "
                  // onClick={(e) => goBack(e)}
                >
                  {next === 0
                    ? "Ajouter une voiture"
                    : next === 1
                    ? "Images pour analyser"
                    : next === 2
                    ? "Detection de dégats"
                    : next === 3
                    ? "Retour"
                    : "3D View"}
                </p>
              </button>
            </>
          ) : (
            <button
              className="flex "
              disabled={false}
              onClick={(e) => back(e, next)}
            >
              <svg
                width="8"
                height="30"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 6.01L6.01 12.02L7.424 10.606L2.824 6.006L7.424 1.406L6.01 0L0 6.01Z"
                  fill="black"
                />
              </svg>
              <p
                className=" text-[18px] px-3 font-bold "
                // onClick={(e) => goBack(e)}
              >
                {next === 0
                  ? "Ajouter une voiture"
                  : next === 1
                  ? "Images pour analyser"
                  : next === 2
                  ? "Detection de dégats"
                  : next === 3
                  ? "Retour"
                  : "3D View"}
              </p>
            </button>
          )}
          <div className="group inline-block">
            <div className="flex lex-col relative ">
              <button>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.22292 18.777C2.1723 17.7623 1.3343 16.5485 0.757801 15.2065C0.181302 13.8644 -0.122147 12.421 -0.134839 10.9605C-0.147531 9.49988 0.130788 8.0514 0.683878 6.69954C1.23697 5.34768 2.05375 4.11951 3.08657 3.08669C4.11939 2.05387 5.34756 1.23709 6.69942 0.684C8.05128 0.13091 9.49976 -0.147408 10.9603 -0.134717C12.4209 -0.122025 13.8643 0.181424 15.2064 0.757923C16.5484 1.33442 17.7622 2.17243 18.7769 3.22304C20.7807 5.29766 21.8894 8.07629 21.8643 10.9605C21.8393 13.8446 20.6824 16.6036 18.6429 18.643C16.6034 20.6825 13.8445 21.8394 10.9603 21.8645C8.07617 21.8895 5.29754 20.7808 3.22292 18.777ZM9.89992 5.50004V12.1H12.0999V5.50004H9.89992ZM9.89992 14.3V16.5H12.0999V14.3H9.89992Z"
                    fill="#FFA500"
                  />
                </svg>
              </button>
              <span className="absolute top-8 -right-10 -left-14 w-[250px] z-10 text-black rounded-lg bg-grey3 hidden group-hover:block group-hover:opacity-100 opacity-0 transition-opacity duration-300 p-3 text-[14px]">
                {next === 0 ? (
                  <p>
                    Veiller télécharger une/des photo(s) du véhicule, remplir
                    ses caractéristiques et ensuite cliquer sur suivant.
                  </p>
                ) : next === 1 ? (
                  <ul className="list-disc">
                    <li>
                      Ajoutez une photo claire et nette de chaque dégât de la
                      voiture dans la case convenable.
                    </li>
                    <li>
                      {" "}
                      Veillez mettre l'integralité des dommages du véhicule.
                    </li>
                    <li>
                      {" "}
                      Veillez patienter un moment, les dommages de voitures sont
                      en cours d'analyse.
                    </li>
                  </ul>
                ) : null}
              </span>
            </div>
          </div>
        </div>

        <button className="flex" onClick={(e) => cancel(e)}>
          Annuler
        </button>
      </div>
    </div>
  );
};
export default StepsNav;
