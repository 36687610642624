import React from "react";
import EngineIcon from "./icons/EngineIcon";
import PassedIcon from "./icons/PassedIcon";
import WarningIcon from "./icons/WarningIcon";

const overviewEngineReport = [
  {
    title: "Huile de moteur",
    passed: true,
    value: 95,
  },
  {
    title: "Filtre à air",
    passed: false,
    value: 75,
  },
  {
    title: "Bougies d'allumage",
    passed: false,
    value: 80,
  },
  {
    title: "Liquide de refroidissement",
    passed: true,
    value: 95,
  },
  {
    title: "Système de carburant",
    passed: true,
    value: 95,
  },
];

const engineReport = [
  {
    title: "Chevaux-vapeur",
    passed: true,
    value: "280hp",
  },
  {
    title: "Couple",
    passed: true,
    value: "350 Nm",
  },
  {
    title: "Efficacité énergétique",
    passed: true,
    value: "28 mpg",
  },
  {
    title: "Taux de compression",
    passed: false,
    value: "8:1",
  },
  {
    title: "Émission des gaz d'échappement",
    passed: true,
    value: "Conforme aux limites",
  },
  {
    title: "RPM au ralenti",
    passed: true,
    value: "750 rpm",
  },
  {
    title: "Pression de l'huile",
    passed: true,
    value: "40 psi",
  },
];
function EngineDetailed() {
  return (
    <div className="flex flex-col p-5">
      <div className="flex flex-row items-center mb-4">
        <div className="flex flex-[0.7] p-4 flex-col gap-5">
          {overviewEngineReport.map((report) => (
            <div className="w-full flex justify-between gap-3 px-3 items-center ">
              <span className="font-bold">{report.title}</span>
              <div className="flex items-center gap-2">
                <div className="bg-gray-200 rounded-full h-1.5 w-[150px] dark:bg-gray-700">
                  <div
                    className={`h-1.5 rounded-full ${
                      report.passed ? "bg-[#118D57]" : "bg-[#FFAB00]"
                    }`}
                    style={{ width: `${report.value}%` }}
                  ></div>
                </div>

                <small className="font-bold"> {report.value}</small>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-[0.3] ">
          <EngineIcon />
        </div>
      </div>
      <div className="w-full flex flex-col gap-5 px-5 my-10">
        {engineReport.map((report) => (
          <div className="w-full flex justify-between items-center">
            <h1 className="font-bold text-l">{report.title}</h1>
            <div className="flex gap-2 items-center">
              <p>{report.value}</p>
              {report.passed ? <PassedIcon /> : <WarningIcon />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EngineDetailed;
