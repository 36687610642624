import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carouselStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { isMobile } from "react-device-detect";

export default function CarouselPreview({ data }) {
  // All Images sent to this component needs to have their image link attribute name "url"
  // WRONG
  // eg :*
  //  data {
  // image : "http://test/test.js"
  // }
  // CORRECT
  // eg :
  //  data {
  // url : "http://test/test.js"
  // }
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="w-full relative rounded-lg shadow bg-white0 lg:p-5 max-h-full lg:border lg:border-grey2">
      <div className="font-bold text-[19px] text-warning1 py-3 px-3 ">
        Aperçu de la voiture
      </div>
      {!data.length ? (
        <div className=" font-bold text-[20px] bg-[#EDEDED] box box-border  md:h-[300px] h-[400px]  border-2 p-5 border-[#EDEDED] space-y-4  flex flex-col justify-center items-center">
          <p className="text-grey1">La voiture apparaîtrait ici</p>
        </div>
      ) : (
        <>
          <div className="absolute h-[calc(100%-94px)] w-[calc(100%-40px)]">
            <img
              src={data[activeIndex].url}
              alt="back-car"
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 backdrop-blur-sm bg-[#00000050]" />
          </div>

          <div className="h-[calc(100%-54px)] relative font-bold text-[20px] box box-border  flex flex-col justify-center items-center">
            <Swiper
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[FreeMode, Navigation, Thumbs, Pagination]}
              className="flex flex-col justify-center items-center damage-carousel-swiper"
              slidesPerView={1}
              onSlideChange={(res) => setActiveIndex(res.activeIndex)}
            >
              {data.map((imageData, index) => (
                <SwiperSlide key={`analysed-image-slide-${index} py-4`}>
                  <div className="h-full py-6">
                    <img
                      className="mx-auto w-full object-contain block h-full"
                      src={imageData.url}
                      alt={``}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="w-1/2">
              <Swiper
                onSwiper={setThumbsSwiper}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="relative flex flex-col justify-center items-center my-4 damage-carousel-swiper-bottom"
                slidesPerView={3}
                spaceBetween={10}
              >
                {data.map((imageData, index) => (
                  <SwiperSlide
                    key={index}
                    className="!h-auto  rounded-xl overflow-hidden self-auto"
                  >
                    <div className="relative flex flex-col justify-center items-center h-full">
                      <img
                        className={
                          "mx-auto w-full object-contain block md:max-h-[500px]"
                        }
                        alt="data-item"
                        src={imageData.url}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
