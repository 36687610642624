import React from "react";
import AskIcon from "../icons/AskIcon";

function ConfirmChosenCar({ isOpen, onContinue, onBack, cars }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[550px] flex flex-col justify-center items-center">
        <h2 className="text-black font-bold text-xl mb-4 text-center">

          {`Etes-vous sûr de vouloir continuer avec ${
            cars.length === 1 ? "ce véhicule" : "ces véhicules"
          }?`}
        </h2>
        <div className="px-10 mb-5 flex flex-col justify-center items-center gap-3">
          <AskIcon height={70} />
          <div className="flex flex-col justify-center items-center ">
            {cars.map((car) => (
              <span className="text-sm">{`${car.make}, ${car.model}, ${car.overviewYear}, ${car.price}€`}</span>
            ))}

          </div>
          <span className="cursor-pointer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.375 3V10.5H13.625V3H2.375ZM2.375 1.75H13.625C13.9565 1.75 14.2745 1.8817 14.5089 2.11612C14.7433 2.35054 14.875 2.66848 14.875 3V10.5C14.875 10.8313 14.7437 11.15 14.5063 11.3813C14.275 11.6187 13.9563 11.75 13.625 11.75H9.25V13H10.5V14.25H5.5V13H6.75V11.75H2.375C2.04375 11.75 1.725 11.6187 1.49375 11.3813C1.25625 11.15 1.125 10.8313 1.125 10.5V3C1.125 2.30625 1.68125 1.75 2.375 1.75ZM7.275 6.08125C7.46875 5.89375 7.73125 5.78125 8 5.78125C8.26875 5.7875 8.53125 5.89375 8.725 6.0875C8.9125 6.275 9.025 6.5375 9.025 6.80625C9.025 7.08125 8.9125 7.3375 8.725 7.53125C8.53125 7.725 8.26875 7.83125 8 7.83125C7.73125 7.83125 7.46875 7.71875 7.275 7.53125C7.0875 7.3375 6.975 7.08125 6.975 6.80625C6.975 6.5375 7.0875 6.275 7.275 6.08125ZM6.79375 8C6.94996 8.16227 7.13731 8.29135 7.34457 8.37952C7.55184 8.46768 7.77476 8.51313 8 8.51313C8.22524 8.51313 8.44816 8.46768 8.65543 8.37952C8.86269 8.29135 9.05004 8.16227 9.20625 8C9.525 7.6875 9.70625 7.25625 9.70625 6.80625C9.70625 6.35625 9.525 5.91875 9.20625 5.6C8.8875 5.28125 8.45 5.1 8 5.1C7.55 5.1 7.1125 5.28125 6.79375 5.6C6.475 5.91875 6.29375 6.35625 6.29375 6.80625C6.29375 7.25625 6.475 7.6875 6.79375 8ZM4.25 6.80625C4.54617 6.05293 5.06227 5.40621 5.73111 4.95029C6.39995 4.49436 7.19055 4.25034 8 4.25C9.70625 4.25 11.1625 5.3125 11.75 6.80625C11.4559 7.56102 10.9404 8.20913 10.271 8.66538C9.60174 9.12162 8.81002 9.36465 8 9.3625C6.29375 9.3625 4.8375 8.3125 4.25 6.80625Z"
                fill="#4368B1"
              />
            </svg>
          </span>
        </div>
        <div className="flex flex-row-reverse gap-4 w-full">
          <button
            className="px-4 py-1 bg-lightblue2 font-bold text-white rounded-lg"
            onClick={onContinue}
          >
            Oui
          </button>
          <button
            className="px-4 py-1 border font-bold text-black rounded-lg"
            onClick={onBack}
          >
            Retour à la page précédente
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmChosenCar;
