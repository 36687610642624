import React from "react";
import BackButton from "../backButton/BackButton";

function ConfirmSection({ goBack: goBack }) {
  return (
    <>
      <BackButton handleBack={goBack} />
      <div className="w-full max-w-md mx-auto mt-10 p-2 min-h-[500px] flex flex-col justify-center text-center">
        <div className="bg-gray-200 rounded-xl flex flex-col justify-center items-center p-5">
          <svg
            width="33"
            height="29"
            viewBox="0 0 33 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.7852 28.3719H2.2135C1.66273 28.3719 1.15379 28.078 0.878409 27.601C0.603027 27.124 0.60303 26.5364 0.878419 26.0594L15.1635 1.39272C15.4391 0.91652 15.9476 0.623291 16.4978 0.623291C17.048 0.623291 17.5565 0.91652 17.8321 1.39272L32.1172 26.0594C32.3925 26.5361 32.3926 27.1235 32.1176 27.6004C31.8426 28.0773 31.3342 28.3713 30.7837 28.3719H30.7852ZM14.9585 20.6636V23.7469H16.3968H16.497H16.5973H18.0387V20.6636H14.9585ZM14.9585 9.87189V17.5802H18.0418V9.87189H14.9585Z"
              fill="#282F75"
            />
          </svg>

          <h3 className="text-black font-bold mt-5">
            Please make sure that all steps are completed before uploading. Once
            data are upload, any modification could be charged
          </h3>
        </div>
      </div>
    </>
  );
}

export default ConfirmSection;
