import React from "react";

function AddIconTimesheetIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C9.10457 0 10 0.839466 10 1.875V13.125C10 14.1605 9.10457 15 8 15C6.89543 15 6 14.1605 6 13.125V1.875C6 0.839466 6.89543 0 8 0Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7.875C0 6.83947 0.89543 6 2 6H14C15.1046 6 16 6.83947 16 7.875C16 8.91053 15.1046 9.75 14 9.75H2C0.89543 9.75 0 8.91053 0 7.875Z"
        fill="white"
      />
    </svg>
  );
}

export default AddIconTimesheetIcon;
