import { useRef, useState, useEffect } from "react";
import useCaretPosition from "./useCaretPosition";
import ImageRenderer from "./Image";
//TODO add config for max file upload
const maxFileUpload = 2;

const cars = ["Audi", "BMW", "Chevrolet", "Ford", "Honda"];
const SlashCommandInput = ({ appendMessage }) => {
  const [selectedText, setSelectedText] = useState("");
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const exceededFiles = files.length > maxFileUpload;
  const { x, y, getPosition: getPositionBasic } = useCaretPosition(inputRef);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeText = (e) => {
    const inputValue = e.target.value;
    setSelectedText(inputValue);
    if (inputValue.endsWith("/")) {
      setAnchorEl(e.target);
      getPositionBasic();
    } else {
      setAnchorEl(null);
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onListChoose = (car) => () => {
    const text =
      selectedText.substring(0, selectedText.lastIndexOf("/")) + `${car} `;
    setSelectedText(text);
    setAnchorEl(null);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (event) => {
    //TODO add more commands Enter to send message (ignore enter if shift is pressed for new line)
    if (event.key === "ArrowDown") {
      setSelectedIndex((prev) => (prev < cars.length - 1 ? prev + 1 : prev));
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
      event.preventDefault();
    } else if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      if (selectedIndex >= 0) {
        const carName = cars[selectedIndex];
        const newValue =
          selectedText.substring(0, selectedText.lastIndexOf("/")) +
          carName +
          " ";
        setSelectedText(newValue);
      }
      setAnchorEl(null);
      setSelectedIndex(-1);
      handleFocus();
    }
  };

  const handleDelete = (id) => {
    const newFiles = files.filter((file) => file.id !== id);
    setFiles(newFiles);
  };

  const handleChooseFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newFile = {
        id: files.length + 1,
        source: file,
      };
      setFiles([...files, newFile]);
    }
  };
  const openChooseFile = () => {
    if (exceededFiles) {
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const sendMessage = () => {
    if (selectedText !== "") {
      appendMessage({
        type: "simple",
        message: selectedText,
        me: true,
        time: "12:00",
        files,
      });
      setSelectedText("");
      setFiles([]);
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      getPositionBasic(inputRef);
    }
  }, []);

  return (
    <div className="sticky bottom-1 w-full bg-white0 border border-gray-300 rounded-md p-2 mb-4 mt-5">
      <div className="w-full p-1">
        <textarea
          ref={inputRef}
          value={selectedText}
          onChange={handleChangeText}
          placeholder="Type '/' to open the popover"
          className="w-full p-2 pr-8 outline-none resize-none"
          rows={1}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
        {anchorEl && (
          <div
            className={`absolute bg-white border border-gray-300 rounded-md shadow-lg`}
            style={{
              top: y - 200,
              left: x - 20,
              zIndex: 10,
            }}
          >
            <ul className="max-h-60 overflow-y-auto">
              {cars.map((car, index) => (
                <li
                  key={index}
                  onMouseEnter={() => setSelectedIndex(index)}
                  onClick={onListChoose(car)}
                  className={`cursor-pointer p-2 hover:bg-blue-100 ${
                    index === selectedIndex ? "bg-blue-200" : ""
                  }`}
                >
                  {car}
                </li>
              ))}
            </ul>
          </div>
        )}
        {files.length > 0 && (
          <div className="flex flex-wrap overflow-auto">
            {files.map((file) => (
              <div key={file.id} className="flex items-center">
                <ImageRenderer
                  canDeleteImage
                  image={file}
                  handleDelete={handleDelete}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between items-center">
        <span className="text-grey1 text-sm select-none">
          Utiliser <u>shift + entrer</u> pour nouvelle ligne
        </span>
        <div className="flex flex-row gap-2 items-center">
          <span
            className="bg-white0 border-2 border-[#CBD5E1] p-1 rounded-full cursor-pointer"
            onClick={openChooseFile}
          >
            <input
              type="file"
              className="hidden"
              onChange={handleChooseFile}
              ref={fileInputRef}
            />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              opacity={exceededFiles ? 0.5 : 1}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9203 12.7959L12.2272 20.4834C11.1719 21.5383 9.74089 22.1308 8.24884 22.1305C6.75678 22.1302 5.32594 21.5373 4.27108 20.482C3.21622 19.4268 2.62376 17.9957 2.62402 16.5037C2.62429 15.0116 3.21726 13.5808 4.27249 12.5259L13.475 3.34875C14.1785 2.64559 15.1326 2.25071 16.1272 2.25098C16.6197 2.25111 17.1074 2.34824 17.5624 2.53684C18.0174 2.72544 18.4307 3.0018 18.7789 3.35015C19.1271 3.69851 19.4032 4.11202 19.5916 4.5671C19.7799 5.02217 19.8768 5.50989 19.8767 6.0024C19.8765 6.49491 19.7794 6.98258 19.5908 7.43755C19.4022 7.89252 19.1258 8.30589 18.7775 8.65406L18.7615 8.66906L9.7803 17.3147C9.67381 17.4171 9.54818 17.4976 9.41059 17.5514C9.27301 17.6053 9.12616 17.6316 8.97842 17.6287C8.83069 17.6258 8.68497 17.5939 8.54958 17.5347C8.4142 17.4755 8.29179 17.3902 8.18936 17.2837C8.08693 17.1773 8.00648 17.0516 7.9526 16.914C7.89872 16.7765 7.87246 16.6296 7.87534 16.4819C7.87821 16.3341 7.91015 16.1884 7.96934 16.053C8.02853 15.9176 8.11381 15.7952 8.2203 15.6928L17.1931 7.05656C17.4736 6.77435 17.6305 6.39229 17.6292 5.99442C17.628 5.59655 17.4688 5.21546 17.1866 4.935C17.0468 4.79613 16.8811 4.68614 16.6989 4.61131C16.5166 4.53648 16.3214 4.49828 16.1244 4.49889C15.7265 4.50012 15.3455 4.65935 15.065 4.94156L5.86249 14.1159C5.22957 14.7491 4.8741 15.6078 4.87428 16.503C4.87437 16.9463 4.96176 17.3852 5.13148 17.7947C5.3012 18.2042 5.54991 18.5763 5.86343 18.8897C6.17694 19.2031 6.5491 19.4516 6.95868 19.6212C7.36825 19.7908 7.80721 19.878 8.25049 19.8779C9.14575 19.8777 10.0043 19.5219 10.6372 18.8887L18.3303 11.2012C18.4349 11.0966 18.5592 11.0136 18.6959 10.957C18.8326 10.9003 18.9792 10.8712 19.1272 10.8712C19.2752 10.8712 19.4217 10.9003 19.5584 10.957C19.6952 11.0136 19.8194 11.0966 19.924 11.2012C20.0287 11.3059 20.1117 11.4301 20.1683 11.5669C20.225 11.7036 20.2541 11.8501 20.2541 11.9981C20.2541 12.1461 20.225 12.2927 20.1683 12.4294C20.1117 12.5661 20.0287 12.6904 19.924 12.795L19.9203 12.7959Z"
                fill="#475569"
              />
            </svg>
          </span>
          <span className="bg-white0 border-2 border-[#CBD5E1] p-1 rounded-full">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              opacity={0.5}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1188 20.5054L13.0781 20.5108V20.5519V22.5C13.0781 22.7859 12.9645 23.0602 12.7623 23.2623C12.5602 23.4645 12.2859 23.5781 12 23.5781C11.7141 23.5781 11.4398 23.4645 11.2377 23.2623C11.0355 23.0602 10.9219 22.7859 10.9219 22.5V20.5519V20.5108L10.8812 20.5054C8.81871 20.2313 6.92581 19.218 5.55395 17.6537C4.1821 16.0895 3.42451 14.0805 3.42187 11.9999L3.375 12H3.42188C3.42188 11.7141 3.53546 11.4398 3.73765 11.2377C3.93984 11.0355 4.21406 10.9219 4.5 10.9219C4.78594 10.9219 5.06016 11.0355 5.26235 11.2377C5.46454 11.4398 5.57812 11.7141 5.57812 12C5.57812 13.7032 6.25471 15.3366 7.45905 16.541C8.66338 17.7453 10.2968 18.4219 12 18.4219C13.7032 18.4219 15.3366 17.7453 16.541 16.541C17.7453 15.3366 18.4219 13.7032 18.4219 12C18.4219 11.7141 18.5355 11.4398 18.7377 11.2377C18.9398 11.0355 19.2141 10.9219 19.5 10.9219C19.7859 10.9219 20.0602 11.0355 20.2623 11.2377C20.4645 11.4398 20.5781 11.714 20.5781 11.9999C20.5755 14.0805 19.8179 16.0895 18.446 17.6537C17.0742 19.218 15.1813 20.2313 13.1188 20.5054ZM15.4124 15.4124C14.5072 16.3175 13.28 16.8266 12 16.8281C10.72 16.8266 9.49277 16.3175 8.58764 15.4124C7.6825 14.5072 7.17335 13.28 7.17188 11.9999V6C7.17188 4.7195 7.68055 3.49145 8.586 2.586C9.49145 1.68055 10.7195 1.17188 12 1.17188C13.2805 1.17188 14.5086 1.68055 15.414 2.586C16.3194 3.49145 16.8281 4.7195 16.8281 6V11.9999C16.8267 13.28 16.3175 14.5072 15.4124 15.4124ZM10.1107 4.1107C9.60963 4.61177 9.32812 5.29137 9.32812 6V12C9.32812 12.7086 9.60963 13.3882 10.1107 13.8893C10.6118 14.3904 11.2914 14.6719 12 14.6719C12.7086 14.6719 13.3882 14.3904 13.8893 13.8893C14.3904 13.3882 14.6719 12.7086 14.6719 12V6C14.6719 5.29137 14.3904 4.61177 13.8893 4.1107C13.3882 3.60963 12.7086 3.32812 12 3.32812C11.2914 3.32812 10.6118 3.60963 10.1107 4.1107Z"
                fill="#475569"
                stroke="#475569"
                stroke-width="0.09375"
              />
            </svg>
          </span>
          <button
            onClick={sendMessage}
            className="bg-blue1 hover:bg-lightblue1 text-white0 w-[100px] flex flex-row gap-2 justify-center px-2 py-1 rounded-lg"
          >
            Envoyer
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2703 8.63119L5.14063 1.139C4.8638 0.983934 4.54632 0.91682 4.23041 0.946591C3.91451 0.976361 3.61516 1.1016 3.37217 1.30566C3.12919 1.50972 2.95409 1.78292 2.87016 2.08892C2.78623 2.39492 2.79746 2.71923 2.90235 3.01869L5.26016 9.99994L2.90235 16.9812C2.81945 17.2167 2.79421 17.4687 2.82872 17.7159C2.86324 17.9632 2.95651 18.1986 3.10073 18.4025C3.24495 18.6063 3.43591 18.7726 3.65762 18.8874C3.87933 19.0022 4.12532 19.0622 4.375 19.0624C4.6435 19.0619 4.90737 18.9925 5.14141 18.8609L5.14844 18.8562L18.2734 11.3507C18.5141 11.2145 18.7143 11.0169 18.8536 10.778C18.9928 10.5391 19.0662 10.2675 19.0662 9.99096C19.0662 9.71443 18.9928 9.44285 18.8536 9.20396C18.7143 8.96506 18.5141 8.76742 18.2734 8.63119H18.2703ZM4.93672 16.8171L6.92266 10.9374H11.25C11.4986 10.9374 11.7371 10.8387 11.9129 10.6629C12.0887 10.487 12.1875 10.2486 12.1875 9.99994C12.1875 9.7513 12.0887 9.51285 11.9129 9.33703C11.7371 9.16121 11.4986 9.06244 11.25 9.06244H6.92266L4.93594 3.18119L16.8703 9.99135L4.93672 16.8171Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
export default SlashCommandInput;
