import React from "react";

function FacebookIcon({ width, height }) {
  return (
    <svg
      width={width ?? "48"}
      height={height ?? "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_89_14719)">
        <path
          d="M48 24C48 10.7453 37.2547 0 24 0C10.7453 0 0 10.7453 0 24C0 35.255 7.74912 44.6995 18.2026 47.2934V31.3344H13.2538V24H18.2026V20.8397C18.2026 12.671 21.8995 8.8848 29.9194 8.8848C31.44 8.8848 34.0637 9.18336 35.137 9.48096V16.129C34.5706 16.0694 33.5866 16.0397 32.3645 16.0397C28.4294 16.0397 26.9088 17.5306 26.9088 21.4061V24H34.7482L33.4013 31.3344H26.9088V47.8243C38.7926 46.3891 48.001 36.2707 48.001 24H48Z"
          fill="#0866FF"
        />
        <path
          d="M33.4005 31.3344L34.7473 24H26.908V21.406C26.908 17.5305 28.4286 16.0396 32.3636 16.0396C33.5857 16.0396 34.5697 16.0694 35.1361 16.1289V9.48093C34.0628 9.18237 31.4392 8.88477 29.9185 8.88477C21.8987 8.88477 18.2017 12.671 18.2017 20.8396V24H13.2529V31.3344H18.2017V47.2934C20.0584 47.7542 22.0005 48 23.9992 48C24.9832 48 25.9537 47.9395 26.907 47.8243V31.3344H33.3995H33.4005Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_89_14719">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookIcon;
