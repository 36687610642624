const CameraButtons = ({
  isCaptured,
  isRecording,
  handleStartCapture,
  handleStopCapture,
  handleRetakeCapture,
  handleCloseCamera,
}) => {
  return (
    <div className="ralative">
      {isCaptured ? (
        <div className="flex justify-center">
          <div className="flex text-[14px] overflow-hidden w-15 items-center justify-center rounded-full m-1 flex-wrap drop-shadow-md">
            <button
              onClick={handleRetakeCapture}
              className="text-white0 rounded-md bg-blue1 px-10 py-3"
            >
              Recommencer
            </button>
          </div>
          <div className="flex text-[14px] overflow-hidden w-15 items-center justify-center rounded-full m-1 flex-wrap drop-shadow-md">
            <button
              onClick={handleCloseCamera}
              className="text-white0 rounded-md bg-blue1 px-10 py-3"
            >
              Terminer
            </button>
          </div>
        </div>
      ) : (
        <div className="relative">
          {!isRecording ? (
            <div className="flex text-[14px] overflow-hidden w-15 items-center justify-center rounded-full m-1 flex-wrap drop-shadow-md">
              <button
                onClick={handleStartCapture}
                className="text-white0 rounded-md bg-blue1 px-10 py-3"
              >
                Start Recording
              </button>
            </div>
          ) : (
            <div className="flex text-[14px] overflow-hidden w-15 items-center justify-center rounded-full m-1 flex-wrap drop-shadow-md">
              <button
                onClick={handleStopCapture}
                className="text-white0 rounded-md bg-blue1 px-10 py-3"
              >
                Stop Recording
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CameraButtons;
