import { PROFILE_SUCCESS, EDIT_PROFILE, IS_LOADING } from "./actionTypes";

const initialState = {
  error: "",
  profile: "",
  loading: false,
};

const profile = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, profile: payload };
      break;

    case IS_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
