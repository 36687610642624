import React from "react";

function BagIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7615 16.9166H4.80495C2.24965 16.9166 0.289314 15.9937 0.84614 12.279L1.4945 7.24463C1.83775 5.39108 3.02005 4.6817 4.05743 4.6817H12.5395C13.5921 4.6817 14.7058 5.44448 15.1024 7.24463L15.7508 12.279C16.2237 15.5741 14.3168 16.9166 11.7615 16.9166Z"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.876 4.49865C11.876 2.51026 10.2641 0.898348 8.27572 0.898348V0.898348C7.31822 0.894291 6.39854 1.27182 5.72005 1.94744C5.04156 2.62307 4.66015 3.54114 4.66016 4.49865H4.66016"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7471 8.25151H10.709"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.88776 8.25151H5.84962"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BagIcon;
