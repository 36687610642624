import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const AddMember = () => {
  const user = useSelector((state) => state.profile).profile;
  const userId = user._id;
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  //addMember
  const addMember = async (email, role, userId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const payload = { email, role, userId };
    const body = JSON.stringify(payload);
    if (email === "" || role === "")
      Swal.fire({
        text: "Les champs E-mail et Autorisation sont obligatoire",
        icon: "warning",
        iconColor: "#4368B1",
        confirmButtonColor: "#4368B1",
        confirmButtonText: "quitter",
      });
    else {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_SERVICE}/api/user/adduser`,
          body,
          config
        )
        .then((res) => {
          setEmail("");
          setRole("");
          Swal.fire({
            title: "Succée",
            text: "L'utilisateur a été ajouter avec succée!",
            iconColor: "#4368B1",
            icon: "success",
            confirmButtonColor: "#4368B1",
            confirmButtonText: "Finir",
          });
        })
        .catch((err) => {
          if (err.response.data.error === "User already exists") {
            Swal.fire({
              text: "Cet utilisateur existe deja !",
              icon: "warning",
              iconColor: "#4368B1",
              confirmButtonColor: "#4368B1",
              confirmButtonText: "quitter",
            });
          } else {
            Swal.fire({
              text: err.response.data.error,
              icon: "warning",
              iconColor: "#4368B1",
              confirmButtonColor: "#4368B1",
              confirmButtonText: "quitter",
            });
          }

          setEmail("");
          setRole("");
        });
    }
  };
  return (
    <div className="flex flex-col justify-between bg-white0 sm:bg-background md:bg-white0 drop-shadow-lg sm:drop-shadow-none md:drop-shadow-lg rounded-lg p-5 h-full md:h-[720px]  ">
      <div className="flex flex-col space-y-5 ">
        <h2 className="font-bold  text-[22px]">Adresse E-mail</h2>

        <div className="flex flex-col      ">
          <div className="flex flex-col">
            <input
              className="flex p-5  shadow-xl  border-b border-grey5 rounded-lg"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
            />
          </div>
        </div>

        <h2 className="font-bold  text-[22px]">Autorisations</h2>

        {[
          {
            title: " Accès administrateur",
            role: "admin",
            description:
              "Idéal pour les directeurs et les administrateurs d'entreprise qui ont besoin d'un accès complet.",
          },
          {
            title: "Accès lecture",

            role: "member",
            description: "Idéal pour les employés qui analysent les paiements.",
          },
        ]
          .filter((e) => (user.role === "owner" ? e : e.role === "member"))
          .map((e) => (
            <div
              key={e.title}
              className="mb-5  items-center shadow-xl  border-b border-grey5 rounded-lg  flex p-5 bg-white0"
            >
              <input
                id="default-radio-1"
                type="radio"
                value={e.role}
                name="role"
                // className=" w-4 h-4 accent-blue1"
                className="my-auto transform scale-150 accent-blue1"
                onChange={(e) => setRole(e.target.value)}
              />

              <div className="px-5">
                <h2 className="text-[20px]  font-bold text-left ">{e.title}</h2>
                <h2 className="text-[16px]  font-normal text-[#A0AEBD] text-left ">
                  {e.description}
                </h2>
              </div>
            </div>
          ))}
      </div>

      <div className="flex fixed lg:relative justify-end bottom-2 right-0  lg:bottom-0 lg:right-10 left-0 lg:justify-end pr-2">
        <button
          className="box-border w-[282px] h-[54px] border-1 justify-center rounded-lg text-white0 bg-blue1  text-[20px] font-bold  "
          type="submit"
          onClick={() => addMember(email, role, userId)}
        >
          Invitez
        </button>
      </div>
    </div>
  );
};
export default AddMember;
