import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

//TODO change to get queries from WS
const defaultQueries = {
  today: [
    {
      title: "How to create a new account 1 ",
      id: 1,
    },
    {
      title: "How to create a new account 2",
      id: 2,
    },
    {
      title: "How to create a new account 3",
      id: 3,
    },
  ],
  recent: [
    {
      title: "How to create a new account 4",
      id: 4,
    },
    {
      title: "How to create a new account 5",
      id: 5,
    },
    {
      title: "How to create a new account 6",
      id: 6,
    },
  ],
  basic: [
    {
      title: "How to create a new account 4",
      id: 7,
    },
    {
      title: "How to create a new account 5",
      id: 8,
    },
    {
      title: "How to create a new account 6",
      id: 9,
    },
  ],
  hot: [
    {
      title: "How to create a new account 4",
      id: 10,
    },
    {
      title: "How to create a new account 5",
      id: 11,
    },
    {
      title: "How to create a new account 6",
      id: 12,
    },
  ],
};

//TODO refactor component for smaller components
function Queries({ closeQueries }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [openedAccordions, setOpenedAccordions] = useState(
    Object.keys(defaultQueries)
  );

  const toggleAccordion = (label) => () => {
    if (openedAccordions.includes(label)) {
      setOpenedAccordions(openedAccordions.filter((item) => item !== label));
    } else {
      setOpenedAccordions([...openedAccordions, label]);
    }
  };

  const continueChat = (id) => () => {
    navigate(`/steer-ai/continue/${id}`);
  };
  const createChat = () => {
    navigate(`/steer-ai`);
  };
  const isCurrentQuery = (queryId) => {
    return String(queryId) === String(id);
  };
  return (
    <div className="bg-white0 mr-1 absolute w-full h-full overflow-auto pb-2 z-10 md:static md:w-[400px] flex flex-col gap-1 p-2">
      <div className="flex justify-end">
        <span
          onClick={closeQueries}
          className="p-2 rounded-full border-2 border-[#CBD5E1]"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.56776 4.57307C4.73058 4.735 4.81199 4.92793 4.81198 5.15186C4.81198 5.37577 4.73058 5.56624 4.56776 5.72327L3.10241 7.18062L9.33734 7.18062C9.56736 7.18062 9.76119 7.25936 9.91885 7.41684C10.0765 7.57433 10.1553 7.76796 10.1553 7.99773C10.1553 8.22752 10.0765 8.41996 9.91885 8.57504C9.76119 8.73012 9.56736 8.80766 9.33734 8.80766L3.14372 8.80766L4.64138 10.2972C4.79043 10.4387 4.86301 10.6196 4.85913 10.8398C4.85524 11.06 4.77041 11.2515 4.60464 11.4141C4.44944 11.5768 4.25544 11.6562 4.02264 11.6523C3.78985 11.6484 3.59204 11.5655 3.42922 11.4036L0.554167 8.54423C0.470474 8.45899 0.408843 8.36758 0.369274 8.26999C0.32972 8.17239 0.309942 8.07225 0.309942 7.96957C0.309942 7.86688 0.32972 7.76795 0.369274 7.67279C0.408843 7.57762 0.470474 7.48768 0.554168 7.40297L3.42024 4.55254C3.56929 4.4043 3.75515 4.33018 3.97783 4.33018C4.20051 4.33018 4.39715 4.41115 4.56776 4.57307ZM14.0423 0.309941C14.493 0.309941 14.8803 0.469842 15.2042 0.789644C15.528 1.10943 15.6899 1.49188 15.6899 1.93698L15.6899 14.0513C15.6899 14.4996 15.528 14.8848 15.2042 15.2068C14.8803 15.5289 14.493 15.6899 14.0423 15.6899L8.76899 15.6899C8.53898 15.6899 8.34514 15.6089 8.18749 15.4469C8.02983 15.2849 7.95101 15.0893 7.95101 14.8602C7.95101 14.6311 8.02983 14.439 8.18749 14.2839C8.34514 14.1288 8.53898 14.0513 8.76899 14.0513L14.0423 14.0513L14.0423 1.93698L8.76899 1.93698C8.53898 1.93698 8.34514 1.85824 8.18749 1.70077C8.02984 1.54327 7.95101 1.34964 7.95101 1.11987C7.95101 0.890079 8.02984 0.697645 8.18749 0.542563C8.34514 0.387481 8.53898 0.309941 8.76899 0.309941L14.0423 0.309941Z"
              fill="#1B2559"
            />
          </svg>
        </span>
      </div>
      <div className="flex justify-center items-center flex-col gap-2">
        <span>
          <svg
            width="50"
            height="46"
            viewBox="0 0 50 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44 28.6389V33.9556C44 35.6824 44 36.5458 43.6639 37.2054C43.3683 37.7855 42.8966 38.2572 42.3165 38.5528C41.6569 38.8889 40.7935 38.8889 39.0667 38.8889H24.7264C23.3009 38.8889 21.9337 39.3688 20.9257 40.2232L16.4999 43.9743C14.6255 45.5629 11.75 44.2307 11.75 41.7736V41.7736C11.75 40.1804 10.4585 38.8889 8.86527 38.8889H5.93333C4.2065 38.8889 3.34309 38.8889 2.68353 38.5528C2.10336 38.2572 1.63167 37.7855 1.33606 37.2054C1 36.5458 1 35.6824 1 33.9556V11.9333C1 10.2065 1 9.34309 1.33606 8.68353C1.63167 8.10336 2.10336 7.63167 2.68353 7.33606C3.34309 7 4.2065 7 5.93333 7H20.9257"
              stroke="#282F75"
              stroke-width="1.54167"
              stroke-linecap="round"
            />
            <path
              d="M36.9714 18.2591L35.3579 26.5265C35.3571 26.5364 35.3571 26.5462 35.3579 26.556L35.144 27.6411C35.0691 28.036 34.8419 28.3943 34.5028 28.6523C34.1637 28.9103 33.7344 29.0514 33.2912 29.0507H32.9286C32.9196 29.0425 32.9141 29.0327 32.9069 29.0261C32.5529 28.6492 32.1114 28.3475 31.6138 28.1424C31.1163 27.9373 30.5748 27.8339 30.028 27.8394C28.8508 27.8549 27.7281 28.2903 26.9043 29.0507H19.4097C19.4006 29.0425 19.3952 29.0327 19.3861 29.0261C19.0324 28.6494 18.5912 28.3479 18.0939 28.1428C17.5967 27.9377 17.0556 27.8341 16.509 27.8394C15.3359 27.8552 14.2166 28.2873 13.3926 29.0425C13.1304 29.0261 12.875 28.9601 12.6429 28.8486C12.4107 28.7372 12.2071 28.5829 12.0452 28.3957C11.8833 28.2086 11.7668 27.9927 11.7031 27.7622C11.6395 27.5316 11.6302 27.2916 11.6758 27.0576L13.7806 16.266H35.1168C35.3915 16.2653 35.663 16.3192 35.9122 16.4237C36.1614 16.5282 36.3822 16.6809 36.5591 16.8709C36.7359 17.0609 36.8645 17.2837 36.9357 17.5236C37.007 17.7635 37.0192 18.0145 36.9714 18.2591Z"
              fill="#282F75"
              fill-opacity="0.8"
            />
            <path
              d="M13.7823 16.2663H5.77282C5.66007 16.2661 5.54872 16.2437 5.44656 16.2006C5.3444 16.1574 5.25389 16.0946 5.18137 16.0166C5.10885 15.9385 5.05606 15.8471 5.0267 15.7487C4.99734 15.6503 4.99212 15.5472 5.0114 15.4468L5.76557 11.5786C5.79709 11.4166 5.89067 11.2699 6.0299 11.1641C6.16913 11.0583 6.34512 11.0002 6.527 11H13.8874C14.0003 10.9999 14.1118 11.0221 14.2141 11.0652C14.3164 11.1082 14.4071 11.171 14.4797 11.2491C14.5523 11.3273 14.605 11.4188 14.6343 11.5174C14.6635 11.6159 14.6685 11.7191 14.6489 11.8195L13.7823 16.2663Z"
              fill="#282F75"
              fill-opacity="0.8"
            />
            <path
              d="M35.3579 26.5262C35.3571 26.5361 35.3571 26.5459 35.3579 26.5557L35.144 27.6408C35.0691 28.0358 34.8419 28.394 34.5028 28.652C34.1636 28.91 33.7344 29.0511 33.2912 29.0504H32.9286C32.9196 29.0422 32.9141 29.0324 32.9069 29.0258C32.5529 28.6489 32.1114 28.3472 31.6138 28.1421C31.1163 27.937 30.5748 27.8336 30.028 27.8391C28.8508 27.8546 27.7281 28.29 26.9043 29.0504H19.4097C19.4006 29.0422 19.3952 29.0324 19.3861 29.0258C19.0324 28.6492 18.5912 28.3476 18.0939 28.1425C17.5967 27.9374 17.0556 27.8338 16.509 27.8391C15.3359 27.8549 14.2166 28.287 13.3926 29.0422C13.1304 29.0258 12.875 28.9598 12.6429 28.8484C12.4107 28.7369 12.2071 28.5826 12.0452 28.3955C11.8833 28.2083 11.7668 27.9924 11.7031 27.7619C11.6395 27.5314 11.6302 27.2913 11.6758 27.0573L11.8897 25.9722C11.9664 25.5799 12.1933 25.2245 12.5307 24.9682C12.8682 24.712 13.2946 24.5713 13.7353 24.5708H15.9543C16.2595 24.5703 16.5601 24.5027 16.8301 24.3739C17.1001 24.2452 17.3314 24.0591 17.5043 23.8316L18.8495 22.0516C19.0214 21.8232 19.2525 21.6364 19.5228 21.5076C19.793 21.3787 20.094 21.3117 20.3996 21.3124H24.979C25.2319 21.3122 25.4823 21.3582 25.7151 21.4478C25.9478 21.5374 26.1581 21.6686 26.3332 21.8336L28.69 24.0496C28.8652 24.2145 29.0755 24.3457 29.3083 24.4353C29.541 24.5249 29.7913 24.571 30.0443 24.5708H33.5033C33.7739 24.5711 34.0412 24.6242 34.2869 24.7265C34.5327 24.8288 34.7511 24.9779 34.9272 25.1636C35.1033 25.3492 35.233 25.5671 35.3073 25.8023C35.3817 26.0375 35.3989 26.2845 35.3579 26.5262Z"
              fill="#282F75"
            />
            <path
              d="M18.8295 31.5856C18.5684 32.9198 17.1634 33.9999 15.6877 33.9999C14.212 33.9999 13.2294 32.9198 13.4886 31.5856C13.7479 30.2514 15.1565 29.1713 16.6304 29.1713C18.1043 29.1713 19.0869 30.2547 18.8295 31.5856Z"
              fill="white"
            />
            <path
              d="M32.1855 31.5856C31.9281 32.9198 30.5194 33.9999 29.0473 33.9999C27.5753 33.9999 26.5963 32.9198 26.8446 31.5856C27.093 30.2514 28.5125 29.1713 29.9864 29.1713C31.4603 29.1713 32.4466 30.2547 32.1855 31.5856Z"
              fill="white"
            />
            <path
              d="M18.8295 31.5856C18.5684 32.9198 17.1634 33.9999 15.6877 33.9999C14.212 33.9999 13.2294 32.9198 13.4886 31.5856C13.7479 30.2514 15.1565 29.1713 16.6304 29.1713C18.1043 29.1713 19.0869 30.2547 18.8295 31.5856Z"
              fill="#282F75"
              fill-opacity="0.8"
            />
            <path
              d="M32.1855 31.5856C31.9281 32.9198 30.5194 33.9999 29.0473 33.9999C27.5753 33.9999 26.5963 32.9198 26.8446 31.5856C27.093 30.2514 28.5125 29.1713 29.9864 29.1713C31.4603 29.1713 32.4466 30.2547 32.1855 31.5856Z"
              fill="#282F75"
              fill-opacity="0.8"
            />
            <path
              d="M43.6044 10.5992L41.9139 14.9377C41.7669 15.315 41.2331 15.315 41.0861 14.9377L39.3956 10.5992C39.2429 10.2073 38.9142 9.91049 38.5088 9.79848L33.5496 8.42819C33.1151 8.30811 33.1151 7.69189 33.5496 7.57182L38.5088 6.20153C38.9142 6.08951 39.2429 5.7927 39.3956 5.40082L41.0861 1.06229C41.2331 0.685024 41.7669 0.685024 41.9139 1.06229L43.6044 5.40082C43.7571 5.7927 44.0858 6.08951 44.4912 6.20153L49.4504 7.57182C49.8849 7.69189 49.8849 8.30811 49.4504 8.42819L44.4912 9.79848C44.0858 9.91049 43.7571 10.2073 43.6044 10.5992Z"
              fill="#282F75"
            />
          </svg>
        </span>
        <p className="text-black font-bold text-md select-none">
          Discuter avec Steer Chat
        </p>

        <button
          onClick={createChat}
          className="w-[85%] mt-5 bg-blue1 hover:bg-lightblue1 text-white0 w-[100px] flex items-center flex-row gap-2 justify-center px-2 py-1 rounded-lg"
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75 9H16.25"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 15.75V2.25"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          New Query
        </button>
      </div>
      <div className="h-[2px] bg-gray-300 my-4"></div>
      <div className="flex flex-col gap-2 overflow-auto pb-4 mb-1">
        {Object.keys(defaultQueries).map((label, key) => (
          <div className="mt-1" key={key}>
            <div className="justify-between flex mb-2">
              <p className="text-black font-bold text-md select-none">
                {label.toUpperCase()}
              </p>
              <div className="flex gap-2">
                <p>{defaultQueries[label].length}</p>
                <span onClick={toggleAccordion(label)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      openedAccordions.includes(label)
                        ? "rotate-180"
                        : "rotate-0"
                    }
                  >
                    <path
                      d="M20.2959 9.79586L12.7959 17.2959C12.6914 17.4007 12.5672 17.484 12.4304 17.5407C12.2937 17.5975 12.1471 17.6267 11.999 17.6267C11.851 17.6267 11.7043 17.5975 11.5676 17.5407C11.4309 17.484 11.3067 17.4007 11.2021 17.2959L3.70215 9.79586C3.4908 9.58451 3.37207 9.29787 3.37207 8.99898C3.37207 8.7001 3.4908 8.41345 3.70215 8.20211C3.91349 7.99076 4.20014 7.87203 4.49902 7.87203C4.79791 7.87203 5.08455 7.99076 5.2959 8.20211L12 14.9062L18.704 8.20117C18.9154 7.98983 19.202 7.87109 19.5009 7.87109C19.7998 7.87109 20.0864 7.98983 20.2978 8.20117C20.5091 8.41252 20.6278 8.69916 20.6278 8.99805C20.6278 9.29693 20.5091 9.58358 20.2978 9.79492L20.2959 9.79586Z"
                      fill="#1E293B"
                    />
                  </svg>
                </span>
              </div>
            </div>
            {openedAccordions.includes(label) && (
              <div className="flex flex-col">
                {defaultQueries[label].map((query) => (
                  <div
                    className={`flex justify-between items-center py-2 pr-1/2 cursor-pointer hover:bg-[#E9F0FF]  ${
                      isCurrentQuery(query.id) && "border-r-4 border-blue1"
                    }`}
                    key={query.id}
                    onClick={continueChat(query.id)}
                  >
                    <p className="select-none pl-1">{query.title}</p>
                  </div>
                ))}
                {key !== Object.keys(defaultQueries).length - 1 && (
                  <div className="h-[1px] bg-gray-300 my-4"></div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Queries;
