import React from "react";
import AgentIaLayout from "../../Layout/AgentAi/AgentIaLayout";
import AgentAi from "../../Components/AgentAi/Chat/AgentAi";

const ContinueChat = () => {
  return (
    <AgentIaLayout title={"Bonjour Michael!"}>
      <AgentAi isContinue />
    </AgentIaLayout>
  );
};

export default ContinueChat;
