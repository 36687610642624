import React from "react";

const PreviewIcon = ({ active, color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    style={{ opacity: active ? 1 : 0.5 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_37_18844)">
      <path
        d="M13 19.0001C11.3599 19.0205 9.7367 18.6665 8.254 17.9651C7.10469 17.4043 6.07265 16.6299 5.213 15.6831C4.30243 14.7042 3.58547 13.5618 3.1 12.3161L3 12.0001L3.105 11.6841C3.59082 10.4395 4.30624 9.29737 5.214 8.3171C6.07334 7.37041 7.10504 6.59596 8.254 6.0351C9.73671 5.33369 11.3599 4.97971 13 5.0001C14.6401 4.97975 16.2633 5.33372 17.746 6.0351C18.8953 6.59584 19.9274 7.37029 20.787 8.3171C21.6993 9.29466 22.4165 10.4375 22.9 11.6841L23 12.0001L22.895 12.3161C21.3262 16.3999 17.3742 19.0694 13 19.0001ZM13 7.0001C9.59587 6.89343 6.47142 8.8752 5.117 12.0001C6.4712 15.1252 9.59579 17.1071 13 17.0001C16.4041 17.1065 19.5284 15.1248 20.883 12.0001C19.5304 8.87368 16.4047 6.89118 13 7.0001ZM13 15.0001C11.5573 15.0097 10.3094 13.9975 10.021 12.5839C9.73256 11.1703 10.4843 9.75012 11.8154 9.19376C13.1465 8.6374 14.6852 9.10024 15.4885 10.2986C16.2919 11.497 16.1354 13.0962 15.115 14.1161C14.5563 14.6814 13.7948 14.9997 13 15.0001Z"
        fill={color ?? "#282F75"}
      />
    </g>
    <defs>
      <clipPath id="clip0_37_18844">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default PreviewIcon;
