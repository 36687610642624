import React, { useEffect, useState } from "react";
import SlashCommandInput from "./SlashCommandInput";
import MessageList from "./MessageList";
import Queries from "./Queries";
import { useParams } from "react-router";

//TODO change to get messages from WS
const defaultMessages = [
  [
    {
      type: "simple",
      time: "15:00",
      message: `Hello i'm Micheal`,
      me: true,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
    {
      type: "html",
      time: "15:01",
      message: `<p>hello <strong>here iam </strong> </p> <br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd`,
      me: false,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
    {
      type: "html",
      time: "15:01",
      message: `<p>hello <strong>here iam </strong> </p> <br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd`,
      me: false,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
    {
      type: "html",
      time: "15:01",
      message: `<p>hello <strong>here iam </strong> </p> <br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd`,
      me: false,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
  ],
  [
    {
      type: "simple",
      time: "15:00",
      message: `Hello i'm Micheal`,
      me: true,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
    {
      type: "html",
      time: "15:01",
      message: `<p>hello <strong>here iam </strong> </p> <br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd<br>qsd`,
      me: false,
      files: [
        {
          id: 1,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
  ],
  [
    {
      type: "simple",
      time: "16:00",
      message: `Hey, what's up?`,
      me: true,
      files: [],
    },
    {
      type: "html",
      time: "16:05",
      message: `<p>This is a new message with <strong>HTML</strong> tags.</p>`,
      me: false,
      files: [
        {
          id: 2,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
  ],
  [
    {
      type: "simple",
      time: "17:00",
      message: `How's your day going?`,
      me: false,
      files: [],
    },
    {
      type: "html",
      time: "17:15",
      message: `<p>I'm doing great! <strong>Thanks for asking!</strong></p>`,
      me: true,
      files: [],
    },
  ],
  [
    {
      type: "simple",
      time: "18:00",
      message: `Good evening!`,
      me: true,
      files: [
        {
          id: 3,
          source:
            "https://cdn.futura-sciences.com/cdn-cgi/image/width=1024,quality=50,format=auto/sources/images/AI-creation.jpg",
        },
      ],
    },
    {
      type: "html",
      time: "18:30",
      message: `<p>Here's a message with <strong>HTML</strong> content and some <em>italic</em> text.</p>`,
      me: false,
      files: [],
    },
  ],
];

function AgentAi({ isContinue }) {
  const { id: urlId } = useParams();
  const id = isContinue ? urlId : null;
  const [messages, setMessages] = useState([]);
  const [showSideQueries, setShowSideQueries] = useState(false);

  useEffect(() => {
    setMessages(
      isContinue && id
        ? defaultMessages[
            id > defaultMessages.length ? defaultMessages.length - 1 : id
          ]
        : []
    );
  }, [isContinue, id]);

  const appendMessage = (message) => {
    //TODO add condition on first message create query + add query to the list + pass query id to new message request object
    setMessages([...messages, message]);
  };
  const closeQueries = () => {
    setShowSideQueries(false);
  };
  return (
    <div className="w-full h-full justify-start flex">
      {showSideQueries ? (
        <Queries closeQueries={closeQueries} />
      ) : (
        <span
          className="absolute p-2 rounded-full bg-white0 z-8 border-2 border-[#CBD5E1] absolute"
          onClick={() => setShowSideQueries(true)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="-rotate-180"
          >
            <path
              d="M4.56776 4.57307C4.73058 4.735 4.81199 4.92793 4.81198 5.15186C4.81198 5.37577 4.73058 5.56624 4.56776 5.72327L3.10241 7.18062L9.33734 7.18062C9.56736 7.18062 9.76119 7.25936 9.91885 7.41684C10.0765 7.57433 10.1553 7.76796 10.1553 7.99773C10.1553 8.22752 10.0765 8.41996 9.91885 8.57504C9.76119 8.73012 9.56736 8.80766 9.33734 8.80766L3.14372 8.80766L4.64138 10.2972C4.79043 10.4387 4.86301 10.6196 4.85913 10.8398C4.85524 11.06 4.77041 11.2515 4.60464 11.4141C4.44944 11.5768 4.25544 11.6562 4.02264 11.6523C3.78985 11.6484 3.59204 11.5655 3.42922 11.4036L0.554167 8.54423C0.470474 8.45899 0.408843 8.36758 0.369274 8.26999C0.32972 8.17239 0.309942 8.07225 0.309942 7.96957C0.309942 7.86688 0.32972 7.76795 0.369274 7.67279C0.408843 7.57762 0.470474 7.48768 0.554168 7.40297L3.42024 4.55254C3.56929 4.4043 3.75515 4.33018 3.97783 4.33018C4.20051 4.33018 4.39715 4.41115 4.56776 4.57307ZM14.0423 0.309941C14.493 0.309941 14.8803 0.469842 15.2042 0.789644C15.528 1.10943 15.6899 1.49188 15.6899 1.93698L15.6899 14.0513C15.6899 14.4996 15.528 14.8848 15.2042 15.2068C14.8803 15.5289 14.493 15.6899 14.0423 15.6899L8.76899 15.6899C8.53898 15.6899 8.34514 15.6089 8.18749 15.4469C8.02983 15.2849 7.95101 15.0893 7.95101 14.8602C7.95101 14.6311 8.02983 14.439 8.18749 14.2839C8.34514 14.1288 8.53898 14.0513 8.76899 14.0513L14.0423 14.0513L14.0423 1.93698L8.76899 1.93698C8.53898 1.93698 8.34514 1.85824 8.18749 1.70077C8.02984 1.54327 7.95101 1.34964 7.95101 1.11987C7.95101 0.890079 8.02984 0.697645 8.18749 0.542563C8.34514 0.387481 8.53898 0.309941 8.76899 0.309941L14.0423 0.309941Z"
              fill="#1B2559"
            />
          </svg>
        </span>
      )}

      <div className="flex w-full flex-col justify-between h-full justify-between flex-shrink flex-1">
        <MessageList messages={messages} />
        <SlashCommandInput appendMessage={appendMessage} />
      </div>
    </div>
  );
}

export default AgentAi;
