import React, { useEffect, useRef } from "react";
import Message from "./Message";

function MessageList({ messages }) {
  const listRef = useRef(null);
  useEffect(() => {
    if (listRef.current)
      listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [messages]);
  return (
    <div
      ref={listRef}
      className="w-full overflow-auto flex flex-col gap-3 flex-1 pb-10 px-1"
    >
      {messages.map(({ type, ...message }, index) => (
        <div key={index}>
          <Message message={message} type={type} />
        </div>
      ))}
    </div>
  );
}

export default MessageList;
