import React, { memo, useState } from "react";
import { Handle, Position } from "@xyflow/react";
import { ReactComponent as MoreIcon } from "../../../Assets/agent-ai/more.svg";
const PopoverMenu = ({ items }) => {
  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border border-gray-200 z-10 mt-[100px]">
      {items.map((item, index) => (
        <div
          key={index}
          className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
          onClick={item.onClick}
        >
          {item.icon && <span className="mr-2">{item.icon}</span>}
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
function SimpleNode({ data }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [disabledTopHandler, setDisabledTopHandler] = useState(false);

  const handleIconClick = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const handleShowTopHandler = () => {
    setDisabledTopHandler((prev) => !prev);
    setIsPopoverOpen(false);
  };
  const menuItems = [
    {
      title: "Delete",
      icon: <i className="fas fa-trash" />,
      onClick: data.deleteHandler,
    },
    {
      title: disabledTopHandler ? "Enable top handler" : "Disable top handler",
      icon: <i className="fas fa-trash" />,
      onClick: handleShowTopHandler,
    },
  ];

  return (
    <div className="px-3 py-3 shadow-md rounded-md bg-white border-2 border-dashed border-grey3 hover:border-blue1 w-[320px] relative">
      <div className="flex flex-row gap-1  items-center">
        <div>
          {data.icon && <data.icon />}
          {data.img && <img src={data.img} className="w-[60px] h-[48px]" />}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-center justify-between">
            <p className="text-black text-md font-bold">{data.name}</p>
            <MoreIcon className="cursor-pointer" onClick={handleIconClick} />
            {isPopoverOpen && <PopoverMenu items={menuItems} />}
          </div>
          <p className="text-grey1 ">{data.annotation}</p>
        </div>
      </div>
      {!disabledTopHandler && (
        <Handle
          type="target"
          id="wissem2"
          position={Position.Top}
          className="w-16 !bg-teal-500"
        />
      )}
      <Handle
        type="source"
        id="wissem1"
        position={Position.Bottom}
        className="w-16 !bg-teal-500"
      />
    </div>
  );
}

export default memo(SimpleNode);
