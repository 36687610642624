import React from "react";

const CarAnalysisIcon = ({ active, color }) => (

  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ opacity: active ? 1 : 0.5 }}
  >
    <path
      d="M6.74554 15.1882L7.18018 13.9175H10.3675L10.8022 15.1882M10.3675 16.6001C10.2523 16.6001 10.1417 16.5554 10.0602 16.476C9.97869 16.3966 9.9329 16.2888 9.9329 16.1765C9.9329 16.0642 9.97869 15.9564 10.0602 15.877C10.1417 15.7976 10.2523 15.7529 10.3675 15.7529C10.4828 15.7529 10.5934 15.7976 10.6749 15.877C10.7564 15.9564 10.8022 16.0642 10.8022 16.1765C10.8022 16.2888 10.7564 16.3966 10.6749 16.476C10.5934 16.5554 10.4828 16.6001 10.3675 16.6001ZM7.18018 16.6001C7.0649 16.6001 6.95435 16.5554 6.87284 16.476C6.79133 16.3966 6.74554 16.2888 6.74554 16.1765C6.74554 16.0642 6.79133 15.9564 6.87284 15.877C6.95435 15.7976 7.0649 15.7529 7.18018 15.7529C7.29545 15.7529 7.406 15.7976 7.48751 15.877C7.56903 15.9564 7.61482 16.0642 7.61482 16.1765C7.61482 16.2888 7.56903 16.3966 7.48751 16.476C7.406 16.5554 7.29545 16.6001 7.18018 16.6001ZM10.779 13.7763C10.721 13.6125 10.5588 13.4939 10.3675 13.4939H7.18018C6.98893 13.4939 6.82667 13.6125 6.76872 13.7763L6.16602 15.4706V17.7296C6.16602 17.8045 6.19654 17.8763 6.25088 17.9293C6.30523 17.9822 6.37893 18.012 6.45578 18.012H6.74554C6.82239 18.012 6.89609 17.9822 6.95043 17.9293C7.00477 17.8763 7.0353 17.8045 7.0353 17.7296V17.4472H10.5124V17.7296C10.5124 17.8045 10.5429 17.8763 10.5973 17.9293C10.6516 17.9822 10.7253 18.012 10.8022 18.012H11.0919C11.1688 18.012 11.2425 17.9822 11.2968 17.9293C11.3512 17.8763 11.3817 17.8045 11.3817 17.7296V15.4706L10.779 13.7763Z"

      fill={color ?? "#282F75"}
    />
    <path
      d="M4.52581 19.4997V19.4995L4.51346 19.4998C4.38965 19.5029 4.2602 19.4703 4.13263 19.3944C4.00372 19.3178 3.8775 19.1969 3.77059 19.0302C3.66367 18.8635 3.58219 18.6602 3.53767 18.4338C3.4932 18.2075 3.48796 17.9697 3.5227 17.7396L3.52275 17.7396L3.52372 17.7325L5.02122 6.75043L5.02141 6.75046L5.02291 6.73667C5.06371 6.35957 5.20538 6.03456 5.39898 5.81336C5.5896 5.59555 5.80603 5.50267 6.0009 5.50035H20.4742V5.5005L20.4865 5.5002C20.6103 5.49714 20.7398 5.5297 20.8674 5.60557C20.9963 5.68224 21.1225 5.80311 21.2294 5.96979C21.3363 6.13647 21.4178 6.33978 21.4623 6.56624C21.5068 6.79251 21.512 7.03029 21.4773 7.26039L21.4772 7.26038L21.4762 7.26823L19.8274 19.4997H4.52581Z"
      stroke={color ?? "#282F75"}
    />
    <path
      d="M17.9995 7.34937H10.6367V9.49236H17.9995V7.34937Z"
      stroke={color ?? "#282F75"}
      stroke-linejoin="round"
    />
    <path
      d="M10.6367 13.7833L12.8779 11.5868L14.0889 12.7119L15.5694 11.2778L16.394 12.0579"
      stroke={color ?? "#282F75"}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.9995 9.34433V13.9875M10.6367 9.34433V11.8445M12.2961 14.1353H17.9995M13.0296 8.4207H16.895M11.7411 8.42017H11.9252"
      stroke={color ?? "#282F75"}
      stroke-linecap="round"
    />
  </svg>
);
export default CarAnalysisIcon;
