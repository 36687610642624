import * as type from "./types";
const initialState = {
  error: "",
  loading: false,
  isLoggedIn: localStorage.getItem("token") ? true : false,
};

const authenticate = (state = initialState, action) => {
  switch (action.type) {
    case type.IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case type.LOGIN_SUCCESS:
      state = {
        ...state,
        isLoggedIn: true,
      };
      break;

    case type.LOGIN_FAILURE:
      state = {
        ...state,
        error: action.error,
      };
      break;
    case type.LOGOUT:
      state = { ...state, isLoggedIn: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authenticate;
