import React from "react";

function HeaderCampaignStepper({ activeStep, marginBottom }) {
  return (
    <div
      className={`flex flex-col justify-center items-center gap-2 ${
        marginBottom ? "mb-".marginBottom : "mb-4"
      }`}
    >
      <h1 className="font-bold text-xxl">
        {activeStep < 5
          ? "Configurer votre compagne de Marketing"
          : "Compagne crée avec succès"}
      </h1>
      <p className="text-center w-[70%] xl:w-[50%] text-textgrey1 text-sm">
        {activeStep < 5
          ? "Cette configuration est nécessaire et sera appliquée lors de l’activation du sponsorship de votre campagne"
          : "Vous pouvez aller dans Analytiques pour suivre votre campagne, la mettre en pause ou l’arrêter"}
      </p>
    </div>
  );
}

export default HeaderCampaignStepper;
