import React, {useEffect, useState} from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { RiseLoader } from "react-spinners";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Thumbs, FreeMode, Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import { getAnalyzedCarImages } from "../../../Redux/cars/actions";
import { DamagedImageSamples } from "../../../Constants/cars";
import "./index.css";

const DamagedCarsDisplay = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const analysedImages = useSelector((state) => state).cars.analysedCarImages;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    dispatch(getAnalyzedCarImages(param.id));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-center flex-wrap flex-grow">
      {!analysedImages ? (
        <div className="self-center inline-block">
          <div className="mt-2 flex items-center justify-center">
            <div className="block bg-indigo-600 text-indigo-100  py-2 rounded text-sm uppercase tracking-wide font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 200 200"
                fill="none"
                color="#282F75"
              >
                <defs>
                  <linearGradient id="spinner-secondHalf">
                    <stop
                      offset="0%"
                      stop-opacity="0"
                      stop-color="currentColor"
                    />
                    <stop
                      offset="100%"
                      stop-opacity="0.5"
                      stop-color="currentColor"
                    />
                  </linearGradient>
                  <linearGradient id="spinner-firstHalf">
                    <stop
                      offset="0%"
                      stop-opacity="1"
                      stop-color="currentColor"
                    />
                    <stop
                      offset="100%"
                      stop-opacity="0.5"
                      stop-color="currentColor"
                    />
                  </linearGradient>
                </defs>
                <g stroke-width="8">
                  <path
                    stroke="url(#spinner-secondHalf)"
                    d="M 4 100 A 96 96 0 0 1 196 100"
                  />
                  <path
                    stroke="url(#spinner-firstHalf)"
                    d="M 196 100 A 96 96 0 0 1 4 100"
                  />

                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    d="M 4 100 A 96 96 0 0 1 4 98"
                  />
                </g>
                <animateTransform
                  from="0 0 0"
                  to="360 0 0"
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1300ms"
                />
              </svg>
            </div>
            <p className="block bg-gray-300 text-[#282F75] px-4 rounded text-sm  font-normal  text-[18px]">
              Loading
            </p>
          </div>
          <p className=" text-[#858585] text-[18px]">
            Scanning Photos to detect damage
          </p>
        </div>
      ) : (
        <>
          {analysedImages.length ?
            <>
              <Swiper
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[FreeMode, Navigation, Thumbs, Pagination]}
                className="relative flex flex-col justify-center items-center damage-carousel-swiper"
                slidesPerView={1}
              >
                {analysedImages.map((imageData, index) => (
                  <SwiperSlide key={`analysed-image-slide-${index}`}>
                    <div className="relative flex flex-col justify-center items-center">
                      <img
                        className="mx-auto w-full object-contain block md:max-h-[500px]"
                        src={imageData.image_s3_link}
                        alt={``}
                      />
                      <p className="text-center z-10 absolute top-4 bg-black1 text-white0 px-4 py-2 bg-opacity-70 -translate-x-1/2 left-1/2 rounded">
                        {imageData.nb_damages} dégat(s) detecté
                      </p>
                      <div className="absolute bg-white0 p-2 right-10 bottom-8 rounded-md border border-grey1">
                        <img
                          src={DamagedImageSamples.find((part) => part.namingRule === imageData.filename)?.image}
                          alt="damage-parts"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="relative flex flex-col justify-center items-center mt-4 damage-carousel-swiper-bottom"
                slidesPerView={isMobile ? 4 : 8}
                spaceBetween={10}
              >
                {analysedImages.map((imageData, index) => (
                  <SwiperSlide key={index}>
                    <div className="relative flex flex-col justify-center items-center">
                      <img
                        alt="s"
                        className={"mx-auto w-full object-contain block md:max-h-[500px]"}
                        src={imageData.image_s3_link}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
            :
            <div className="flex items-center">
              <RiseLoader />
            </div>
          }
        </>
      )}
    </div>
  );
};
export default DamagedCarsDisplay;
