import React from "react";
import CreatedIcon from "../icons/CreatedIcon";

function CreatedCampagneModal({ isOpen, onContinue }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[550px] flex flex-col justify-center items-center">
        <h2 className="text-black font-bold text-xl mb-4 text-center">
          {`Création et Pulication de la Compagne 1`}
        </h2>
        <div className="px-10 mb-5 flex flex-col items-center gap-3">
          <CreatedIcon height={70} />
          <span className="text-sm">
            {`Votre compagne de marketing a été crée avec succès `}
          </span>
        </div>
        <div className="flex flex-row-reverse gap-4 w-full">
          <button
            className="px-4 py-1 bg-lightblue2 font-bold text-white rounded-lg"
            onClick={onContinue}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreatedCampagneModal;
