import React from "react";
import AskIcon from "../icons/AskIcon";

function ValidateParametersModal({ isOpen, onClose, onContinue, parameters }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[550px] flex flex-col justify-center items-center">
        <h2 className="text-black font-bold text-xl mb-4 text-center">
          {`Etes-vous sûr de continuer avec la publication de ${parameters.configName} ?`}
        </h2>
        <div className="px-10 mb-5 flex flex-col items-center gap-4">
          <AskIcon height={60} />
          {/* TODO:: get values from global states during stepper */}
          <span className="text-sm">
            {`${parameters.estimatedAmount}€ par jours durant ${parameters.days} jours à partir de ${parameters.startDate}`}
          </span>
        </div>
        <div className="flex flex-row-reverse gap-4 w-full">
          <button
            className="px-4 py-1 bg-lightblue2 font-bold text-white rounded-lg"
            onClick={onContinue}
          >
            Oui
          </button>
          <button
            className="px-4 py-1 text-back font-bold bg-transparent border border-grey2 rounded-lg"
            onClick={onClose}
          >
            Revenir
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValidateParametersModal;
