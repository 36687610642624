import * as React from "react";

export default function TimeSheet({ steps: steps, active: activeStep }) {
  return (
    <div className="w-full h-full max-w-md mx-auto mt-2 xl:mt-10 p-2">
      <div className="relative">
        {steps.map((step, index) => (
          <div key={index} className="flex items-start mb-6 relative">
            {/* Vertical line */}
            {index < steps.length - 1 && (
              <div className="absolute left-4 top-5 -z-10 h-full border-l-2 border-[#4368B1] mt-1 mb-4" />
            )}
            {/* Step Circle */}
            <div
              onClick={step.onStepClick}
              className={`flex items-center justify-center rounded-full cursor-pointer ${
                index === activeStep
                  ? "bg-active text-white w-12 h-12 -ml-2"
                  : "bg-darkblue1 text-black w-8 h-8"
              }`}
            >
              {step.icon && (
                <step.icon color={"white"} active height={18} width={20} />
              )}
            </div>
            <div className="ml-5 flex-1">
              <div
                className={`text-lg ${
                  index <= activeStep
                    ? "font-bold text-active1"
                    : "text-inactive1"
                }`}
              >
                {step.label}
              </div>
              <small className={`mt-1 text-tiny text-inactive1-400`}>
                {step.description}
              </small>
              <div className="mt-2 flex space-x-2">
                {step.withButton && index === activeStep && (
                  <button
                    onClick={step.onButtonClick}
                    className="px-2 py-1 bg-lightblue2 text-white rounded text-sm"
                  >
                    {step.buttonText}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
