import React from "react";
import NeedHelp from "../../needHelp/NeedHelp";
import CarPlateNumber from "../../carPlate/CarPlateNumber";
import Road from "../../previewCar/svg/Road";
import Seat from "../../previewCar/svg/Seat";
import Gear from "../../previewCar/svg/Gear";
import Fuel from "../../previewCar/svg/Fuel";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};
function ViewVehicle({ landing, chosenCar }) {
  const options = [
    {
      icon: <Road />,
      name: `${chosenCar.km} km`,
    },
    {
      icon: <Seat />,
      name: `${chosenCar.seat} Seats`,
    },
    {
      icon: <Gear />,
      name: chosenCar.gearBox,
    },
    {
      icon: <Fuel />,
      name: chosenCar.fuel,
    },
  ];
  const specifications = [
    {
      name: "Year",
      parameter: chosenCar.overviewYear,
    },
    {
      name: "Extérieur",
      parameter: chosenCar.outside,
    },
    {
      name: "Intérieur",
      parameter: chosenCar.inside,
    },
    {
      name: "Nombre de CV",
      parameter: `${chosenCar.numberCV} CV`,
    },
  ];

  return (
    <div className="rounded bg-white border ">
      <NeedHelp />
      {landing ? (
        <div className="flex gap-2 pt-2 px-5 py-5 items-center">
          <div className="h-6 w-6 rounded-full  bg-[#686868]"></div>
          <span>Sélectionner une étape pour avoir un visuel</span>
        </div>
      ) : (
        <div className="flex flex-col p-6">
          <div className="flex gap-2 items-center">
            <div className="rounded-full bg-active h-8 w-8"></div>
            <h1 className="font-bold">Vehicule ajouté</h1>
          </div>
          <div className="flex flex-col xxl:flex-row justify-between items-center px-10 m-2">
            <div className="flex flex-col justify-center items-center gap-2 m-2">
              <h1 className="font-bold text-xxl">{`${chosenCar.make} ${chosenCar.model}`}</h1>
              <h1 className="font-bold text-darkblue1 text-big">
                {chosenCar.price} €
              </h1>
              <div className="flex justify-between items-center gap-2">
                <div className="bg-active rounded-xl text-white px-2 py-1 text-sm">
                  Inspecté
                </div>
                <div className="bg-warning1 rounded-xl text-white px-2 py-1 text-sm">
                  Non publié
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2 rounded-l w-[350px] shadow-xl py-2">
              <CarPlateNumber
                plateNumber={chosenCar.immatricule}
                countryCode={"F"}
              />
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <h1 className="text-end w-[100px] text-gray5">
                    Ajouté par :
                  </h1>
                  <h1 className="font-bold">{chosenCar.userId}</h1>
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-end w-[100px] text-gray5">Agence : </h1>
                  <h1 className="font-bold">{chosenCar.location}</h1>
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-end w-[100px] text-gray5">Horodaté : </h1>
                  <h1 className="font-bold">
                    {formatDate(chosenCar.createdAt)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-2">
            <img src="/preview-example/image1.png" alt="car" />
          </div>
          <div className="flex flex-col xl:flex-row justify-between gap-10 ">
            <div className="bg-white0 w-full h-full mx-auto xl:w-[250px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
              <h3 className="text-lightblue2 font-bold mb-5">Overview</h3>
              <div className="flex flex-col gap-4 ">
                {options.map(({ icon, name }, index) => (
                  <div key={index} className="flex gap-4 items-center ">
                    {icon}
                    <span>{name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="max-h-full h-fit xl:flex bg-white0 lg:rounded-md min-w-0 flex-grow sm:drop-shadow-lg rounded-md md:border md:border-grey2 flex-col p-3">
              <h3 className="text-lightblue2 font-bold mb-5">Specifications</h3>
              <div className="flex flex-col gap-4 w-full xl:w-[400px]">
                {specifications.map(({ name, parameter }, index) => (
                  <div key={index} className="flex justify-between">
                    <span className="text-lightblue2">{name}</span>
                    <span>{parameter}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewVehicle;
