import React from "react";

function MessengerIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6748 5.04692C14.0035 4.87951 11.363 5.69289 9.24898 7.33436C7.13494 8.97583 5.69275 11.3325 5.19315 13.9619C4.69355 16.5914 5.1709 19.3128 6.53559 21.6152C6.67534 21.851 6.71218 22.1338 6.63752 22.3975L5.46631 26.5341L9.60292 25.3629L9.87534 26.3251L10.3852 25.4649C12.6877 26.8295 15.409 27.3069 18.0385 26.8073C20.6679 26.3077 23.0246 24.8655 24.6661 22.7515C26.3075 20.6374 27.1209 17.9969 26.9535 15.3257C26.7861 12.6544 25.6494 10.1361 23.7569 8.24357C21.8643 6.35101 19.346 5.21433 16.6748 5.04692ZM9.74445 27.4015C12.383 28.8479 15.4468 29.3355 18.4118 28.7721C21.5199 28.1816 24.3055 26.4769 26.2458 23.978C28.186 21.4792 29.1475 18.3581 28.9496 15.2006C28.7517 12.0431 27.4081 9.06641 25.1711 6.82936C22.934 4.5923 19.9573 3.24871 16.7999 3.05084C13.6424 2.85296 10.5212 3.81439 8.0224 5.75465C5.52355 7.69491 3.81884 10.4805 3.2283 13.5886C2.66495 16.5536 3.15249 19.6175 4.59897 22.256L3.53913 25.9993C3.4404 26.34 3.43469 26.7011 3.52263 27.0448C3.61099 27.3903 3.79064 27.7056 4.04276 27.9577C4.29488 28.2098 4.61017 28.3894 4.95559 28.4778C5.29939 28.5657 5.66044 28.56 6.00123 28.4613L9.74445 27.4015Z"
        fill="#282F75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L18 16.5858L21.2929 13.2929C21.6834 12.9024 22.3166 12.9024 22.7071 13.2929C23.0976 13.6834 23.0976 14.3166 22.7071 14.7071L18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L14 15.4142L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L13.2929 13.2929Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default MessengerIcon;
