import ad_dd00_es01_is00 from "./../Assets/car-images/ad_dd00_es14_is00.png";
import ad_dd00_es02_is00 from "./../Assets/car-images/ad_dd00_es13_is00.png";
import ad_dd00_es03_is00 from "./../Assets/car-images/ad_dd00_es36_is00.png";
import ad_dd00_es04_is00 from "./../Assets/car-images/ad_dd00_es12_is00.png";
import ad_dd00_es05_is00 from "./../Assets/car-images/ad_dd00_es11_is00.png";
import ad_dd00_es06_is00 from "./../Assets/car-images/ad_dd00_es37_is00.png";
import ad_dd00_es07_is00 from "./../Assets/car-images/ad_dd00_es10_is00.png";
import ad_dd00_es08_is00 from "./../Assets/car-images/ad_dd00_es09_is00.png";
import ad_dd00_es09_is00 from "./../Assets/car-images/ad_dd00_es08_is00.png";
import ad_dd00_es10_is00 from "./../Assets/car-images/ad_dd00_es07_is00.png";
import ad_dd00_es11_is00 from "./../Assets/car-images/ad_dd00_es06_is00.png";
import ad_dd00_es12_is00 from "./../Assets/car-images/ad_dd00_es05_is00.png";
import ad_dd00_es13_is00 from "./../Assets/car-images/ad_dd00_es04_is00.png";
import ad_dd00_es14_is00 from "./../Assets/car-images/ad_dd00_es03_is00.png";
import ad_dd00_es15_is00 from "./../Assets/car-images/ad_dd00_es02_is00.png";
import ad_dd00_es16_is00 from "./../Assets/car-images/ad_dd00_es01_is00.png";
import ad_dd00_es17_is00 from "./../Assets/car-images/ad_dd00_es19_is00.png";
import ad_dd00_es18_is00 from "./../Assets/car-images/ad_dd00_es26_is00.png";
import ad_dd00_es19_is00 from "./../Assets/car-images/ad_dd00_es25_is00.png";
import ad_dd00_es20_is00 from "./../Assets/car-images/ad_dd00_es24_is00.png";
import ad_dd00_es21_is00 from "./../Assets/car-images/ad_dd00_es23_is00.png";
import ad_dd00_es22_is00 from "./../Assets/car-images/ad_dd00_es22_is00.png";
import ad_dd00_es23_is00 from "./../Assets/car-images/ad_dd00_es21_is00.png";
import ad_dd00_es24_is00 from "./../Assets/car-images/ad_dd00_es20_is00.png";
import ad_dd00_es25_is00 from "./../Assets/car-images/ad_dd00_es18_is00.png";
import ad_dd00_es26_is00 from "./../Assets/car-images/ad_dd00_es17_is00.png";
import ad_dd00_es27_is00 from "./../Assets/car-images/ad_dd00_es16_is00.png";
import ad_dd00_es28_is00 from "./../Assets/car-images/ad_dd00_es15_is00.png";
import ad_dd00_es29_is00 from "./../Assets/car-images/ad_dd00_es27_is00.png";
import ad_dd00_es30_is00 from "./../Assets/car-images/ad_dd00_es28_is00.png";
import ad_dd00_es31_is00 from "./../Assets/car-images/ad_dd00_es29_is00.png";
import ad_dd00_es32_is00 from "./../Assets/car-images/ad_dd00_es30_is00.png";
import ad_dd00_es33_is00 from "./../Assets/car-images/ad_dd00_es31_is00.png";
import ad_dd00_es34_is00 from "./../Assets/car-images/ad_dd00_es32_is00.png";
import ad_dd00_es35_is00 from "./../Assets/car-images/ad_dd00_es34_is00.png";
import ad_dd00_es36_is00 from "./../Assets/car-images/ad_dd00_es33_is00.png";
import ad_dd00_es37_is00 from "./../Assets/car-images/ad_dd00_es35_is00.png";
import ad_dd00_es00_is01 from "./../Assets/car-images/ad_dd00_es00_is01.png";
import ad_dd00_es00_is02 from "./../Assets/car-images/ad_dd00_es00_is03.png";
import ad_dd00_es00_is03 from "./../Assets/car-images/ad_dd00_es00_is04.png";
import ad_dd00_es00_is04 from "./../Assets/car-images/ad_dd00_es00_is02.png";
import ad_dd00_es00_is05 from "./../Assets/car-images/ad_dd00_es00_is05.png";
import ad_dd00_es00_is06 from "./../Assets/car-images/ad_dd00_es00_is13.png";
import ad_dd00_es00_is07 from "./../Assets/car-images/ad_dd00_es00_is09.png";
import ad_dd00_es00_is08 from "./../Assets/car-images/ad_dd00_es00_is08.png";
import ad_dd00_es00_is09 from "./../Assets/car-images/ad_dd00_es00_is07.png";
import ad_dd00_es00_is10 from "./../Assets/car-images/ad_dd00_es00_is06.png";
import ad_dd00_es00_is11 from "./../Assets/car-images/ad_dd00_es00_is12.png";
import ad_dd00_es00_is12 from "./../Assets/car-images/ad_dd00_es00_is11.png";
import ad_dd00_es00_is13 from "./../Assets/car-images/ad_dd00_es00_is10.png";

export const DamagedImageSamples = [
  {
    namingRule: `dd00_es01_is00`,
    image: ad_dd00_es01_is00,
  },
  {
    namingRule: `dd00_es02_is00`,
    image: ad_dd00_es02_is00,
  },
  {
    namingRule: `dd00_es03_is00`,
    image: ad_dd00_es03_is00,
  },
  {
    namingRule: `dd00_es04_is00`,
    image: ad_dd00_es04_is00,
  },
  {
    namingRule: `dd00_es05_is00`,
    image: ad_dd00_es05_is00,
  },
  {
  namingRule: `dd00_es06_is00`,
    image: ad_dd00_es06_is00,
  },
  {
    namingRule: `dd00_es07_is00`,
    image: ad_dd00_es07_is00,
  },
  {
    namingRule: `dd00_es08_is00`,
    image: ad_dd00_es08_is00,
  },
  {
    namingRule: `dd00_es09_is00`,
    image: ad_dd00_es09_is00,
  },
  {
    namingRule: `dd00_es010_is00`,
    image: ad_dd00_es10_is00,
  },
  {
    namingRule: `dd00_es011_is00`,
    image: ad_dd00_es11_is00,
  },
  {
    namingRule: `dd00_es012_is00`,
    image: ad_dd00_es12_is00,
  },
  {
    namingRule: `dd00_es013_is00`,
    image: ad_dd00_es13_is00,
  },
  {
    namingRule: `dd00_es014_is00`,
    image: ad_dd00_es14_is00,
  },
  {
    namingRule: `dd00_es015_is00`,
    image: ad_dd00_es15_is00,
  },
  {
    namingRule: `dd00_es016_is00`,
    image: ad_dd00_es16_is00,
  },
  {
    namingRule: `dd00_es017_is00`,
    image: ad_dd00_es17_is00,
  },
  {
    namingRule: `dd00_es018_is00`,
    image: ad_dd00_es18_is00,
  },
  {
    namingRule: `dd00_es019_is00`,
    image: ad_dd00_es19_is00,
  },
  {
    namingRule: `dd00_es020_is00`,
    image: ad_dd00_es20_is00,
  },
  {
    namingRule: `dd00_es021_is00`,
    image: ad_dd00_es21_is00,
  },
  {
    namingRule: `dd00_es022_is00`,
    image: ad_dd00_es22_is00,
  },
  {
    namingRule: `dd00_es023_is00`,
    image: ad_dd00_es23_is00,
  },
  {
    namingRule: `dd00_es024_is00`,
    image: ad_dd00_es24_is00,
  },
  {
    namingRule: `dd00_es025_is00`,
    image: ad_dd00_es25_is00,
  },
  {
    namingRule: `dd00_es026_is00`,
    image: ad_dd00_es26_is00,
  },
  {
    namingRule: `dd00_es027_is00`,
    image: ad_dd00_es27_is00,
  },
  {
    namingRule: `dd00_es028_is00`,
    image: ad_dd00_es28_is00,
  },
  {
    namingRule: `dd00_es029_is00`,
    image: ad_dd00_es29_is00,
  },
  {
    namingRule: `dd00_es030_is00`,
    image: ad_dd00_es30_is00,
  },
  {
    namingRule: `dd00_es031_is00`,
    image: ad_dd00_es31_is00,
  },
  {
    namingRule: `dd00_es032_is00`,
    image: ad_dd00_es32_is00,
  },
  {
    namingRule: `dd00_es033_is00`,
    image: ad_dd00_es33_is00,
  },
  {
    namingRule: `dd00_es034_is00`,
    image: ad_dd00_es34_is00,
  },
  {
    namingRule: `dd00_es035_is00`,
    image: ad_dd00_es35_is00,
  },
  {
    namingRule: `dd00_es036_is00`,
    image: ad_dd00_es36_is00,
  },
  {
    namingRule: `dd00_es037_is00`,
    image: ad_dd00_es37_is00,
  },
  {
    namingRule: `dd00_es00_is01`,
    image: ad_dd00_es00_is01,
  },
  {
    namingRule: `dd00_es00_is02`,
    image: ad_dd00_es00_is02,
  },
  {
    namingRule: `dd00_es00_is03`,
    image: ad_dd00_es00_is03,
  },
  {
    namingRule: `dd00_es00_is04`,
    image: ad_dd00_es00_is04,
  },
  {
    namingRule: `dd00_es00_is05`,
    image: ad_dd00_es00_is05,
  },
  {
    namingRule: `dd00_es00_is06`,
    image: ad_dd00_es00_is06,
  },
  {
    namingRule: `dd00_es00_is07`,
    image: ad_dd00_es00_is07,
  },
  {
    namingRule: `dd00_es00_is08`,
    image: ad_dd00_es00_is08,
  },
  {
    namingRule: `dd00_es00_is09`,
    image: ad_dd00_es00_is09,
  },
  {
    namingRule: `dd00_es00_is10`,
    image: ad_dd00_es00_is10,
  },
  {
    namingRule: `dd00_es00_is11`,
    image: ad_dd00_es00_is11,
  },
  {
    namingRule: `dd00_es00_is12`,
    image: ad_dd00_es00_is12,
  },
  {
    namingRule: `dd00_es00_is13`,
    image: ad_dd00_es00_is13,
  },
];

export const CarTopStates = [
  { name: '_es01_', className: 'top-[39%] right-[0%]' },
  { name: '_es02_', className: 'bottom-[9%] right-[2%]' },
  { name: '_es03_', className: 'bottom-[1%] right-[22%]' },
  { name: '_es04_', className: 'bottom-[1%] right-[39%]' },
  { name: '_es05_', className: 'bottom-[0%] right-[56.5%]' },
  { name: '_es06_', className: 'bottom-[1%] right-[72.5%]' },
  { name: '_es07_', className: 'bottom-[1%] right-[87.5%]' },
  { name: '_es08_', className: 'bottom-[13%] right-[95%]' },
  { name: '_es09_', className: 'bottom-[39.5%] right-[97.5%]' },
  { name: '_es010_', className: 'bottom-[64.5%] right-[94.5%]' },
  { name: '_es011_', className: 'bottom-[81%] right-[89.5%]' },
  { name: '_es012_', className: 'bottom-[81%] right-[72.5%]' },
  { name: '_es013_', className: 'bottom-[81%] right-[56.5%]' },
  { name: '_es014_', className: 'bottom-[81%] right-[39%]' },
  { name: '_es015_', className: 'bottom-[81%] right-[21.5%]' },
  { name: '_es016_', className: 'bottom-[77%] right-[4%]' },
];

export const CarFrontStates = [
  { name: '_es025_', className: 'top-[39%] right-[10%]' },
  { name: '_es026_', className: 'top-[39%] right-[87%]' },
  { name: '_es027_', className: 'top-[-4%] right-[47.5%]' },
  { name: '_es028_', className: 'top-[49%] right-[47.5%]' },
];

export const CarLeftStates = [
  { name: '_es017_', className: 'top-[44.5%] right-[2%]' },
  { name: '_es018_', className: 'top-[44.5%] right-[17.5%]' },
  { name: '_es019_', className: 'top-[44.5%] right-[37.5%]' },
  { name: '_es020_', className: 'top-[44.5%] right-[57%]' },
  { name: '_es021_', className: 'top-[44.5%] right-[75%]' },
  { name: '_es022_', className: 'top-[44.5%] right-[95%]' },
  { name: '_es023_', className: 'top-[-2%] right-[25%]' },
  { name: '_es024_', className: 'top-[-2%] right-[59%]' },
];

export const CarBackStates = [
  { name: '_es035_', className: 'top-[34%] right-[8%]' },
  { name: '_es036_', className: 'top-[35%] right-[45%]' },
  { name: '_es037_', className: 'top-[34%] right-[85%]' },
];

export const CarRightStates = [
  { name: '_es029_', className: 'top-[52%] right-[2%]' },
  { name: '_es030_', className: 'top-[47%] right-[17%]' },
  { name: '_es031_', className: 'top-[41%] right-[37%]' },
  { name: '_es032_', className: 'top-[41%] right-[56%]' },
  { name: '_es033_', className: 'top-[30%] right-[74.5%]' },
  { name: '_es034_', className: 'top-[35%] right-[94%]' },
];

export const CarInitialStates = [
  { name: '_is01', className: 'top-[40%] right-[4.5%]', panelClassName: 'bottom-4' },
  { name: '_is02', className: 'top-[65.5%] right-[4.5%]', panelClassName: 'bottom-4' },
  { name: '_is03', className: 'top-[65.5%] right-[17%]', panelClassName: 'bottom-4' },
  { name: '_is04', className: 'top-[40%] right-[15.5%]', panelClassName: 'bottom-4' },
  { name: '_is05', className: 'top-[7%] right-[35.5%]' },
  { name: '_is06', className: 'top-[46%] right-[50%]', panelClassName: 'bottom-4' },
  { name: '_is07', className: 'top-[46%] right-[83%]', panelClassName: 'bottom-4' },
  { name: '_is08', className: 'top-[21%] right-[91%]', panelClassName: 'bottom-4' },
  { name: '_is09', className: 'top-[-3%] right-[83%]' },
  { name: '_is10', className: 'top-[-3%] right-[50%]' },
  { name: '_is11', className: 'top-[82%] right-[39%]', panelClassName: 'bottom-4' },
  { name: '_is12', className: 'top-[89%] right-[60%]', panelClassName: 'bottom-4' },
  { name: '_is13', className: 'top-[89%] right-[76%]', panelClassName: 'bottom-4' },
];

export const CarFunctionalities = [
  { value: "phone_holder", label: "Support de téléphone" },
  { value: "Touchscreen_car_radio", label: "Autoradio tactile" },
  { value: "GPS", label: "GPS" },
  { value: "CD_player", label: "Lecteur CD" },
  { value: "Speakers", label: "Haut-parleurs" },
  { value: "Steering_wheel", label: "Commande au volant" },
  { value: "Air_conditioner", label: "Climatisation" },
  { value: "Bi_zone_air_conditioning", label: "Climatisation bi-zone" },
  { value: "Cigarette_lighter", label: "Prise allume-cigare" },
  { value: "Recoil_radar", label: "Radar de recul" },
  { value: "Reversing_camera", label: "Caméra de recul" },
  { value: "Parking_assistance", label: "Assistance au stationnement" },
  { value: "Speed_regulator", label: "Régulateur de vitesse" },
  { value: "Cross_Control_Regulator", label: "Régulateur Cross Control" },
  { value: "Emergency_brake_assist", label: "Aide au freinage d’urgence" },
  { value: "Vitre_teintée", label: "Vitre teintée" },
  { value: "Sunroof", label: "Toit panoramique" },
  { value: "Sunroof", label: "Toit ouvrant" },
  { value: "Electric_seats", label: "Sièges életriques" },
  { value: "Bluetooth", label: "Bluetooth" },
  { value: "Auto_antivol", label: "Antivol auto" },
  { value: "car_alarm", label: "Alarme de voiture" },
  { value: "Contactless_keys", label: "Clés sans-contact" },
  { value: "Others", label: "Autres" },
]