import { useState, useEffect } from "react";
import showPass from "../../../Assets/Auth/showpass.svg";
import hidePass from "../../../Assets/Auth/hidepass.svg";
import logo from "../../../Assets/Auth/logo.png";
import { Link } from "react-router-dom";
import { authentication } from "../../../Redux/auth/login/actions";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
const Authentication = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const errorResponse = useSelector((state) => state.authenticate).error;
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (errorResponse) {
      if (errorResponse.length) {
        setError(errorResponse[0].msg);
      }
    }
  }, [errorResponse]);

  const onAuth = (e) => {
    e.preventDefault();
    const payload = { email, password };
    setLoading(true);
    dispatch(authentication(payload));
    setLoading(false);
  };
  const onChangeEmail = (e) => {
    setError(null);
    const value = e.target.value;
    setEmail(value);
  };

  const onChangePassword = (e) => {
    setError(null);
    const value = e.target.value;
    setpassword(value);
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const showPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const ref = useRef();
  useEffect(() => {}, []);

  return (
    <div className=" w-screen h-screen flex items-center justify-center bg-white0">
      <form className="font-mulish  rounded-lg border-grey3 border-2 p-5">
        {location.state?.verificationSuccess && (
          <div className=" flex justify-center p-3   bg-[#46bb4dd1]text-sm rounded-lg     w-full  ">
            <p className="font-normal text-[16px] text-white0 ">
              Votre compte est maintenant verifié !
            </p>
          </div>
        )}
        <div className=" space-y-10">
          <div className=" flex flex-col justify-center items-center ">
            <div className="m-5">
              <img className="w-25 h-16 my-5" src={logo} alt="logo" />
            </div>
            <div className="space-y-12">
              <div>
                <p className="font-bold text-[24px]">Se connecter</p>
              </div>
            </div>

            <div>
              <p className="font-normal text-[14px] text-grey1 ">
                Entrez votre e-mail et votre mot de passe
              </p>
            </div>
          </div>
          {loading && (
            <div className="flex flex-col justify-center items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 200 200"
                fill="none"
                color="#282F75"
              >
                <defs>
                  <linearGradient id="spinner-secondHalf">
                    <stop
                      offset="0%"
                      stop-opacity="0"
                      stop-color="currentColor"
                    />
                    <stop
                      offset="100%"
                      stop-opacity="0.5"
                      stop-color="currentColor"
                    />
                  </linearGradient>
                  <linearGradient id="spinner-firstHalf">
                    <stop
                      offset="0%"
                      stop-opacity="1"
                      stop-color="currentColor"
                    />
                    <stop
                      offset="100%"
                      stop-opacity="0.5"
                      stop-color="currentColor"
                    />
                  </linearGradient>
                </defs>
                <g stroke-width="8">
                  <path
                    stroke="url(#spinner-secondHalf)"
                    d="M 4 100 A 96 96 0 0 1 196 100"
                  />
                  <path
                    stroke="url(#spinner-firstHalf)"
                    d="M 196 100 A 96 96 0 0 1 4 100"
                  />

                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    d="M 4 100 A 96 96 0 0 1 4 98"
                  />
                </g>
                <animateTransform
                  from="0 0 0"
                  to="360 0 0"
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1300ms"
                />
              </svg>
            </div>
          )}
          {error && (
            <div
              className=" flex justify-center h-[3rem]  border-[#D20000] bg-[#FFF5F5] border-2 text-sm rounded-lg    block w-full  "
              ref={ref}
            >
              <div className="flex  items-center space-x-3 ">
                {/* <div className="flex justify-center items-center"> */}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z"
                    fill="#D20000"
                  />
                </svg>
                <p className="font-normal text-[13px] " id="errormsg">
                  {error}
                </p>
              </div>
            </div>
          )}

          <div className=" flex-col space-y-5 grid   ">
            <div>
              <div>
                <label
                  for="first_name"
                  className="uppercase block mb-2 text-[12px] font-medium text-grey1 dark:text-gray-300"
                >
                  E-mail
                </label>
                <input
                  type="Email"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  placeholder={"Adresse e-mail"}
                  className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row justify-between ">
                <label
                  for="Password"
                  className="uppercase block mb-2 text-[12px] font-medium text-grey1 dark:text-gray-300"
                >
                  Mot de passe
                </label>
              </div>
              <div className="flex relative flex-row items-center box-border h-10 w-80 border-2 rounded-lg border-grey3 ">
                <img
                  width={40}
                  className="absolute inset-y-0 right-0 px-2 py-2"
                  src={passwordShown ? hidePass : showPass}
                  onClick={showPassword}
                  alt="show password"
                />

                <input
                  className="bg-background border border-grey3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  placeholder={"Mot de passe"}
                />
              </div>

              <Link
                className="text-[12px] text-grey1 flex flex-row justify-end pt-3"
                to="/forgetpassword"
              >
                Mot de passe oublié ?
              </Link>
            </div>
            <div>
              <button
                className="box-border h-10 w-80  border-1 rounded-lg text-white0 bg-blue1 "
                type="submit"
                onClick={(e) => {
                  onAuth(e);
                }}
              >
                Se connecter
              </button>
            </div>
            <div>
              <p className="font-normal text-sm text-grey1">
                Pas de compte ?{" "}
                <Link
                  to="Registration"
                  className="font-normal text-sm text-blue1"
                >
                  S'enregistrer
                </Link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Authentication;
