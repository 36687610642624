import React, { useState } from "react";

const tasks = [
  {
    id: 1,
    title: "Appel WhatsApp “Client_0125”  pour “Renault, Clio, 2017, 12 000€”",
    checked: false,
  },
  {
    id: 2,
    title: "SMS “Eline”  pour “Renault, Clio, 2017, 12 000€”",
    checked: false,
  },
  {
    id: 3,
    title:
      "Messanger “Profil_facebook_0123”  pour “Renault, Clio, 2017, 12 000€”",
    checked: false,
  },
  {
    id: 4,
    title: "SMS “Eline”  pour “Renault, Clio, 2017, 12 000€”",
    checked: false,
  },
  {
    id: 5,
    title:
      "Messanger “Profil_facebook_0123”  pour “Renault, Clio, 2017, 12 000€”",
    checked: false,
  },
  {
    id: 6,
    title:
      "Appel téléphonique “Client_1345”  pour “Renault, Clio, 2017, 12 000€”",
    checked: true,
  },
  {
    id: 7,
    title:
      "Appel téléphonique “Client_0155”  pour “Renault, Clio, 2017, 12 000€”",
    checked: true,
  },
];
function PlanifiedTask() {
  const [planifiedTasks, setPlanifiedTasks] = useState(tasks);

  const handleCheckTask = (id) => () => {
    const newTasks = planifiedTasks.map((task) =>
      task.id === id ? { ...task, checked: !task.checked } : task
    );
    setPlanifiedTasks(newTasks);
  };
  return (
    <div className="rounded-xl border border-grey2 bg-white p-5">
      <div className="flex gap-2 items-center mb-4">
        <div className="bg-background p-1 rounded-xl">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
              stroke="#282F75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 2V6"
              stroke="#282F75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 2V6"
              stroke="#282F75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 10H21"
              stroke="#282F75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h2 className="font-bold text-l">Mes tâches planifiées</h2>
      </div>
      <div className="overflow-scroll h-[250px] px-10">
        <ul>
          {planifiedTasks.map((task) => (
            <li
              key={task.id}
              className={`flex gap-2 items-center mb-5 ${
                task.checked ? "line-through" : ""
              }`}
            >
              <label
                class={`flex items-center cursor-pointer relative ${
                  task.checked && "ml-2"
                }`}
              >
                <input
                  type="checkbox"
                  checked={task.checked}
                  onChange={handleCheckTask(task.id)}
                  class="peer h-5 w-5 checked:h-4 checked:w-4 cursor-pointer transition-all appearance-none rounded border-2 border-darkblue1 "
                  id="check-custom-icon"
                />
                <span class="absolute text-white opacity-0 peer-checked:opacity-100 border-blue1 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg
                    width={"14"}
                    height={"14"}
                    viewBox="0 0 11 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.875L3 7.75L9.66667 1.5"
                      stroke="#282F75"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </label>
              <p
                className={`${
                  task.checked ? "text-sm  text-grey-300" : " text-md "
                }`}
              >
                {task.title}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PlanifiedTask;
