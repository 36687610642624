import * as type from "./types";
const initialState = {
  next: 0,
};

const nextStep = (state = initialState, action) => {
  switch (action.type) {
    case type.NEXT:
      return {
        next: action.next,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default nextStep;
