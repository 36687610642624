import { combineReducers } from "redux";

// Authentication
import authenticate from "./auth/login/reducers";
import Register from "./auth/register/reducer";
import profile from "./auth/profile/reducer";
import notification from "./notification/reducer";
import { cars, formReducer } from "./cars/reducer";
import { users } from "./users/reducer";
import { subscriptions } from "./subscriptions/reducer";
import nextStep from "./camera/reducer";

const rootReducer = combineReducers({
  cars,
  users,
  formReducer,
  authenticate,
  Register,
  profile,
  notification,
  subscriptions,
  nextStep,
});

export default rootReducer;
