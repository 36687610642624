import React from "react";
import AgentIaLayout from "../../Layout/AgentAi/AgentIaLayout";
import FlowBuilder from "../../Components/AgentAi/FlowBuilder/FlowBuilder";

const Os = () => {
  return (
    <AgentIaLayout title={"Tester Steer OS !"}>
      <FlowBuilder />
    </AgentIaLayout>
  );
};

export default Os;
