import { Outlet } from "react-router-dom";
import SideBar from "./sideBar/";
export default function Layout() {
  return (
    <div className="flex flex-col fixed md:flex-row bg-white0 md:bg-background w-screen h-screen md:justify-between">
      <div className="flex md:relative flex-col md:basis-2/6 xl:basis-1/6  xxl:basis-1/6 xxxl:basis-1/12  z-10 ">
        <SideBar />
      </div>
      <div className="flex flex-col flex-grow md:basis-4/5 sm:bg-background overflow-hidden w-full mt-[22px] mr-[14px] md:pt-[9px] md:pl-[26px] md:pr-[16px] md:pb-[32px] ">
        <Outlet />
      </div>
    </div>
  );
}
