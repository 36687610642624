import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fragment } from "react";
const Member = () => {
  const [members, setMembers] = useState([]);

  const user = useSelector((state) => state.profile).profile;
  const id = user._id;
  const getMembers = async () => {
    axios
      .get(`${process.env.REACT_APP_AUTH_SERVICE}/api/user/`, {
        "x-auth-token": `${localStorage.getItem("token")}`,
      })
      .then((res) => setMembers(res.data));
  };
  //deletemember
  const deleteMember = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_AUTH_SERVICE}/api/user/${id}`, {
        "x-auth-token": `${localStorage.getItem("token")}`,
      })
      .then((res) => getMembers());
  };
  useEffect(() => {
    getMembers();
  }, [id]);
  return (
    <Fragment>
      <div className="h-[730px] pb-5 px-5 overflow-y-auto md:rounded-lg md:border md:border-grey2 sm:rounded-lg bg-white0 sm:bg-opacity-0 md:shadow md:bg-white0 md:p-5">
        <div className="flex flex-col  gap-y-20 ">
          <div className="mt-2 flex flex-col ">
            <div className="-my-2 tablet:overflow-x-auto  -mx-6 tablet:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full lg:px-8">
                <div>
                  <div className=" lg:block min-w-full  px-3  ">
                    <h2 className="font-bold text-[22px]  ">Membres</h2>
                    <p className="font-normal text-[16px]   py-3 ">
                      Gérez les accès et/ou ajoutez de nouveaux membres à
                      l’équipe.
                    </p>

                    <Link
                      className=" py-3 flex items-center mb-7"
                      to="/addmember"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <linearGradient
                            id="myGradient"
                            gradientTransform="90"
                          >
                            <stop offset="0%" stop-color="#76A4FF" />
                            <stop offset="0.01%" stop-color="#4368B1" />

                            <stop offset="100%" stop-color="#B7C7FF" />
                            <stop offset="100%" stop-color="#B7C7FF" />
                          </linearGradient>
                        </defs>
                        <path
                          d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5 9.00002V11H9V15H11V11H15V9.00002H11V5.00002H9V9.00002H5Z"
                          fill="url('#myGradient')"
                        />
                      </svg>

                      <p className="font-normal text-[16px]  text-lightblue2 ml-3 text-center ">
                        Ajouter un membre
                      </p>
                    </Link>

                    {members
                      .filter((e) => e.userId === id)
                      .map((e) => (
                        <div
                          key={e?._id}
                          className="sm:w-full xl:w-[700px] mb-5 flex items-center shadow-xl  border-b border-grey5 rounded-lg bg-white0 flex py-5 px-5 justify-between"
                        >
                          <div className="px-5">
                            <div className="flex">
                              <h2 className="font-normal text-[16px]text-left ">
                                {e?.title}
                              </h2>
                              <div
                                className={
                                  e?.role === "owner"
                                    ? " bg-transparent text-lightblue2 font-normal text-[14px]   px-4 border border-lightblue2  rounded-lg"
                                    : " bg-transparent text-lightgreen font-normal text-[14px]  px-4 border border-lightgreen rounded-lg"
                                }
                              >
                                {e?.role}
                              </div>
                            </div>
                            <h2 className="font-normal text-[16px] text-left ">
                              {e?.email}
                            </h2>
                          </div>
                          {e?.role !== "owner" && (
                            <div className="flex">
                              <button>
                                <div className="px-3">
                                  <svg
                                    width="16"
                                    height="15"
                                    viewBox="0 0 16 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.3214 7.49987C12.0255 7.49987 11.7857 7.73971 11.7857 8.03559V13.3927C11.7857 13.6886 11.5459 13.9284 11.25 13.9284H1.60714C1.31127 13.9284 1.07142 13.6886 1.07142 13.3927V2.67843C1.07142 2.38256 1.31127 2.14271 1.60714 2.14271H8.03572C8.3316 2.14271 8.57144 1.90286 8.57144 1.60698C8.57144 1.31111 8.3316 1.07129 8.03572 1.07129H1.60714C0.719542 1.07129 0 1.79083 0 2.67843V13.3927C0 14.2803 0.719542 14.9999 1.60714 14.9999H11.25C12.1376 14.9999 12.8572 14.2803 12.8572 13.3927V8.03556C12.8572 7.73971 12.6173 7.49987 12.3214 7.49987Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M14.401 0.600741C14.0163 0.216031 13.4946 -5.43035e-05 12.9506 8.47553e-06C12.4062 -0.001561 11.884 0.214901 11.5004 0.601087L4.44395 7.65695C4.38541 7.71593 4.34124 7.78763 4.31484 7.86641L3.24342 11.0807C3.14991 11.3614 3.30168 11.6648 3.5824 11.7582C3.63686 11.7764 3.69389 11.7856 3.75127 11.7857C3.80878 11.7856 3.86594 11.7764 3.92056 11.7584L7.13485 10.687C7.21379 10.6606 7.28552 10.6162 7.34431 10.5573L14.4007 3.50091C15.2016 2.7001 15.2017 1.40165 14.401 0.600741ZM13.6432 2.74392L6.67894 9.70823L4.59823 10.403L5.2909 8.32498L12.2579 1.36071C12.6409 0.978452 13.2613 0.979079 13.6436 1.36209C13.8262 1.54506 13.929 1.79276 13.9298 2.05125C13.9305 2.31113 13.8273 2.56048 13.6432 2.74392Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </button>
                              <button onClick={() => deleteMember(e?._id)}>
                                <svg
                                  width="13"
                                  height="15"
                                  viewBox="0 0 13 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.12395 5.40741C7.93088 5.40741 7.77441 5.56388 7.77441 5.75694V12.3631C7.77441 12.556 7.93088 12.7126 8.12395 12.7126C8.31701 12.7126 8.47348 12.556 8.47348 12.3631V5.75694C8.47348 5.56388 8.31701 5.40741 8.12395 5.40741Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M3.99895 5.40741C3.80588 5.40741 3.64941 5.56388 3.64941 5.75694V12.3631C3.64941 12.556 3.80588 12.7126 3.99895 12.7126C4.19201 12.7126 4.34848 12.556 4.34848 12.3631V5.75694C4.34848 5.56388 4.19201 5.40741 3.99895 5.40741Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M0.992724 4.44332V13.055C0.992724 13.564 1.17937 14.042 1.50541 14.385C1.82996 14.7289 2.28162 14.9242 2.7543 14.925H9.36753C9.84035 14.9242 10.292 14.7289 10.6164 14.385C10.9425 14.042 11.1291 13.564 11.1291 13.055V4.44332C11.7772 4.27128 12.1972 3.64513 12.1105 2.98007C12.0237 2.31514 11.4572 1.81774 10.7865 1.8176H8.99697V1.38069C8.99902 1.01327 8.85375 0.660467 8.59365 0.400914C8.33355 0.141497 7.98019 -0.00295785 7.61278 4.59254e-05H4.50906C4.14164 -0.00295785 3.78829 0.141497 3.52819 0.400914C3.26809 0.660467 3.12281 1.01327 3.12486 1.38069V1.8176H1.33529C0.664629 1.81774 0.098144 2.31514 0.0113075 2.98007C-0.0753924 3.64513 0.34459 4.27128 0.992724 4.44332ZM9.36753 14.2259H2.7543C2.15669 14.2259 1.69178 13.7126 1.69178 13.055V4.47404H10.43V13.055C10.43 13.7126 9.96515 14.2259 9.36753 14.2259ZM3.82392 1.38069C3.8216 1.19869 3.89314 1.02351 4.02231 0.895035C4.15133 0.766555 4.32692 0.695967 4.50906 0.699107H7.61278C7.79491 0.695967 7.9705 0.766555 8.09953 0.895035C8.22869 1.02338 8.30023 1.19869 8.29791 1.38069V1.8176H3.82392V1.38069ZM1.33529 2.51667H10.7865C11.134 2.51667 11.4157 2.79834 11.4157 3.14582C11.4157 3.4933 11.134 3.77498 10.7865 3.77498H1.33529C0.987808 3.77498 0.706136 3.4933 0.706136 3.14582C0.706136 2.79834 0.987808 2.51667 1.33529 2.51667Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M6.06047 5.40741C5.86741 5.40741 5.71094 5.56388 5.71094 5.75694V12.3631C5.71094 12.556 5.86741 12.7126 6.06047 12.7126C6.25353 12.7126 6.41 12.556 6.41 12.3631V5.75694C6.41 5.56388 6.25353 5.40741 6.06047 5.40741Z"
                                    fill="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Member;
