import React, { useEffect, useState } from "react";

const ConnectionModal = ({ isOpen, onClose }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[400px] ">
        <h2 className="text-black font-bold text-xl mb-4">OBD2 Connection</h2>
        {isButtonDisabled ? (
          <>
            <div className={`flex items-center justify-center mb-4`}>
              <svg
                width="68"
                height="68"
                viewBox="0 0 68 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M62.4969 33.5179C62.5922 39.1539 61.0142 44.6916 57.9623 49.4307C54.9104 54.1698 50.5217 57.8974 45.3512 60.1423C40.1808 62.3872 34.4607 63.0484 28.9145 62.0424C23.3682 61.0364 18.2448 58.4083 14.1922 54.4905C10.1395 50.5727 7.33966 45.5411 6.14663 40.032C4.9536 34.523 5.421 28.7838 7.4897 23.5404C9.55841 18.297 13.1355 13.7847 17.7687 10.5743C22.4018 7.36386 27.883 5.5994 33.5189 5.50406C34.1487 5.4934 34.7485 5.23301 35.1863 4.78014C35.6241 4.32727 35.8641 3.71903 35.8534 3.08923C35.8428 2.45943 35.5824 1.85966 35.1295 1.42185C34.6766 0.984053 34.0684 0.744084 33.4386 0.754738C26.8633 0.865966 20.4687 2.9245 15.0633 6.67001C9.65795 10.4155 5.48465 15.6798 3.07116 21.7971C0.65767 27.9145 0.112377 34.6101 1.50424 41.0374C2.89611 47.4646 6.16262 53.3348 10.8907 57.9056C15.6188 62.4764 21.5961 65.5425 28.0667 66.7161C34.5374 67.8898 41.2107 67.1184 47.2429 64.4994C53.2752 61.8804 58.3953 57.5314 61.9558 52.0024C65.5164 46.4735 67.3574 40.0129 67.2462 33.4376C67.2356 32.8078 66.9752 32.208 66.5223 31.7702C66.0694 31.3324 65.4612 31.0925 64.8314 31.1031C64.2016 31.1138 63.6018 31.3742 63.164 31.827C62.7262 32.2799 62.4862 32.8881 62.4969 33.5179Z"
                  fill="#282F75"
                />
              </svg>
            </div>
            <div className={`flex items-center  mb-4 `}>
              <p className="text-darkblue1 text-md">
                Connecting to OBD2 device...
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={`flex items-center justify-center mb-4`}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0007 63.6666C14.5196 63.6474 0.353182 49.481 0.333984 32V31.3666C0.682101 13.9643 15.0102 0.105171 32.4144 0.336096C49.8187 0.567022 63.774 14.8014 63.6603 32.2069C63.5466 49.6123 49.4065 63.6632 32.0007 63.6666ZM17.4657 30.7016L13.0007 35.1666L25.6673 47.8333L51.0007 22.5L46.5357 18.0033L25.6673 38.8716L17.4657 30.7016Z"
                  fill="#282F75"
                />
              </svg>
            </div>
            <div className={`flex items-center  mb-4 `}>
              <p className="text-darkblue1 text-md">
                The connection with the ODB2 adapter has beed established.
              </p>
            </div>
          </>
        )}

        <button
          onClick={onClose}
          disabled={isButtonDisabled}
          className={`px-2 py-1 ${
            isButtonDisabled ? "bg-[lightgray]" : "bg-lightblue2"
          } w-20 text-white rounded float-right`}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default ConnectionModal;
