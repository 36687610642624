import React from "react";

function BackButton({ handleBack }) {
  return (
    <div
      className="flex gap-2 items-center cursor-pointer"
      onClick={handleBack}
    >
      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.01L6.01 12.02L7.424 10.606L2.824 6.006L7.424 1.406L6.01 0L0 6.01Z"
          fill="black"
        />
      </svg>
      <p className="font-bold">Back</p>
    </div>
  );
}

export default BackButton;
