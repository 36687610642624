import React from "react";
import LacentraleIcon from "./icons/LacentraleIcon";
import LeboncoinIcon from "./icons/LeboncoinIcon";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import NeedHelp from "../../needHelp/NeedHelp";

function Insight() {
  //TOOD:: used values should be get from global state during the vertical stepper
  return (
    <>
      <NeedHelp />
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-center mb-4 mt-4">
          Aperçu des détails de la compagne
        </h1>
      </div>
      <div>
        <div className="flex items-center mb-5 ">
          <p className="text-textgrey1 w-3/5 text-right mr-4">
            Budget estimé par jour :
          </p>
          <div className=" w-3/5 p-2 text-center flex">
            <span className="text-sm text-grey1">2.00€</span>
          </div>
        </div>
        <div className="flex items-center mb-5">
          <p className="text-textgrey1 w-3/5 text-right mr-4">
            Durée de la compagne :
          </p>
          <div className=" w-3/5 p-2 text-center flex">
            <span className="text-sm text-grey1">10 jours</span>
          </div>
        </div>
        <div className="flex items-center mb-5">
          <p className="text-textgrey1 w-3/5 text-right mr-4">
            Populations cibles :
          </p>
          <div className=" w-3/5 p-2 text-center flex">
            <span className="text-sm text-grey1 text-start">
              Hommes uniquement, age entre 18 - 66, jeunes actifs, cadres,
              senior, qui habitent dans: ile-de-France
            </span>
          </div>
        </div>

        <div className="flex items-center mb-5">
          <p className="text-textgrey1 w-3/5 text-right mr-4">
            Plateformes concernées :
          </p>
          <div className=" w-3/5 p-2 text-center flex">
            <span className="text-sm ">
              <FacebookIcon height={24} />
            </span>
            <span className="text-sm ">
              <InstagramIcon height={24} />
            </span>
          </div>
        </div>
        <div className="flex items-center mb-5">
          <p className="text-textgrey1 w-3/5 text-right mr-4">
            Sites locaux concernés :
          </p>
          <div className=" w-3/5 p-2 text-center flex">
            <span className="text-sm text-grey1 text-start">Aucun</span>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-center mb-4 mt-4">
            Sites concernés par la création d’une annonce
          </h1>
        </div>
        <div className="w-full justify-center flex items-center">
          <div className="flex w-[50%] justify-around items-center">
            <div className="flex flex-col justify-start gap-2">
              <LeboncoinIcon height={50} width={150} />
            </div>
            <div>
              <LacentraleIcon height={50} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-4">
        <div className="flex flex-col gap-2 p-5 border rounded w-[70%] justify-center shadow drop-shadow items-center my-2">
          <h1 className="font-bold">Sauvegarder votre compagne</h1>
          <p className="text-md">Donner un nom à votre compagne</p>
          <input type="text" placeholder="Compagne 1" className="px-1" />
          <button className="px-2 py-1 bg-lightblue2 text-white rounded mt-2">
            Sauvegarder
          </button>
        </div>
      </div>
    </>
  );
}

export default Insight;
