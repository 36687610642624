import * as type from "./types";
import axios from "axios";

export const dispatchNextStep = (data) => {
  return {
    type: type.NEXT,
    next: data,
  };
};

export const nextStepEnum = async (payload) => {
  return async (dispatch) => {
    dispatch(dispatchNextStep(payload));
  };
};
