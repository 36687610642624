import * as type from "./types";
import axios from "axios";

export const notifAmountAction = (data) => {
  return {
    type: type.NOTIF_AMOUNT,
    notifAmount: data,
  };
};

export const notifAmount = () => {
  return async (dispatch) => {
    try {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/notifAmount/`,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      };

      await axios(config)
        .then(function (response) {
          dispatch(notifAmountAction(response.data));
        })
        .catch(function (error) {
          // to enhance
        });
    } catch (error) {
      // to enhance
    }
  };
};

export const notifRead = async () => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/notifRead/`,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      };
      await axios(config)
        .then(() => {
          dispatch(notifAmountAction(0));
        })
        .catch((err) => err);
    } catch (error) {
      // to enhance
    }
  };
};
