import React, { Fragment } from "react";
import VehicleSheet from "../../Components/vehicleSheet";

function DisplayVehicleSheet() {
  return (
    <Fragment>
      <VehicleSheet />
    </Fragment>
  );
}

export default DisplayVehicleSheet;
