import React from "react";
import HelpIcon from "../horizentalStepper/icons/HelpIcon";

function StepHeader({ invokeHelp, isOBD2Connected }) {
  return (
    <div className="flex justify-between items-center  p-2">
      <div className="flex items-center">
        <span className="mr-2">OBD2 Status:</span>
        <div className="flex items-center">
          <div
            className={`w-3 h-3 ${
              isOBD2Connected ? "bg-green-500" : "bg-gray-500"
            } rounded-full mx-2`}
          ></div>
        </div>
        <span>{isOBD2Connected ? "Connected" : "Disconnected"}</span>
      </div>
      <div
        className="flex items-center border-2 cursor-pointer	 justify-between mr-2 p-2 h-8"
        onClick={invokeHelp}
      >
        <HelpIcon />
        <span className="ml-2">I need help</span>
      </div>
    </div>
  );
}

export default StepHeader;
