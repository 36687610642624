import React, { useState } from "react";
import { steps } from "./common/steps";
import ChooseCarForMultiDiffusion from "./steps/ChooseCarForMultiDiffusion";
import ConfigureCampaign from "./steps/ConfigureCompaign";
import ChooseConfigurationModal from "./CampaignStepper/modals/ChooseConfigurationModal";
import ContinueBudgetModal from "./CampaignStepper/modals/ContinueBudgetModal";
import ValidateParametersModal from "./CampaignStepper/modals/ValidateParametersModal";
import CreatedCampagneModal from "./CampaignStepper/modals/CreatedCampagneModal";
import { useNavigate } from "react-router";

const parameters = {
  estimatedAmount: 2,
  days: 10,
  configName: "Campagne1",
  startDate: "4 Janvier 2025",
};
function Marketing() {
  const [activeStep, setActiveStep] = useState(1);
  const [cars, setCars] = useState([]);
  const [ChooseConfiguration, setChooseConfiguration] = useState({
    isOpen: false,
    configuration: undefined,
  });
  const [campaignStep, setCampaignStep] = useState(0);
  const [ContinueBudget, setContinueBudgetModal] = useState(false);
  const [validateParameters, setValidateParametersModal] = useState(false);
  const [createdCampagne, setCreatedCampagneModal] = useState(false);

  const handleCreatedCampagneContinue = () => {
    setCreatedCampagneModal(false);
    setCampaignStep(campaignStep + 1);
  };
  const handleStepNext = () => {
    if (campaignStep === 3) {
      handleContinueBudgetOpen();
      return;
    }
    if (campaignStep === 4) {
      handleValidateParametersOpen();
      return;
    }
    setCampaignStep(Math.min(steps.length - 1, campaignStep + 1));
  };

  const handleStepBack = () => {
    setCampaignStep(Math.max(0, campaignStep - 1));
  };
  const handleValidateParametersContinue = () => {
    setValidateParametersModal(false);
    setCreatedCampagneModal(true);
  };
  const handleValidateParametersDiscard = () => {
    setValidateParametersModal(false);
  };
  const handleValidateParametersOpen = () => {
    setValidateParametersModal(true);
  };

  const handleContinueBudgetContinue = () => {
    setContinueBudgetModal(false);
    setCampaignStep(campaignStep + 1);
  };
  const handleContinueBudgetDiscard = () => {
    setContinueBudgetModal(false);
  };
  const handleContinueBudgetOpen = () => {
    setContinueBudgetModal(true);
  };
  const handleOpenChooseConfigurationModal = () => {
    setChooseConfiguration((preConfig) => ({ ...preConfig, isOpen: true }));
  };
  const handleCloseChooseConfigurationModal = () => {
    setChooseConfiguration((preConfig) => ({ ...preConfig, isOpen: false }));
  };
  const handleChooseConfiguration = (configuration) => {
    return () => {
      setChooseConfiguration((preConfig) => ({
        ...preConfig,
        configuration: configuration,
        isOpen: false,
      }));
    };
  };
  const navigate = useNavigate();
  const handleGoToAnalytics = () => setActiveStep(navigate("/sales/analytics"));
  const handleChooseCars = (cars) => {
    setCars([...cars]);
    setActiveStep(2);
  };
  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return (
          <ChooseCarForMultiDiffusion handleChooseCars={handleChooseCars} />
        );
      case 2:
        return (
          <ConfigureCampaign
            goBack={() => setActiveStep((activeStep) => activeStep - 1)}
            chosenConfiguration={ChooseConfiguration}
            handleOpenChooseConfigurationModal={
              handleOpenChooseConfigurationModal
            }
            handleContinueBudgetOpen={handleContinueBudgetOpen}
            campaignStep={campaignStep}
            handleStepNext={handleStepNext}
            handleStepBack={handleStepBack}
            handleGoToAnalytics={handleGoToAnalytics}
            chosenCars={cars}
          />
        );
      default:
        break;
    }
  };

  const renderPageTitle = () => {
    switch (activeStep) {
      case 1:
        return "Sélection des  véhicules";
      case 2:
        return "Configuration de campagne";
      default:
        return "";
    }
  };
  return (
    <>
      <h1 className="font-bold text-xl mb-5 position-fixed pl-2">
        Multi-diffusion: {renderPageTitle()}
      </h1>
      <div className="flex h-[100%] flex-col overflow-y-scroll mt-4">
        {renderSteps()}
        <ChooseConfigurationModal
          handleChooseConfiguration={handleChooseConfiguration}
          onClose={handleCloseChooseConfigurationModal}
          isOpen={ChooseConfiguration.isOpen}
        />
        <ContinueBudgetModal
          isOpen={ContinueBudget}
          onClose={handleContinueBudgetDiscard}
          onContinue={handleContinueBudgetContinue}
          parameters={parameters}
        />
        <ValidateParametersModal
          isOpen={validateParameters}
          onClose={handleValidateParametersDiscard}
          onContinue={handleValidateParametersContinue}
          parameters={parameters}
        />
        <CreatedCampagneModal
          isOpen={createdCampagne}
          onContinue={handleCreatedCampagneContinue}
        />
      </div>
    </>
  );
}

export default Marketing;
