import React from "react";

const TickIcon = ({ active, height, width }) => (
  <svg
    width={width ?? "17"}
    height={height ?? "13"}
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85911 12.9999C5.58195 12.999 5.31758 12.8832 5.12911 12.6799L0.269112 7.50994C-0.109203 7.10677 -0.0890559 6.47325 0.314112 6.09494C0.71728 5.71662 1.3508 5.73677 1.72911 6.13994L5.84911 10.5299L14.2591 1.32994C14.4917 1.04035 14.8656 0.903506 15.2301 0.974547C15.5946 1.04559 15.8898 1.31281 15.9966 1.66853C16.1034 2.02424 16.0042 2.40985 15.7391 2.66994L6.59911 12.6699C6.4124 12.8769 6.1478 12.9965 5.86911 12.9999H5.85911Z"
      fill="white"
    />
  </svg>
);
export default TickIcon;
