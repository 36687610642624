import React from "react";
export function ImageGalleryCarousel({ images, active, setActive }) {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="flex gap-4 overflow-x-auto max-w-full justify-center h-[100px] min-w-0">
        {images.map(({ link }, index) => (
          <img
            key={index}
            onClick={() => setActive(link)}
            src={link}
            className="w-[200px] cursor-pointer rounded-lg object-cover"
            alt="gallery-image"
          />
        ))}
      </div>
    </div>
  );
}
