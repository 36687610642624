import { Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SocketContext, socket } from "./Context/socket";
import { profile } from "./Redux/auth/profile/actions";
import { AUTH_ROUTES, MAIN_ROUTES } from "./Routes";

export default function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authenticate.isLoggedIn);
  const loading = useSelector((state) => state.authenticate.loading);
  const user = useSelector((state) => state.profile).profile;

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(profile());
    }
  }, [isLoggedIn]);

  return (
    <SocketContext.Provider value={socket}>
      {!loading && (
        <Routes>
          <Fragment>
            {isLoggedIn ? (
              <Route element={<Layout />}>
                {MAIN_ROUTES.map((route, index) =>
                  route.roles?.length ? (
                    <Fragment key={index}>
                      {route.roles.includes(user.role) ? (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.component}
                        />
                      ) : null}
                    </Fragment>
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.component}
                    />
                  )
                )}
              </Route>
            ) : (
              AUTH_ROUTES.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              ))
            )}
            <Route path="*" element={null} />
          </Fragment>
        </Routes>
      )}
    </SocketContext.Provider>
  );
}
