export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USERS_COUNT = "GET_USERS_COUNT";
export const EDIT_USER = "EDIT_USER";
export const USER_EDIT_COUNT = "USER_EDIT_COUNT";
export const ADD_USER_COUNT = "ADD_USER_COUNT";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const OVERVIEW_IMGS = "OVERVIEW_IMGS";
export const REMOVE_IMG = "REMOVE_IMG";
export const CDD_IMAGE = "CDD_IMAGE";
export const ANALYZED_USERS_IMAGES = "ANALYZED_USERS_IMAGES";
export const ERROR = "ERROR";
export const EDIT_CDD_IMAGE = "EDIT_CDD_IMAGE";
export const EDIT_OVERVIEW_IMGS = "EDIT_OVERVIEW_IMGS";
export const FORM_INFO_EDIT = "FORM_INFO_EDIT";
export const CLEARSTORE = "CLEARSTORE";
export const IS_NEXT_CONFIRMED = "IS_NEXT_CONFIRMED";
export const DAMAGED_USER_IMAGE = "DAMAGED_USER_IMAGE";
export const DELETE_DAMAGED_USER_IMAGE = "DELETE_DAMAGED_USER_IMAGE";
export const UPDATE_DAMAGED_USER_IMAGE = "UPDATE_DAMAGED_USER_IMAGE";
export const UPLOAD_START_DAMAGED_USER_IMAGE = "UPLOAD_START_DAMAGED_USER_IMAGE";
export const UPLOAD_FINISH_DAMAGED_USER_IMAGE = "UPLOAD_FINISH_DAMAGED_USER_IMAGE";
export const CLEAR_DAMAGED_USER_IMAGE = "CLEAR_DAMAGED_USER_IMAGE";
export const DAMAGED_USER_IMAGE_TEMPORARY = "DAMAGED_USER_IMAGE_TEMPORARY";
