import CarAnalysisIcon from "../../stepper/horizentalStepper/icons/CarAnalysisIcon";
import CardDetailsIcon from "../../stepper/horizentalStepper/icons/CarDetailsIcon";
import CardMultidiffusionIcon from "../../stepper/horizentalStepper/icons/CarMultidiffusionIcon";

export const steps = [
  {
    label: "Préparez vous pour la compagne",
    description: ``,
    withNext: true,
    withBack: false,
  },
  {
    label: "Connectez les plateformes  ",
    description: "Suivre les instructions pour connecter vos plateformes",
    withNext: true,
    withBack: true,
  },
  {
    label: "Configuration de la population cible",
    description: `Suivre les instructions pour configurer le ciblage`,
    withNext: true,
    withBack: true,
  },
  {
    label: "Configuration  du budget",
    description: `Suivre les instructions pour configurer le budget`,
    withNext: true,
    withBack: true,
  },
  {
    label: "Aperçu",
    description: `Vérifier la configuration de votre compagne`,
    withNext: true,
    withBack: true,
  },
  {
    label: "Publier",
    description: ``,
    withNext: false,
    withBack: true,
  },
];
export const baseSteps = [
  {
    label: "Sélectionner",
    component: CardDetailsIcon,
  },
  {
    label: "Multidiffusion",
    component: CardMultidiffusionIcon,
  },
  {
    label: "Analytiques",
    component: CarAnalysisIcon,
  },
];
