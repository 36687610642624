import React from "react";
import Chart from "../../Components/Chart";
import StatCubes from "../../Components/statsCubes";

const Stats = () => {
  return (
    <div className="overflow-auto scrollbar-hide ">
      <div className="flex flex-grow items-center justify-between p-4">
        <p className="font-bold text-[23px] mb-5">Overview</p>
        <div className="flex space-x-4">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 20H11V13H22V20ZM9 20H2V13H9V20ZM22 11H2V4H22V11Z"
              fill="#C5C7CD"
            />
          </svg>

          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.5"
              cy="6.5"
              r="5.75"
              stroke="#C5C7CD"
              stroke-width="1.5"
            />
            <path
              d="M11 11L15 15"
              stroke="#C5C7CD"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1211 9.92257L13.7469 9.12903C13.8856 8.38064 13.8856 7.6129 13.7469 6.86452L15.1211 6.07097C15.2792 5.98065 15.3501 5.79356 15.2985 5.61936C14.9405 4.47098 14.3308 3.43228 13.534 2.56776C13.4114 2.4355 13.2114 2.40325 13.0566 2.49357L11.6824 3.28711C11.105 2.79034 10.4405 2.40647 9.72112 2.15486V0.570996C9.72112 0.390351 9.59532 0.232287 9.4179 0.193578C8.23403 -0.0709372 7.02113 -0.0580341 5.89533 0.193578C5.71791 0.232287 5.59211 0.390351 5.59211 0.570996V2.15809C4.87598 2.41292 4.21147 2.79679 3.63083 3.29034L2.25987 2.49679C2.1018 2.40647 1.90503 2.4355 1.78245 2.57099C0.985677 3.43228 0.376002 4.47098 0.0179393 5.62259C-0.0368992 5.79678 0.037294 5.98388 0.195358 6.0742L1.56955 6.86775C1.43084 7.61613 1.43084 8.38387 1.56955 9.13225L0.195358 9.9258C0.037294 10.0161 -0.0336734 10.2032 0.0179393 10.3774C0.376002 11.5258 0.985677 12.5645 1.78245 13.429C1.90503 13.5613 2.10503 13.5935 2.25987 13.5032L3.63405 12.7097C4.21147 13.2064 4.87598 13.5903 5.59534 13.8419V15.429C5.59534 15.6096 5.72114 15.7677 5.89856 15.8064C7.08243 16.0709 8.29532 16.058 9.42112 15.8064C9.59854 15.7677 9.72435 15.6096 9.72435 15.429V13.8419C10.4405 13.5871 11.105 13.2032 11.6856 12.7097L13.0598 13.5032C13.2179 13.5935 13.4147 13.5645 13.5372 13.429C14.334 12.5677 14.9437 11.529 15.3017 10.3774C15.3501 10.2 15.2792 10.0129 15.1211 9.92257ZM7.65662 10.5774C6.23404 10.5774 5.07598 9.41935 5.07598 7.99677C5.07598 6.5742 6.23404 5.41614 7.65662 5.41614C9.07919 5.41614 10.2372 6.5742 10.2372 7.99677C10.2372 9.41935 9.07919 10.5774 7.65662 10.5774Z"
              fill="#282F75"
            />
          </svg>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <StatCubes
          title="Average Days to Sell"
          value="16 days"
          change="-5"
          period="This Week"
        />
        <StatCubes
          title="Average Selling Price"
          value="16.550 €"
          change="+5%"
          period="This Week"
        />
        <StatCubes
          title="Average age of inventory"
          value="5,5 year"
          change="+10%"
          period="This Month"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2">
          <Chart />
        </div>
        <div className="space-y-4 ">
          <StatCubes
            title="Gross Profit Margin"
            value="4844 €"
            change="-10%"
            period="Per Week"
          />
          <StatCubes
            title="Vehicle Acquisition Cost Ratio"
            value="1600 €"
            change="-5%"
            period="Per Week"
          />
          <StatCubes
            title="Traffic and Conversion Rate"
            value="9%"
            change="+2"
            period="This Month"
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
