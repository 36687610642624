import React from "react";

const Car360ViewIcon = ({ active, color }) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ opacity: active ? 1 : 0.5 }}
  >
    <path
      d="M4.11858 10.1882L4.55322 8.91747H7.74059L8.17523 10.1882M7.74059 11.6001C7.62531 11.6001 7.51476 11.5554 7.43325 11.476C7.35174 11.3966 7.30595 11.2888 7.30595 11.1765C7.30595 11.0642 7.35174 10.9564 7.43325 10.877C7.51476 10.7976 7.62531 10.7529 7.74059 10.7529C7.85586 10.7529 7.96641 10.7976 8.04792 10.877C8.12944 10.9564 8.17523 11.0642 8.17523 11.1765C8.17523 11.2888 8.12944 11.3966 8.04792 11.476C7.96641 11.5554 7.85586 11.6001 7.74059 11.6001ZM4.55322 11.6001C4.43795 11.6001 4.3274 11.5554 4.24589 11.476C4.16438 11.3966 4.11858 11.2888 4.11858 11.1765C4.11858 11.0642 4.16438 10.9564 4.24589 10.877C4.3274 10.7976 4.43795 10.7529 4.55322 10.7529C4.6685 10.7529 4.77905 10.7976 4.86056 10.877C4.94207 10.9564 4.98786 11.0642 4.98786 11.1765C4.98786 11.2888 4.94207 11.3966 4.86056 11.476C4.77905 11.5554 4.6685 11.6001 4.55322 11.6001ZM8.15205 8.77628C8.0941 8.6125 7.93183 8.4939 7.74059 8.4939H4.55322C4.36198 8.4939 4.19972 8.6125 4.14176 8.77628L3.53906 10.4706V12.7296C3.53906 12.8045 3.56959 12.8763 3.62393 12.9293C3.67827 12.9822 3.75197 13.012 3.82882 13.012H4.11858C4.19543 13.012 4.26913 12.9822 4.32347 12.9293C4.37782 12.8763 4.40834 12.8045 4.40834 12.7296V12.4472H7.88547V12.7296C7.88547 12.8045 7.916 12.8763 7.97034 12.9293C8.02468 12.9822 8.09838 13.012 8.17523 13.012H8.46499C8.54184 13.012 8.61554 12.9822 8.66988 12.9293C8.72422 12.8763 8.75475 12.8045 8.75475 12.7296V10.4706L8.15205 8.77628Z"
      fill={color ?? "#282F75"}
    />
    <path
      d="M1.52581 14.4997V14.4995L1.51346 14.4998C1.38965 14.5029 1.2602 14.4703 1.13263 14.3944C1.00372 14.3178 0.877502 14.1969 0.770587 14.0302C0.663673 13.8635 0.582188 13.6602 0.537674 13.4338C0.493197 13.2075 0.487965 12.9697 0.522703 12.7396L0.522754 12.7396L0.523722 12.7325L2.02122 1.75043L2.02141 1.75046L2.02291 1.73667C2.06371 1.35957 2.20538 1.03456 2.39898 0.813358C2.5896 0.595553 2.80603 0.502675 3.0009 0.50035H17.4742V0.500502L17.4865 0.500197C17.6103 0.497139 17.7398 0.529698 17.8674 0.60557C17.9963 0.682243 18.1225 0.803106 18.2294 0.96979C18.3363 1.13647 18.4178 1.33978 18.4623 1.56624C18.5068 1.79251 18.512 2.03029 18.4773 2.26039L18.4772 2.26038L18.4762 2.26823L16.8274 14.4997H1.52581Z"
      stroke={color ?? "#282F75"}
    />
    <path
      d="M14.8078 6.91268V7.72277L13.6436 6.59325V5.10545C13.6436 5.06351 13.6315 5.0224 13.6088 4.98673C13.586 4.95106 13.5535 4.92224 13.5149 4.90349L12.2466 4.2879V3.03346L12.8487 3.61765L13.1779 3.29822L12.0137 2.1687L10.8495 3.29822L11.1787 3.61765L11.7809 3.03346V4.28813L10.5126 4.90326C10.4739 4.92203 10.4413 4.95089 10.4186 4.9866C10.3958 5.02231 10.3838 5.06347 10.3838 5.10545V6.59325L9.21959 7.72277V6.91268H8.75391V8.494H10.3838V8.0422H9.54883L10.6625 6.9617L11.9096 7.56667C11.942 7.58232 11.9776 7.59047 12.0137 7.59047C12.0498 7.59047 12.0855 7.58232 12.1178 7.56667L13.3649 6.9617L14.4786 8.0422H13.6436V8.494H15.2735V6.91268H14.8078ZM11.7809 6.99897L10.8495 6.54716V5.47096L11.7809 5.92277V6.99897ZM12.0137 5.5306L11.1373 5.10545L12.0137 4.6803L12.8901 5.10545L12.0137 5.5306ZM13.1779 6.54716L12.2466 6.99897V5.92277L13.1779 5.47096V6.54716Z"
      fill={color ?? "#282F75"}
    />
  </svg>
);
export default Car360ViewIcon;
