import { Dialog, Disclosure, Transition } from "@headlessui/react";
import axios from "axios";
import moment from "moment";
import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import RiseLoader from "react-spinners/RiseLoader";
import Swal from "sweetalert2";
import dropDownIconDown from "../../Assets/dropdown-down.svg";
import dropDownIconUp from "../../Assets/dropdown-up.svg";
import emptySign from "../../Assets/emptySign.svg";
import { handleDeleteAd } from "./WebCarDisplay";
import Checkbox from "./subComponents/checkbox";
const MobileCarDisplay = ({
  loading,
  carData,
  isGenerateAdActive,
  handleCheckbox,
  checkbox,
  removeItem,
  mode,
  handleChooseCar,
}) => {
  const [singleAd, setSingleAd] = useState([]);
  const [singleAdsLoading, setSingleAdsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const riseLoaderCss = {
    marginTop: "50%",
    marginBottom: "50%",
    display: "flex",
    alignSelf: "center",
  };

  async function getSingleCar(id) {
    // I THINK THIS COULD BE DONE IN A DIFFERENT WAY
    try {
      const singleAdData = await axios.get(
        `${process.env.REACT_APP_CAR_SERVICE}/api/car/${id}`,
        { headers: { "x-auth-token": `${localStorage.getItem("token")}` } }
      );
      setSingleAd(singleAdData.data);

      setSingleAdsLoading(false);
    } catch (error) {
      Swal.fire(error.response);
      setSingleAdsLoading(false);
    }
  }
  let [selectedCarId, setSelectedCarId] = useState(null);
  const openModal = (e, carId) => {
    e.stopPropagation();
    setSelectedCarId(carId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="flex flex-col lg:hidden  space-y-auto  overflow-scroll ">
      {!loading ? (
        carData.length === 0 ? (
          <div className="flex flex-col justify-center items-center">
            <img src={emptySign} alt="empty-sign" />
            <p className="text-[18px] font-bold">Pas encore de voitures</p>
            <NavLink
              className={
                " text-[16px] mx-3 bg-warning1 p-2 text-center text-white0"
              }
              to="/addCar"
            >
              + Ajouter
            </NavLink>
          </div>
        ) : (
          carData.map((car, key) => {
            return (
              <div className="flex flex-col border-b border-b-grey2" key={key}>
                {isGenerateAdActive ? (
                  <div className="flex space-x-20 ">
                    <div className="flex flex-row  ">
                      <Checkbox
                        onChange={handleCheckbox}
                        value={checkbox}
                        id={car._id}
                      ></Checkbox>
                    </div>
                    <div className="m-4">
                      <p className=" whitespace-no-wrap font-semibold text-black1">
                        {car.make}
                      </p>
                      <p className="whitespace-no-wrap items-start text-[12px] text-grey1">
                        {"Mise a jours " +
                          moment(car.updatedAt).startOf("minutes").fromNow()}
                      </p>
                    </div>
                  </div>
                ) : (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex  py-2 justify-between w-full">
                          <div className="flex justify-between items-center w-full px-4">
                            <div className="flex items-center ">
                              <img
                                className="m-2"
                                src={!open ? dropDownIconUp : dropDownIconDown}
                                onClick={() => getSingleCar(car._id)}
                                alt="drop-down-icon"
                              />
                              <h2 className="m-2 font-bold text-[#9FA2B4] hidden xs:block">
                                Marque
                              </h2>
                            </div>
                            <div className="flex items-center ml-auto">
                              <div className="flex flex-col items-start ml-auto xs:w-[170px] sm:w-[250px]">
                                <p className="whitespace-no-wrap font-semibold text-[16px] text-black1 text-left">
                                  {car.make}
                                </p>
                                <p className="whitespace-no-wrap text-[12px] text-grey1 items-start text-left">
                                  {"Mise a jours " +
                                    moment(car.updatedAt)
                                      .startOf("day")
                                      .fromNow()}
                                </p>
                              </div>
                              <div
                                className="flex"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="flex flex-row text-left">
                                  <Link
                                    to={`/viewCar/${car._id}`}
                                    className="justify-center rounded-md px-4 text-white"
                                  >
                                    <svg
                                      width="15"
                                      height="13"
                                      viewBox="0 0 15 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.875 1.5V9H13.125V1.5H1.875ZM1.875 0.25H13.125C13.4565 0.25 13.7745 0.381696 14.0089 0.616116C14.2433 0.850537 14.375 1.16848 14.375 1.5V9C14.375 9.33125 14.2437 9.65 14.0063 9.88125C13.775 10.1187 13.4563 10.25 13.125 10.25H8.75V11.5H10V12.75H5V11.5H6.25V10.25H1.875C1.54375 10.25 1.225 10.1187 0.99375 9.88125C0.75625 9.65 0.625 9.33125 0.625 9V1.5C0.625 0.80625 1.18125 0.25 1.875 0.25ZM6.775 4.58125C6.96875 4.39375 7.23125 4.28125 7.5 4.28125C7.76875 4.2875 8.03125 4.39375 8.225 4.5875C8.4125 4.775 8.525 5.0375 8.525 5.30625C8.525 5.58125 8.4125 5.8375 8.225 6.03125C8.03125 6.225 7.76875 6.33125 7.5 6.33125C7.23125 6.33125 6.96875 6.21875 6.775 6.03125C6.5875 5.8375 6.475 5.58125 6.475 5.30625C6.475 5.0375 6.5875 4.775 6.775 4.58125ZM6.29375 6.5C6.44996 6.66227 6.63731 6.79135 6.84457 6.87952C7.05184 6.96768 7.27476 7.01313 7.5 7.01313C7.72524 7.01313 7.94816 6.96768 8.15543 6.87952C8.36269 6.79135 8.55004 6.66227 8.70625 6.5C9.025 6.1875 9.20625 5.75625 9.20625 5.30625C9.20625 4.85625 9.025 4.41875 8.70625 4.1C8.3875 3.78125 7.95 3.6 7.5 3.6C7.05 3.6 6.6125 3.78125 6.29375 4.1C5.975 4.41875 5.79375 4.85625 5.79375 5.30625C5.79375 5.75625 5.975 6.1875 6.29375 6.5ZM3.75 5.30625C4.04617 4.55293 4.56227 3.90621 5.23111 3.45029C5.89995 2.99436 6.69055 2.75034 7.5 2.75C9.20625 2.75 10.6625 3.8125 11.25 5.30625C10.9559 6.06102 10.4404 6.70913 9.77105 7.16538C9.10174 7.62162 8.31002 7.86465 7.5 7.8625C5.79375 7.8625 4.3375 6.8125 3.75 5.30625Z"
                                        fill="#4368B1"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <svg
                                  width="4"
                                  height="16"
                                  viewBox="0 0 4 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={(e) => openModal(e, car?._id)}
                                >
                                  <svg
                                    width="4"
                                    height="16"
                                    viewBox="0 0 4 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => openModal(e, car?._id)}
                                  >
                                    <path
                                      d="M2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                                      fill="#2E3A59"
                                    />
                                  </svg>
                                </svg>

                                {isModalOpen && (
                                  <div className="absolute inset-0 top-1/3 z-10 overflow-y-auto">
                                    <div
                                      className="fixed inset-0"
                                      onClick={closeModal}
                                    ></div>
                                    <div className="px-4 text-center bg-white0 m-10 drop-shadow-sm  ">
                                      <div className="flex bg-white max-w-md p-6 transition-all transform drop-shadow-sm rounded-lg divide-x  text-grey1 justify-center">
                                        <Link
                                          to={`/editCar/${selectedCarId}`}
                                          className="p-3 text-center text-[12px] rounded text-blue1"
                                        >
                                          <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 16 16"
                                            fill="#4368B1"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14.4688 13.6953H1.53125C1.22012 13.6953 0.96875 13.9467 0.96875 14.2578V14.8906C0.96875 14.968 1.03203 15.0312 1.10938 15.0312H14.8906C14.968 15.0312 15.0312 14.968 15.0312 14.8906V14.2578C15.0312 13.9467 14.7799 13.6953 14.4688 13.6953ZM3.52988 12.2188C3.56504 12.2188 3.6002 12.2152 3.63535 12.21L6.59199 11.6914C6.62715 11.6844 6.66055 11.6686 6.68516 11.6422L14.1365 4.19082C14.1528 4.17456 14.1657 4.15524 14.1746 4.13398C14.1834 4.11271 14.1879 4.08992 14.1879 4.06689C14.1879 4.04387 14.1834 4.02108 14.1746 3.99981C14.1657 3.97855 14.1528 3.95923 14.1365 3.94297L11.215 1.01973C11.1816 0.986328 11.1377 0.96875 11.0902 0.96875C11.0428 0.96875 10.9988 0.986328 10.9654 1.01973L3.51406 8.47109C3.4877 8.49746 3.47187 8.5291 3.46484 8.56426L2.94629 11.5209C2.92919 11.6151 2.9353 11.712 2.96409 11.8033C2.99288 11.8945 3.04349 11.9774 3.11152 12.0447C3.22754 12.1572 3.37344 12.2188 3.52988 12.2188Z"
                                              fill="#4368B1"
                                            />
                                          </svg>
                                        </Link>
                                        <div className="flex flex-row justify-center items-center space-x-3">
                                          <button
                                            className="text-center text-[12px] rounded text-blue1 pl-3"
                                            onClick={(e) =>
                                              handleDeleteAd(
                                                e,
                                                car._id,
                                                removeItem
                                              )
                                            }
                                          >
                                            <svg
                                              width="30"
                                              height="30"
                                              viewBox="0 0 16 16"
                                              fill="#4368B1"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M13.1875 3.5H10.9375V2.09375C10.9375 1.47324 10.433 0.96875 9.8125 0.96875H4.1875C3.56699 0.96875 3.0625 1.47324 3.0625 2.09375V3.5H0.8125C0.501367 3.5 0.25 3.75137 0.25 4.0625V4.625C0.25 4.70234 0.313281 4.76562 0.390625 4.76562H1.45234L1.88652 13.959C1.91465 14.5584 2.41035 15.0312 3.00977 15.0312H10.9902C11.5914 15.0312 12.0854 14.5602 12.1135 13.959L12.5477 4.76562H13.6094C13.6867 4.76562 13.75 4.70234 13.75 4.625V4.0625C13.75 3.75137 13.4986 3.5 13.1875 3.5ZM9.67188 3.5H4.32812V2.23438H9.67188V3.5Z"
                                                fill="#cd0f0f"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500">
                          <table className="ml-12 table">
                            {!singleAdsLoading ? (
                              <tbody>
                                <tr>
                                  <td className="px-5 py-2 text-grey1 font-bold min-w-[140px]">
                                    Modèle
                                  </td>
                                  <td className="font-semibold">
                                    {singleAd.make}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-5 py-2 text-grey1 font-bold min-w-[140px]">
                                    Année
                                  </td>
                                  <td className="md:px-5 md:py-2 font-semibold">
                                    {singleAd.overviewYear}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-5 py-2 text-grey1 font-bold min-w-[140px]">
                                    Propriétaire
                                  </td>
                                  <td className="md:px-5 md:py-2 font-semibold">
                                    {singleAd.ownerNumber}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-5 py-2 text-grey1 font-bold min-w-[140px]">
                                    Date
                                  </td>
                                  <td className="md:px-5 md:py-2 font-semibold">
                                    {formatDate(singleAd.createdAt)}{" "}
                                    <span className="text-[12px] leading-[16px] text-[#C5C7CD]">
                                      {getHoursMinutes(singleAd.createdAt)}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-5 py-2 text-grey1 font-bold min-w-[140px]">
                                    Prix
                                  </td>
                                  <td className="md:px-5 md:py-2 font-semibold">
                                    {singleAd.price}
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                <RiseLoader cssOverride={riseLoaderCss} />
                              </tbody>
                            )}
                          </table>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </div>
            );
          })
        )
      ) : (
        <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
      )}
    </div>
  );
};

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

const getHoursMinutes = (d) => {
  const date = new Date(d);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  return `${hours}:${minutes} ${period}`;
};
export default MobileCarDisplay;
