import React from "react";
import LandingStep from "../steps/LandingStep";
import OBD2ConnectStep from "../steps/OBD2ConnectStep";
import AccelerationTestStep from "../steps/AccelerationTestStep";
import UTurnTestStep from "../steps/UTurnTestStep";
import BrakeTestStep from "../steps/BrakeTestStep";
import PreviewReportStep from "../steps/PreviewReportStep";

export default function BaseStepper({
  stepAnalysis: activeStep,
  handleStepAnalysisNext: handleNext,
  invokeHelp: invokeHelp,
}) {
  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <LandingStep invokeHelp={invokeHelp} isOBD2Connected={false} />;
      case 1:
        return (
          <OBD2ConnectStep invokeHelp={invokeHelp} isOBD2Connected={false} />
        );
      case 2:
        return (
          <AccelerationTestStep
            handleNext={handleNext}
            invokeHelp={invokeHelp}
            isOBD2Connected={true}
          />
        );
      case 3:
        return (
          <UTurnTestStep
            handleNext={handleNext}
            invokeHelp={invokeHelp}
            isOBD2Connected={true}
          />
        );
      case 4:
        return (
          <BrakeTestStep
            handleNext={handleNext}
            invokeHelp={invokeHelp}
            isOBD2Connected={true}
          />
        );
      case 5:
        return <PreviewReportStep />;
      default:
        return <LandingStep invokeHelp={invokeHelp} isOBD2Connected={false} />;
    }
  };
  return <>{renderStep()}</>;
}
