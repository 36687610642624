import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getCurrentWeekLabels } from "../utils/function-helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function RotationStock() {
  const labels = getCurrentWeekLabels();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cette semaine",
        data: [22, 29, 13, 25, 12, 13, 15],
        backgroundColor: "#282F75",
        borderRadius: Number.MAX_VALUE,
      },
      {
        label: "Semaine derniere",
        data: [40, 43, 15, 29, 15, 15, 31],
        backgroundColor: "#EEF0FA",
        borderRadius: Number.MAX_VALUE,
      },
    ],
  };
  const options = {
    responsive: true,
    barPercentage: 0.3,
    plugins: {
      legend: {
        position: "top",
        align: "center",
        labels: {
          font: {
            size: 12,
          },
          padding: 5,
        },
      },
      tooltip: {
        enabled: true,
        events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
      },
    },
    interaction: {
      mode: false,
    },
    events: [],
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        borderRadius: 10,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="rounded-xl border border-grey2 bg-white p-5">
      <div className="mb-4 flex items-center justify-between">
        <p className="inset-0 flex items-start justify-start top-10 left-20 text-lg font-bold text-[#8B8D97]">
          Ratio de rotation des stocks
        </p>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block bg-transparent border-0 p-2.5"
          value={"semaine"}
        >
          <option value={"semaine"}>
            <p className="text-[#8B8D97]">{`Cette semaine`}</p>
          </option>
        </select>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
}

export default RotationStock;
