import React from "react";

function Road() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9123 10.6121L13.5439 0.589402C13.3886 0.232969 13.0501 0.00463867 12.6776 0.00463867H9.62796L9.70452 0.730034C9.72015 0.877869 9.60421 1.00691 9.45577 1.00691H8.54452C8.39608 1.00691 8.28015 0.877869 8.29577 0.730034L8.37233 0.00463867H5.32264C4.94983 0.00463867 4.61139 0.232969 4.45608 0.589402L0.0876424 10.6121C-0.20142 11.2758 0.25983 12.0319 0.954518 12.0319H7.10577L7.42796 8.97247C7.45483 8.71752 7.66921 8.52396 7.92515 8.52396H10.0751C10.3311 8.52396 10.5455 8.71752 10.5723 8.97247L10.8945 12.0319H17.0458C17.7405 12.0319 18.2017 11.2758 17.9123 10.6121ZM8.13765 2.23344C8.14413 2.17186 8.17314 2.11487 8.21907 2.07346C8.26501 2.03205 8.32461 2.00915 8.3864 2.00918H9.61421C9.74202 2.00918 9.84952 2.10597 9.86296 2.23344L10.0067 3.59841C10.0301 3.82048 9.8564 4.01373 9.6339 4.01373H8.36702C8.14421 4.01373 7.97077 3.82048 7.99421 3.59841L8.13765 2.23344ZM9.8639 7.52168H8.13608C7.83921 7.52168 7.60765 7.26391 7.6389 6.96793L7.79733 5.46452C7.82421 5.20957 8.03858 5.016 8.29452 5.016H9.70546C9.9614 5.016 10.1758 5.20957 10.2026 5.46452L10.3611 6.96793C10.3923 7.26391 10.1608 7.52168 9.8639 7.52168Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default Road;
