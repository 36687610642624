import React from "react";

function RappelIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 27.5C13.6193 27.5 12.5 26.3807 12.5 25H17.5C17.5 26.3807 16.3807 27.5 15 27.5ZM25 23.75H5V21.25L7.5 20V13.125C7.5 8.7975 9.27625 5.99125 12.5 5.225V2.5H17.5V5.225C20.7238 5.99 22.5 8.795 22.5 13.125V20L25 21.25V23.75ZM15 7.1875C13.4746 7.089 12.0035 7.7716 11.0938 9C10.3157 10.2307 9.93392 11.6705 10 13.125V21.25H20V13.125C20.066 11.6705 19.6842 10.2307 18.9062 9C17.9965 7.7716 16.5254 7.089 15 7.1875ZM16.25 18.75H13.75V16.25H11.25V13.75H13.75V11.25H16.25V13.75H18.75V16.25H16.25V18.75Z"
        fill="#2E3A59"
      />
    </svg>
  );
}

export default RappelIcon;
