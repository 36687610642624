import axios from "axios";
import * as type from "../subscriptions/actionTypes";
import Swal from "sweetalert2";

export const ClearStoreData = (payload) => async (dispatch) => {
  dispatch({
    type: "CLEARSTORE",
    payload: payload,
  });
};

export const getSubscriptions = (page, pageSize, search) => async (dispatch) => {
  const cancelTokenSource = axios.CancelToken.source();
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_AUTH_SERVICE}/api/subscriptions?pageSize=${pageSize}&page=${page}&search=${search}`,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
    cancelToken: cancelTokenSource.token,
  };
  try {
    const response = await axios(config).catch(function (error) {
      // to enhance
    });
    dispatch({
      type: type.GET_SUBSCRIPTIONS,
      subscriptions: response.data.subscriptions,
    });
    dispatch({
      type: type.GET_SUBSCRIPTIONS_COUNT,
      totalCount: response.data.total,
    });
  } catch (error) {
    Swal.fire(error.response || "Failed to fetch subscriptions");
  }
};