import { Fragment, useEffect, useRef, useState } from "react";
import "./index.css";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { useSelector } from "react-redux";
import carPlaceholder from "./../../../Assets/pictures/car-rectangle.png";

const Car3dViewer = () => {
  const car_id = useSelector((state) => state).cars.car._id;
  const user_id = useSelector((state) => state).profile.profile._id;
  const [inferenceImages, setInferenceImages] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [infererenceError, setInferenceError] = useState(0);
  const [canvasW, setCanvasW] = useState(0);
  const [canvasH, setCanvasH] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const canvasRef = useRef(null);
  const [prevX, setPrevX] = useState(0);

  const handleMouseDown = (event) => {
    setDragging(true);
    setStartX(event.clientX);
    canvasRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      const touchX = event.clientX;
      const delta = touchX - startX;
      const imageWidth = canvasW / inferenceImages.length;
      const swipeThreshold = imageWidth / 4;
      const isSwipingLeft = touchX < prevX;
      const isSwipingRight = touchX > prevX;
      if (Math.abs(delta) > swipeThreshold) {
        if (isSwipingLeft && currentImage < inferenceImages.length - 1) {
          setCurrentImage((prevImage) => prevImage + 1);
          setStartX(touchX);
          setPrevX(touchX);
          setPrevX(touchX);
        } else if (isSwipingRight && currentImage > 0) {
          setCurrentImage((prevImage) => prevImage - 1);
          setStartX(touchX);
          setPrevX(touchX);

          setPrevX(touchX);
        }
      }
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    setCurrentImage(prevX);
  };

  const handleClick = (event) => {
    const { width, left } = canvasRef.current.getBoundingClientRect();
    const clickedX = event.clientX - left;
    const imageWidth = width / inferenceImages.length;
    const clickedImage = Math.floor(clickedX / imageWidth);
    setCurrentImage(clickedImage);
  };

  const handleTouchStart = (event) => {
    setDragging(true);
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (dragging) {
      const touchX = event.touches[0].clientX;
      const delta = touchX - startX;
      const imageWidth = canvasW / inferenceImages.length;
      const swipeThreshold = imageWidth / 4;
      const isSwipingLeft = touchX < prevX;
      const isSwipingRight = touchX > prevX;
      if (Math.abs(delta) > swipeThreshold) {
        if (isSwipingLeft && currentImage < inferenceImages.length - 1) {
          setCurrentImage((prevImage) => prevImage + 1);
          setStartX(touchX);
          setPrevX(touchX);
        } else if (isSwipingRight && currentImage > 0) {
          setCurrentImage((prevImage) => prevImage - 1);
          setStartX(touchX);
          setPrevX(touchX);
        }
      }
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
    setCurrentImage(currentImage);
  };

  const handleTouch = (event) => {
    const { width, left } = canvasRef.current.getBoundingClientRect();
    const touch = event.touches[0];
    const touchedX = touch.clientX - left;
    const imageWidth = width / inferenceImages.length;
    const touchedImage = Math.floor(touchedX / imageWidth);
    setCurrentImage(touchedImage);
  };
  const getInferenceImages = async () => {
    let statusConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://app.dev.steerai.autos/3d/status",
      data: {
        user_id: user_id,
        car_id: car_id,
      },
    };
    let inferenceConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://app.dev.steerai.autos/3d/inference",
      data: {
        user_id: user_id,
        car_id: car_id,
      },
    };

    await axios
      .post(statusConfig)
      .then((response) => {
        if (response.data.data.inference_ready) {
          axios
            .post(inferenceConfig)
            .then((response) => {
              if (response.data.data != null) {
                setInferenceImages(response.data.data);
              } else {
                setInferenceError(true);
              }
            })
            .catch(() => {
              setInferenceError(true);
            });
        } else if (response.data == null) {
          setInferenceError(true);
        }
      })
      .catch((error) => {
        setInferenceError(true);
      });
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    getInferenceImages();
    const image = new Image();
    image.src = inferenceImages[currentImage];
    image.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height); // clear canvas
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
    const screen = window.outerWidth;
    let canvasWidth;
    if (isMobile) {
      canvasWidth = 250;
    } else if (screen < 768) {
      canvasWidth = 480;
    } else if (screen < 1024) {
      canvasWidth = 640;
    } else if (screen < 1280) {
      canvasWidth = 800;
    } else {
      canvasWidth = 1200;
    }
    setCanvasW(canvasWidth);
    setCanvasH(canvasWidth * 0.75);
    // redraw canvas content
    // eslint-disable-next-line
  }, [currentImage, inferenceImages]);

  return (
    <Fragment>
      {infererenceError ? (
        <div className="flex justify-center items-center">
          <img className="w-full" src={carPlaceholder} alt="car-placeholder" />
        </div>
      ) : (
        <div className="overflow-hidden">
          <canvas
            width={canvasW}
            height={canvasH - 200}
            ref={canvasRef}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
            onTouch={handleTouch}
          />
        </div>
      )}
    </Fragment>
  );
};
export default Car3dViewer;
