import SteerAiSwitcher from "../../Components/AgentAi/base/Handler";

const AgentIaLayout = ({ children, title }) => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between items-center mb-2 p-1">
        <p className="font-bold text-lg">{title}</p>
        <SteerAiSwitcher />
      </div>
      {children}
    </div>
  );
};

export default AgentIaLayout;
