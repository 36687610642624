import React from "react";

const CustomizedSteppers = ({ steps, activeStep }) => {
  return (
    <div className="w-full overflow-y-scroll xl:overflow-hidden flex justify-center mb-2">
      <div className="flex">
        {steps.map((step, index) => (
          <div key={index} className="flex items-start">
            <div className="flex items-start">
              <div className="flex flex-col items-center">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    index + 1 === activeStep
                      ? "border-4 border-[#00A76F] bg-[#EBF2FF]"
                      : index < activeStep || activeStep === steps.length
                      ? "border-2 border-blue1 bg-blue1"
                      : "border-2 border-disabledblue"
                  }`}
                >
                  <step.component
                    active={index < activeStep}
                    color={index + 1 < activeStep ? "white" : "#4368B1"}
                  />
                </div>
                <small
                  className={`mt-1 text-sm text-center ${
                    index < activeStep || activeStep === steps.length
                      ? "text-black"
                      : "text-disabledblue"
                  }`}
                >
                  {step.label}
                </small>
              </div>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`h-1 w-[5vw] mt-4 ${
                  index < activeStep - 1 || activeStep === steps.length
                    ? "bg-blue1"
                    : "bg-disabledblue"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomizedSteppers;
