import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import SimpleBarChart from "../../../chartjs/SimpleBarChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function RoadTestDetailed() {
  const labels = [
    "Test d'accélération",
    "Test du virage en U",
    "Test de freinage",
  ];
  const datasets = [
    {
      label: "-",
      data: [100, 82, 92],
      backgroundColor: ["#118D57", "#FFAB00", "#118D57"],
      borderRadius: Number.MAX_VALUE,
    },
  ];

  return (
    <div>
      <SimpleBarChart labels={labels} datasets={datasets} />
    </div>
  );
}

export default RoadTestDetailed;
