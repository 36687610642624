import React from "react";
import Select from "react-select";
import { components } from "react-select";
import { CarFunctionalities } from "../../../../Constants/cars";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="whitespace-nowrap">{props.label}</label>
      </components.Option>
    </div>
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="#292F70"
          d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4v4Zm1 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
const DropdownWithSearch = ({ value, onChange }) => {
  const handleChange = (selected) => {
    onChange(selected.map((item) => item.value));
  };

  const selectedOptions = value.map((key) =>
    CarFunctionalities.find((option) => option.value === key)
  );

  return (
    <div>
      <div>
        <Select
          hideSelectedOptions={false}
          isMulti
          value={selectedOptions}
          options={CarFunctionalities}
          onChange={handleChange}
          components={{
            Option,
            DropdownIndicator,
          }}
          closeMenuOnSelect={false}
          controlShouldRenderValue={false}
        />
      </div>
      <div className="flex flex-wrap">
        {(selectedOptions || []).map((item) => (
          <div
            className="px-6 py-2 mx-1 my-2 rounded-full bg-[#5482DE] text-white0"
            key={item.id}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownWithSearch;
