import React from "react";


const DataUploadIcon = ({ active, color }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    style={{ opacity: active ? 1 : 0.5 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_37_18796)">
      <path
        d="M13.4571 14.1667H4.2488C2.03481 14.168 0.190684 12.4694 0.0103915 10.2628C-0.169901 8.05612 1.37405 6.08071 3.55888 5.72262C4.56451 3.93967 6.4518 2.83582 8.4988 2.83329C9.77523 2.82847 11.0151 3.25925 12.0136 4.0545C12.9939 4.83215 13.691 5.91102 13.9969 7.12442C15.8272 7.40564 17.1318 9.04933 16.9903 10.8957C16.8488 12.742 15.3089 14.1676 13.4571 14.1667ZM8.4988 4.24999C6.96293 4.25181 5.54694 5.08024 4.7928 6.41821L4.4613 7.0125L3.78909 7.12229C2.33721 7.36553 1.31347 8.68013 1.43333 10.1474C1.55318 11.6146 2.77669 12.7456 4.2488 12.75H13.4571C14.5682 12.7511 15.4926 11.8962 15.578 10.7884C15.6635 9.6806 14.8811 8.69401 13.783 8.52479L12.8508 8.38312L12.6227 7.46796C12.1519 5.57445 10.45 4.24643 8.4988 4.24999ZM9.52588 11.3333H7.47172V9.20833H5.66547L8.4988 6.375L11.3321 9.20833H9.52588V11.3333Z"

        fill={color ?? "#282F75"}
      />
    </g>
    <defs>
      <clipPath id="clip0_37_18796">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DataUploadIcon;
