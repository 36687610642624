import React from "react";

function CallIcon({ height, width, color, active }) {
  return (
    <svg
      width={width ?? "25"}
      height={height ?? "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={active ? 1 : 0.5}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.55029 16.4499C11.5514 19.4511 14.339 21.1238 16.4131 22.0535C18.2244 22.8653 20.362 22.2282 22.0283 20.5619L22.4089 20.18L17.8308 17.2668C16.9608 18.5357 15.3152 19.2801 13.6722 18.5875C12.428 18.0618 10.7811 17.1633 9.30899 15.6912C7.8369 14.219 6.93966 12.5722 6.414 11.3279C5.72003 9.6849 6.4632 8.0393 7.73332 7.16924L4.8202 2.59108L4.43826 2.97173C2.77196 4.63805 2.13625 6.77565 2.94675 8.58697C3.87635 10.6611 5.54913 13.4487 8.55029 16.4499ZM15.3553 24.4163C13.0054 23.3637 9.95376 21.5149 6.71956 18.2806C3.48535 15.0477 1.63649 11.9947 0.582592 9.64477C-0.80923 6.53741 0.462183 3.28764 2.60753 1.14098L2.98818 0.759035C3.26233 0.484704 3.5946 0.275459 3.96045 0.146751C4.3263 0.0180428 4.71639 -0.0268403 5.10191 0.015416C5.48744 0.0576723 5.85855 0.185989 6.18783 0.390889C6.51712 0.59579 6.79618 0.872041 7.00439 1.19924L10.6684 6.96079C10.7655 7.11362 10.8294 7.28511 10.8561 7.46417C10.8827 7.64324 10.8715 7.8259 10.8232 8.00038C10.7749 8.17485 10.6905 8.33725 10.5756 8.4771C10.4606 8.61694 10.3176 8.73112 10.1557 8.81226L9.33618 9.22269C8.77686 9.50235 8.66293 9.99564 8.79887 10.3206C9.23778 11.3603 9.97189 12.6913 11.1397 13.8604C12.3089 15.0283 13.6398 15.7624 14.6795 16.2013C15.0045 16.3385 15.4977 16.2233 15.7774 15.664L16.1878 14.8444C16.2688 14.6825 16.3829 14.5393 16.5227 14.4242C16.6625 14.3091 16.8249 14.2246 16.9994 14.1761C17.1739 14.1277 17.3566 14.1164 17.5357 14.143C17.7148 14.1695 17.8864 14.2334 18.0393 14.3304L23.8008 17.9958C24.128 18.204 24.4042 18.4831 24.6091 18.8123C24.814 19.1416 24.9423 19.5127 24.9846 19.8983C25.0268 20.2838 24.982 20.6739 24.8533 21.0397C24.7245 21.4056 24.5153 21.7379 24.241 22.012L23.859 22.3927C21.7137 24.5393 18.4639 25.8095 15.3553 24.4163Z"
        fill={color ?? "#282F75"}
      />
    </svg>
  );
}

export default CallIcon;
