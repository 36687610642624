import React, { useState } from "react";
import NeedHelp from "../../needHelp/NeedHelp";
import AddIconTimesheetIcon from "../../stepper/timesheet/icons/AddIconTimesheet";
import WhatsappIcon from "../icons/WhatsappIcon";
import CallIcon from "../icons/CallIcon";
import AddPersonIcon from "../icons/AddPersonIcon";
import RappelIcon from "../icons/RappelIcon";
import CalendarIcon from "../icons/CalendarIcon";
import MessageIcon from "../icons/MessageIcon";
import EmailIcon from "../icons/EmailIcon";
import NoteIcon from "../icons/NoteIcon";
import AddSeriesIcon from "../icons/AddSeriesIcon";
import SubscribeSeriesIcon from "../icons/SubscribeSeriesIcon";
import MessengerIcon from "../icons/MessengerIcon";
import InstagramIcon from "../icons/InstagramIcon";
import SnapchatIcon from "../icons/SnapchatIcon";
import TiktokIcon from "../icons/TiktokIcon";
import SearchIcon from "../icons/SearchIcon";

function SaveActivity({
  handleOpenWhatsappModal,
  handleOpenLogCallModal,
  handleOpenSMSModal,
  handleOpenEmailModal,
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const sampleItems = [
    {
      icon: CallIcon,
      title: "Consigner un appel Lead",
      onclick: handleOpenLogCallModal,
    },
    { icon: AddPersonIcon, title: "Ajouter un Lead" },
    { icon: RappelIcon, title: "Ajouter un rappel" },
    { icon: CalendarIcon, title: "Programmer une tâche" },
    {
      icon: WhatsappIcon,
      title: "Archiver un appel Whatsapp",
      onclick: handleOpenWhatsappModal,
    },
    {
      icon: MessageIcon,
      title: "Archiver un SMS",
      onclick: handleOpenSMSModal,
    },
    {
      icon: EmailIcon,
      title: "Archiver un Email",
      onclick: handleOpenEmailModal,
    },
    { icon: NoteIcon, title: "Archiver une note" },
    { icon: AddSeriesIcon, title: "Créer une série" },
    { icon: CalendarIcon, title: "Archiver une réunion" },
    { icon: EmailIcon, title: "Archiver une visite" },
    { icon: SubscribeSeriesIcon, title: "S’inscrire dans une série" },
    { icon: MessengerIcon, title: "Archiver un messenger" },
    { icon: InstagramIcon, title: "Archiver un message Instagram" },
    { icon: SnapchatIcon, title: "Archiver un message Snapchat" },
    { icon: TiktokIcon, title: "Archiver un message Tiktok" },
  ];

  const filteredItems = searchQuery
    ? sampleItems.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : sampleItems;

  return (
    <div className="rounded bg-white border ">
      <NeedHelp />
      <div className="flex flex-col p-6">
        <div className="flex gap-2 items-center">
          <div className="rounded-full bg-darkblue1 h-8 w-8 flex items-center justify-center">
            <AddIconTimesheetIcon />
          </div>
          <h1 className="font-bold">Enregistrer une activité </h1>
        </div>
        <div>
          <div className="w-full flex justify-center items-center">
            <div className="bg-background flex items-center gap-3 p-2 rounded-xl border-grey3 border-2">
              <SearchIcon />
              <input
                className="border-none bg-transparent w-full focus:outline-none placeholder-black"
                placeholder="Rechercher une activité"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
              />
            </div>
          </div>
          <div className="w-full flex justify-center items-center mt-5">
            <div className="grid grid-cols-3 md:grid-cols-4 gap-10">
              {filteredItems.map((item, index) => (
                <div
                  key={index}
                  onClick={item.onclick}
                  className="relative cursor-pointer p-2 flex flex-col items-center justify-center w-[120px] h-[110px] border rounded-xl shadow-xl"
                >
                  {/* Badge for "Coming Soon" if there's no onclick */}
                  {!item.onclick && (
                    <div className="absolute bg-orange-500 text-darkblue1 text-tiny rounded-xl px-2 py-1/2 font-bold drop-shadow-l shadow-lg font-bold transform -rotate-[25deg] origin-top-left top-[15px] -left-[15px]">
                      Coming Soon
                    </div>
                  )}
                  <div className="text-3xl mb-2">{<item.icon active />}</div>
                  <div className="font-bold text-center text-sm">
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex justify-center items-center py-10">
            <div className="w-full md:w-[70%] xxl:w-[50%] mt-10 cursor-pointer bg-lightblue2 shadow-xl rounded-xl flex justify-center px-20 py-2 items-center text-white text-sm ">
              Voir les détails du véhicule
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveActivity;
