import React from "react";
import CarsDisplay from "../../carsDisplay";

function ChooseCarForMultiDiffusion({ handleChooseCars }) {
  return (
    <div>
      <CarsDisplay
        mode={"PICK_MULTIPLE"}
        handleChooseCar={handleChooseCars}
        customTitle={"Sélectionner un ou plusieurs véhicules"}
        customChooseButton={"Sélectionner"}
      />
    </div>
  );
}

export default ChooseCarForMultiDiffusion;
