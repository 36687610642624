import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { UserRole } from "../../Constants/users";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FeatureOptions } from "../../Constants/subscriptions";

export const SubscriptionDetailDialog = ({
  selectedSubscription,
  open,
  onClose,
}) => {
  const authUser = useSelector((state) => state.profile).profile;
  const isEditing = !!selectedSubscription;
  const [loading, setLoading] = useState(false);
  const [subscriptionForm, setSubscriptionForm] = useState({
    name: "",
    inspections: 0,
    features: [],
    price: 0,
  });

  const remainedOptions = FeatureOptions.filter((item) =>
    subscriptionForm.features?.every((feature) => feature.key !== item.value)
  );

  const handleChangeField = (e) => {
    setSubscriptionForm({
      ...subscriptionForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFeaturesOption = (index, option) => {
    setSubscriptionForm({
      ...subscriptionForm,
      features: subscriptionForm.features.map((item, i) =>
        i === index
          ? {
              key: option.value,
              name: option.label,
              price: item.price,
            }
          : item
      ),
    });
  };

  const handleChangeFeaturesPrice = (index, price) => {
    setSubscriptionForm({
      ...subscriptionForm,
      features: subscriptionForm.features.map((item, i) =>
        i === index
          ? {
              ...item,
              price: price,
            }
          : item
      ),
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const config = {
      method: isEditing ? "put" : "post",
      url: `${process.env.REACT_APP_AUTH_SERVICE}/api/subscriptions${
        isEditing ? `/${selectedSubscription._id}` : ""
      }`,
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
      data: subscriptionForm,
    };

    axios(config)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: "Succès!",
          text: isEditing
            ? "Subscription updated successfully!"
            : "Subscription created successfully",
          iconColor: "#4368B1",
          icon: "success",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "Finir",
        });
        onClose({ refresh: true });
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          text: isEditing
            ? "Failed to update subscription"
            : "Failed to create subscription",
          icon: "warning",
          iconColor: "#4368B1",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "quitter",
        });
        onClose();
      });
  };

  const handleAddFeature = () => {
    setSubscriptionForm({
      ...subscriptionForm,
      features: [
        ...(subscriptionForm.features || []),
        { key: "", name: "", price: 0 },
      ],
    });
  };

  const handleRemoveFeature = (index) => {
    setSubscriptionForm({
      ...subscriptionForm,
      features: subscriptionForm.features.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    if (selectedSubscription) {
      setSubscriptionForm({
        ...selectedSubscription,
      });
    }
  }, [selectedSubscription]);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg w-[580px] max-w-[580px] bg-white0 text-left shadow-xl transition-all sm:max-w-[580px] sm:my-8 sm:w-[580px] sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center w-full sm:mt-0 sm:ml-4 sm:text-left">
                        <h2 className="text-[24px] text-center font-bold pb-4 ">
                          {isEditing ? "Edit Subscription" : "Add Subscription"}
                        </h2>

                        <div className="flex flex-col gap-4">
                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">Name</p>
                            <input
                              disabled={authUser.role !== UserRole.SUPER_ADMIN}
                              value={subscriptionForm.name}
                              name="name"
                              className="flex-1 box box-border w-[300px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>

                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">
                              Inspections
                            </p>
                            <input
                              disabled={authUser.role !== UserRole.SUPER_ADMIN}
                              value={subscriptionForm.inspections}
                              name="inspections"
                              className="flex-1 box box-border w-[300px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>

                          <div className="flex gap-4">
                            <p className="w-[90px] flex-shrink-0 mt-2">
                              Features
                            </p>

                            <div className="flex flex-col flex-1 gap-2">
                              {subscriptionForm.features?.map(
                                (feature, index) => (
                                  <div
                                    key={index}
                                    className="w-full flex items-center gap-2"
                                  >
                                    <Select
                                      isDisabled={
                                        authUser.role !== UserRole.SUPER_ADMIN
                                      }
                                      className="flex-1 w-[120px]"
                                      value={{
                                        value: feature.key,
                                        label: feature.name,
                                      }}
                                      options={remainedOptions}
                                      onChange={(option) =>
                                        handleChangeFeaturesOption(
                                          index,
                                          option
                                        )
                                      }
                                      closeMenuOnSelect={false}
                                      placeholder=""
                                      isSearchable={false}
                                    />
                                    <input
                                      name="price"
                                      value={feature.price}
                                      className="w-[120px] box box-border h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                                      onChange={(e) =>
                                        handleChangeFeaturesPrice(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      className="inline-flex w-full justify-center rounded-md border border-blue1 bg-white px-4 py-2 text-base font-medium text-darkblue1 shadow-sm hover:bg-darkblue1 hover:text-white0 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                      onClick={() => handleRemoveFeature(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                )
                              )}
                              {!!(
                                remainedOptions.length > 0 &&
                                subscriptionForm.features.length < 4
                              ) && (
                                <button
                                  className="mt-3 inline-flex justify-center rounded-md bg-darkblue1 text-white0 px-4 py-2 text-base font-medium shadow-sm hover:bg-blue1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                  onClick={handleAddFeature}
                                >
                                  + Add
                                </button>
                              )}
                            </div>
                          </div>

                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">Price</p>
                            <input
                              disabled={authUser.role !== UserRole.SUPER_ADMIN}
                              value={subscriptionForm.price}
                              name="price"
                              className="flex-1 box box-border w-[300px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-darkblue1 text-white0 px-4 py-2 text-base font-medium shadow-sm hover:bg-blue1  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    >
                      Save
                      {loading && (
                        <ClipLoader className="ml-2" size={25} color="#fff" />
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-blue1 bg-white px-4 py-2 text-base font-medium text-darkblue1 shadow-sm hover:bg-darkblue1 hover:text-white0 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
