import TopoJsonMap from "../Map/TopoJsonMap";
import FranceMap from "../../config/france-topo.json";
import CardInformation from "../sales/dashboard/components/CardInformation";
import LabelSvg from "./svg/LabelSvg";
import React, { useState, useEffect } from "react";
import TextCarousel from "../TextCarousel/TextCarousel";

const logoLocations = [
  {
    name: "Paris",
    coordinates: [2.1, 49],
    brandUrl: "./car-brands/renault.png",
  },
  {
    name: "Lyon",
    coordinates: [4, 45.8],
    brandUrl: "./car-brands/peugeot.png",
  },
  {
    name: "Bordeaux",
    coordinates: [-0.1526, 45.5],
    brandUrl: "./car-brands/renault.png",
  },
  {
    name: "Marseille",
    coordinates: [5.6, 44.2],
    brandUrl: "./car-brands/tesla.png",
  },
  {
    name: "Ajaccio",
    coordinates: [8.7369, 42.626701],
    brandUrl: "./car-brands/citroen.png",
  },
  {
    name: "Toulouse",
    coordinates: [1.444, 43.9045],
    brandUrl: "./car-brands/vw.png",
  },
  {
    name: "Strasbourg",
    coordinates: [5.2, 49],
    brandUrl: "./car-brands/vw.png",
  },
  {
    name: "Dijon",
    coordinates: [4.3, 47.6],
    brandUrl: "./car-brands/vw.png",
  },
  {
    name: "Orléans",
    coordinates: [1.4, 47.7],
    brandUrl: "./car-brands/dacia.png",
  },
  {
    name: "Nantes",
    coordinates: [-1.2, 47.8],
    brandUrl: "./car-brands/nissan.png",
  },
  {
    name: "Lille",
    coordinates: [2.5, 50.4],
    brandUrl: "./car-brands/renault.png",
  },
  {
    name: "Rouen",
    coordinates: [-0.5, 49.25],
    brandUrl: "./car-brands/renault.png",
  },
  {
    name: "Rennes",
    coordinates: [-3.3, 48.5],
    brandUrl: "./car-brands/peugeot.png",
  },
];
const topBrands = [
  {
    name: "Renault",
    logo: "./car-brands/renault.png",
    percentage: "0",
  },
  {
    name: "Citroën",
    logo: "./car-brands/citroen.png",
    percentage: "+2",
  },
  {
    name: "VW",
    logo: "./car-brands/vw.png",
    percentage: "+1",
  },
  {
    name: "Dacia",
    logo: "./car-brands/dacia.png",
    percentage: "-2",
  },
  {
    name: "Nissan",
    logo: "./car-brands/nissan.png",
    percentage: "-1",
  },
];
const slides = [
  "Nouveau modèle BYD au Salon de l'Automobile de Paris 2024",
  "Le marché de l'occasion en France en hausse de 2.5% en 24",
  "Les voitures électriques en France : une croissance de 17% en 2024",
  "Les voitures autonomes : une révolution en marche",
];
function Insights() {
  const [selectedOption, setSelectedOption] = useState("semaine");

  const options = [
    { value: "semaine", label: "Par semaine" },
    { value: "mois", label: "Par mois" },
    { value: "annee", label: "Par année" },
  ];

  function getColorByValue(value) {
    const numericValue = parseFloat(value);

    switch (true) {
      case value.startsWith("-"):
        return "#D20000";
      case numericValue < 10:
        return "#519C66";
      case numericValue >= 10:
        return "#FFA500";
      default:
        return "#000000";
    }
  }
  return (
    <div className="overflow-scroll">
      <h1 className="font-bold text-xl mb-5 position-fixed">
        Analyses en France 🇫🇷
      </h1>
      <div className="flex flex-col md:flex-row gap-5 overflow-auto mt-2 p-5">
        <div className="flex flex-col gap-2 md:basis-4/6 flex-1">
          <div className="flex flex-col md:flex-row justify-between gap-2">
            <CardInformation
              label="Prix moyen des véhicules d'occasion"
              value="11 454€"
              subValue="+1.5%"
              subValueColor={getColorByValue("+1.5%")}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              customHeight
            />
            <CardInformation
              label="Voiture d'occasion la plus vendue"
              icon={LabelSvg}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              controller={
                <div className="flex justify-center items-center w-full">
                  <div className="flex items-center gap-4">
                    <img src={"./car-brands/renault.png"} alt="Renault logo" />
                    <p>Clio V</p>
                  </div>
                </div>
              }
              customHeight
            />
          </div>
          <CardInformation
            label="Tendances de recherche"
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            controller={
              <div className="flex justify-center items-center h-[400px] w-full pl-20">
                <TopoJsonMap pins={logoLocations} topoJson={FranceMap} />
              </div>
            }
            customHeight
          />
          <div className="h-[160px] w-full rounded-xl border border-grey2 bg-white p-5">
            <div className="flex w-full items-center justify-between">
              <small className="text-[#8B8D97] mb-1">Liens utils</small>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.41999 20.579C4.13948 20.5785 3.87206 20.4603 3.68299 20.253C3.49044 20.0475 3.39476 19.7695 3.41999 19.489L3.66499 16.795L14.983 5.48103L18.52 9.01703L7.20499 20.33L4.51099 20.575C4.47999 20.578 4.44899 20.579 4.41999 20.579ZM19.226 8.31003L15.69 4.77403L17.811 2.65303C17.9986 2.46525 18.2531 2.35974 18.5185 2.35974C18.7839 2.35974 19.0384 2.46525 19.226 2.65303L21.347 4.77403C21.5348 4.9616 21.6403 5.21612 21.6403 5.48153C21.6403 5.74694 21.5348 6.00146 21.347 6.18903L19.227 8.30903L19.226 8.31003Z"
                    fill="#2E3A59"
                  />
                </svg>
              </span>
            </div>
            <div className="flex w-full items-center justify-between px-5 py-8">
              <span className="cursor-pointer">
                <img className="h-[30px]" src="./marketplaces/leboncoin.png" />
              </span>
              <span className="cursor-pointer">
                <img className="h-[30px]" src="./marketplaces/lacentrale.png" />
              </span>
              <span className="cursor-pointer">
                <img
                  className="h-[30px]"
                  src="./marketplaces/lautomobile.png"
                />
              </span>
              <span className="cursor-pointer">
                <img className="h-[30px]" src="./marketplaces/scout24.png" />
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 md:basis-2/6 flex-1">
          <CardInformation
            label=""
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            controller={
              <div className="flex flex-col w-full">
                <TextCarousel slides={slides} />
                <p className="text-end mt-2 text-tiny italic text-grey">
                  Dernière mise à jour 10H
                </p>
              </div>
            }
            customHeight
          />
          <CardInformation
            label="Top 5 des marques de voitures"
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            controller={
              <div className="flex flex-col justify-center items-center w-full gap-5">
                {topBrands.map((brand, key) => (
                  <div
                    key={key}
                    className="flex items-center justify-between w-full"
                  >
                    <div className="flex items-center gap-5">
                      <p className="font-bold">
                        {key + 1} - {brand.name}
                      </p>
                      <p
                        className={`text-tiny text-[${
                          brand.percentage.startsWith("-")
                            ? "#D20000"
                            : "#00A76F"
                        }]`}
                      >
                        {brand.percentage}
                      </p>
                    </div>
                    <img
                      className="h-[25px] w-[35px]"
                      src={brand.logo}
                      alt={brand.name}
                    />
                  </div>
                ))}
              </div>
            }
            customHeight
          />

          <div className="h-[160px] w-full rounded-xl border border-grey2 bg-white p-5 flex items-center justify-center">
            <div className="flex flex-col items-center gap-4">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 20H2C0.89543 20 0 19.1046 0 18V6H2V18H14V20ZM18 16H6C4.89543 16 4 15.1046 4 14V2C4 0.89543 4.89543 0 6 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM6 2V14H18V2H6ZM13 12H11V9H8V7H11V4H13V7H16V9H13V12Z"
                  fill="#2E3A59"
                />
              </svg>

              <span className="text-[#8B8D97] mb-2">
                Ajouter une indicateur
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insights;
