import * as type from "./actionTypes";
const formInitState = {
  email: "",
};
const initialState = {
  users: [],
  totalCount: null,
  success: {},
  user: {},
};
const formReducer = (state = formInitState, action) => {
  switch (action.type) {
    case "INIT_FORM":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_FORM":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "RESET_FORM":
      return formInitState;
    default:
      return state;
  }
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_USERS:
      state = {
        ...state,
        users: action.users,
      };
      break;
    case type.GET_USER:
      state = {
        ...state,
        user: action.user,
      };
      break;
    case type.GET_USERS_COUNT:
      state = {
        ...state,
        totalCount: action.totalCount,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export { formReducer, users };
