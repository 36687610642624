import React, { useState } from "react";
import TimeSheet from "../stepper/timesheet/timesheet";
import CardDetailsIcon from "../stepper/horizentalStepper/icons/CarDetailsIcon";
import AddIconTimesheetIcon from "../stepper/timesheet/icons/AddIconTimesheet";
import ViewVehicle from "./steps/ViewVehicle";
import SaveActivity from "./steps/SaveActivity";
import WhatsappModal from "./modals/WhatsappModal";
import LogCallModal from "./modals/LogCallModal";
import SMSModal from "./modals/SMSModal";
import EmailModal from "./modals/EmailModal";
import CarsDisplay from "../carsDisplay";

function VehicleSheet() {
  const handleStepClick = (step) => () => {
    setTimeSheetStep(step);
  };
  const handleSeeDetails = () => {
    setIsLanding(false);
  };
  const [steps, setSteps] = useState([
    {
      label: "Véhicule ajouté",
      description: `Véhicule ajouté le 25 Octobre 2025 à 16:45`,
      icon: CardDetailsIcon,
      onStepClick: handleStepClick(0),
      withButton: true,
      buttonText: "Voir details",
      onButtonClick: () => handleSeeDetails,
    },
    {
      label: "Véhicule inspecté",
      description: `Véhicule inspecté le 25 Octobre 2025 à 16:45`,
      onStepClick: handleStepClick(1),
      withButton: false,
    },
    {
      label: "Enregistrer une activité",
      description: ``,
      icon: AddIconTimesheetIcon,
      onStepClick: handleStepClick(2),
      withButton: false,
    },
  ]);
  const [timeSheetStep, setTimeSheetStep] = useState(0);
  const [parentStep, setParentStep] = useState(0);
  const [isLanding, setIsLanding] = useState(true);
  const [isOpenWhatsappModal, setIsOpenWhatsappModal] = useState(false);
  const [isOpenLogCallModal, setIsOpenLogCallModal] = useState(false);
  const [isOpenSMSModal, setIsOpenSMSModal] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [chosenCar, setChosenCar] = useState({});

  const handleOpenWhatsappModal = () => {
    setIsOpenWhatsappModal(true);
  };
  const handleOpenLogCallModal = () => {
    setIsOpenLogCallModal(true);
  };
  const handleOpenSMSModal = () => {
    setIsOpenSMSModal(true);
  };
  const handleOpenEmailModal = () => {
    setIsOpenEmailModal(true);
  };
  const handleShareSheet = () => {
    //TODO Implement share sheet
    console.log("Share sheet");
  };

  const handleChooseCar = (car) => {
    setChosenCar(car);
    setParentStep(1);
  };
  const renderParent = () => {
    switch (parentStep) {
      case 0:
        return (
          <CarsDisplay
            customTitle={"Sélectionner un véhicule"}
            customChooseButton={"Ouvrir la fiche"}
            handleChooseCar={handleChooseCar}
            mode={"PICK_ONE"}
          />
        );
      case 1:
        return (
          <div className="flex flex-col xl:flex-row justify-between gap-8 overflow-scroll h-max">
            <div className="flex flex-col justify-between bg-white0 w-full h-full mx-auto xl:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3">
              <TimeSheet steps={steps} active={timeSheetStep} />
              <div
                onClick={handleShareSheet}
                className="fixed bottom-2 z-10 left-5 right-5 md:left-10 md:right-10 m-2 cursor-pointer bg-lightblue2 shadow-xl rounded-xl flex justify-center px-20 py-2 items-center text-white text-sm"
              >
                Partager la fiche
              </div>
            </div>
            <div className="max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col">
              {renderSteps()}
            </div>
          </div>
        );
      default:
        return (
          <CarsDisplay
            customTitle={"Ouvrir la fiche"}
            customChooseButton={"Sélectionner"}
          />
        );
    }
  };

  const renderSteps = () => {
    switch (timeSheetStep) {
      case 0:
        return <ViewVehicle landing={false} chosenCar={chosenCar} />;

      case steps.length - 1:
        return (
          <SaveActivity
            handleOpenWhatsappModal={handleOpenWhatsappModal}
            handleOpenLogCallModal={handleOpenLogCallModal}
            handleOpenSMSModal={handleOpenSMSModal}
            handleOpenEmailModal={handleOpenEmailModal}
          />
        );
      default:
        setTimeSheetStep(steps.length - 1);
        return;
    }
  };

  const handleCompleted =
    ({ icon, description, title }) =>
    () => {
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        const isDuplicate = newSteps.some((step) => step.label === title);

        if (!isDuplicate) {
          const newStep = {
            description,
            icon,
            label: title,
            onStepClick: handleStepClick(steps.length - 2),
            withButton: false,
          };

          newSteps.splice(steps.length - 1, 0, newStep);
        }

        return newSteps;
      });

      setIsOpenWhatsappModal(false);
      setIsOpenLogCallModal(false);
      setIsOpenSMSModal(false);
      setIsOpenEmailModal(false);
    };

  return (
    <div className="flex h-[100%] flex-col overflow-y-scroll">
      <div className="mb-2">
        <h1 className="font-bold text-xl mb-5 position-fixed">
          Ventes: Fiches véhicules
        </h1>
      </div>

      {renderParent()}
      <WhatsappModal
        isOpen={isOpenWhatsappModal}
        onClose={() => {
          setIsOpenWhatsappModal(false);
        }}
        handleCompleted={handleCompleted}
      />
      <LogCallModal
        isOpen={isOpenLogCallModal}
        onClose={() => {
          setIsOpenLogCallModal(false);
        }}
        handleCompleted={handleCompleted}
      />
      <SMSModal
        isOpen={isOpenSMSModal}
        onClose={() => {
          setIsOpenSMSModal(false);
        }}
        handleCompleted={handleCompleted}
      />
      <EmailModal
        isOpen={isOpenEmailModal}
        onClose={() => {
          setIsOpenEmailModal(false);
        }}
        handleCompleted={handleCompleted}
      />
    </div>
  );
}

export default VehicleSheet;
