import React from "react";


const CardDetailsIcon = ({ active, color }) => (

  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ opacity: active ? 1 : 0.5 }}
  >
    <path
      d="M1.33333 3.75L2.33333 0.9375H9.66667L10.6667 3.75M9.66667 6.875C9.40145 6.875 9.1471 6.77623 8.95956 6.60041C8.77202 6.4246 8.66667 6.18614 8.66667 5.9375C8.66667 5.68886 8.77202 5.4504 8.95956 5.27459C9.1471 5.09877 9.40145 5 9.66667 5C9.93188 5 10.1862 5.09877 10.3738 5.27459C10.5613 5.4504 10.6667 5.68886 10.6667 5.9375C10.6667 6.18614 10.5613 6.4246 10.3738 6.60041C10.1862 6.77623 9.93188 6.875 9.66667 6.875ZM2.33333 6.875C2.06812 6.875 1.81376 6.77623 1.62623 6.60041C1.43869 6.4246 1.33333 6.18614 1.33333 5.9375C1.33333 5.68886 1.43869 5.4504 1.62623 5.27459C1.81376 5.09877 2.06812 5 2.33333 5C2.59855 5 2.8529 5.09877 3.04044 5.27459C3.22798 5.4504 3.33333 5.68886 3.33333 5.9375C3.33333 6.18614 3.22798 6.4246 3.04044 6.60041C2.8529 6.77623 2.59855 6.875 2.33333 6.875ZM10.6133 0.625C10.48 0.2625 10.1067 0 9.66667 0H2.33333C1.89333 0 1.52 0.2625 1.38667 0.625L0 4.375V9.375C0 9.54076 0.0702379 9.69973 0.195262 9.81694C0.320286 9.93415 0.489856 10 0.666667 10H1.33333C1.51014 10 1.67971 9.93415 1.80474 9.81694C1.92976 9.69973 2 9.54076 2 9.375V8.75H10V9.375C10 9.54076 10.0702 9.69973 10.1953 9.81694C10.3203 9.93415 10.4899 10 10.6667 10H11.3333C11.5101 10 11.6797 9.93415 11.8047 9.81694C11.9298 9.69973 12 9.54076 12 9.375V4.375L10.6133 0.625Z"
      fill={color ?? "#003A8F"}
    />
  </svg>
);
export default CardDetailsIcon;
