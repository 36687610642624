import React, { Fragment } from "react";
import Insights from "../../Components/insights";

function DisplayInsights() {
  return (
    <Fragment>
      <Insights />
    </Fragment>
  );
}

export default DisplayInsights;
