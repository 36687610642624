import * as type from "./actionTypes";

const initialState = {
  subscriptions: [],
  totalCount: null,
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_SUBSCRIPTIONS:
      state = {
        ...state,
        subscriptions: action.subscriptions,
      };
      break;

    case type.GET_SUBSCRIPTIONS_COUNT:
      state = {
        ...state,
        totalCount: action.totalCount,
      };

    // eslint-disable-next-line no-fallthrough
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { subscriptions };
