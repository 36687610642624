import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import Select from "react-select";

const PAGE_SIZE = 10; // Number of items to fetch per page

export function CarSelect({ value, onChange, field, make }) {
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const unDuplicatedOptions = useMemo(() => {
    let result = [];
    if (options?.length) {
      options.forEach((option) => {
        if (!result.some((item) => item.value === option.value)) {
          result.push(option);
        }
      });
      return result;
    }
    return [];
  }, [options]);

  const loadMore = (event) => {
    const { target } = event;
    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleInputChange = (value) => {
    if (value) {
      setPage(1);
      setOptions([]);
    }
    setQuery(value);
  };

  const handleChange = (option) => {
    onChange(field, option.value);
  };

  const fetchData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_CAR_SERVICE}/api/car/${field}s`,
      {
        params: { page: page, pageSize: PAGE_SIZE, query: query, ...(field === 'model' && { make }) },
        headers: { "x-auth-token": `${localStorage.getItem("token")}` }
      });
    const data = response.data;
    const newOptions = data.list.map((item) => ({ value: item[field], label: item[field] }));
    const unDuplicatedOptions = newOptions.filter((item) => !options.some((option) => option.value === item.value));
    setOptions((prevOptions) => [...prevOptions, ...unDuplicatedOptions]);
  };

  useEffect(() => {
    // Fetch the initial page of data
    fetchData();
    // eslint-disable-next-line
  }, [page, query]);

  useEffect(() => {
    if (field === 'model') {
      onChange("");
      setOptions([]);
      fetchData();
    }
  }, [make]);

  return (
    <Select
      styles={{
        control: (provided) => ({
          ...provided,
          width: 205,
        })
      }}
      value={{ value: value, label: value }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={unDuplicatedOptions}
      onMenuScrollToBottom={loadMore}
    />
  );
}
