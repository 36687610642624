import * as types from "./actionTypes";

import axios from "axios";
import Swal from "sweetalert2";
import { logOutDispatch } from "../login/actions";
export const isLoading = (loading) => {
  return {
    type: types.IS_LOADING,
    payload: loading,
  };
};
// We implement the sendNotification here to ensure the firstName and lastName are available to be provided for the notification as arguments as the state is officially set.
const sendWelcomeNotif = async (fullName) => {
  var NotificationEventData = {
    eventType: "welcomeMsg",
    notifAdditionalInfo: fullName.firstName + " " + fullName.lastName,
  };
  var notificationConfig = {
    method: "post",
    url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/notify/`,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: NotificationEventData,
  };
  await axios(notificationConfig)
    .then(function (response) {
      // to enhance
    })
    .catch(function (error) {
      // to enhance
    });
};
export const profile = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    await axios
      .get(`${process.env.REACT_APP_AUTH_SERVICE}/api/auth`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (!response.data || response.data === "null") {
          localStorage.clear();
        } else {
          dispatch(profileSuccess(response.data));
        }
        dispatch(isLoading(false));
      })
      .catch((err) => {
        dispatch(isLoading(false));
        dispatch(failure(err));
      });
  };
};

export const updateProfile = (payload, id) => async (dispatch) => {
  /// sendWelcomeNotif() needs to be refactored (it needs to be in a services folder to be called as suitable everywhere)
  const sendWelcomeNotif = async () => {
    var NotificationEventData = {
      eventType: "passwordUpdated",
    };
    var notificationConfig = {
      method: "post",
      url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/notify/`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: NotificationEventData,
    };
    await axios(notificationConfig)
      .then(function (response) {
        // to enhance
      })
      .catch(function (error) {
        // to enhance
      });
  };
  dispatch(isLoading(true));
  await axios
    .put(
      `${process.env.REACT_APP_AUTH_SERVICE}/api/auth/update/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      dispatch(profileSuccess(response.data));
      sendWelcomeNotif();
      Swal.fire(
        "Terminé",
        "Votre profil a été modifié avec Succès !",
        "success"
      );
      dispatch(isLoading(false));
    })
    .catch(() => {
      dispatch(isLoading(false));
      Swal.fire({
        text: "Un probleme est survenue !",
        icon: "warning",
        iconColor: "#4368B1",
        confirmButtonColor: "#4368B1",
        confirmButtonText: "quitter",
      });
    });
};

export const profileSuccess = (data) => {
  return {
    type: types.PROFILE_SUCCESS,
    payload: data,
  };
};

export const failure = (error) => {
  return {
    type: types.PROFILE_ERROR,
    payload: error,
  };
};
