import * as React from "react";
import TickIcon from "../horizentalStepper/icons/TickIcon";
import BackButton from "../../backButton/BackButton";

export default function VerticalStepper({
  steps: steps,
  stepAnalysis: activeStep,
  handleNext: handleNext,
  handleBack: handleBack,
  goBack: goBack,
}) {
  return (
    <>
      <BackButton handleBack={goBack} />
      <div className="w-full max-w-md mx-auto mt-10 p-2 pb-20">
        <div className="relative">
          {steps.map((step, index) => (
            <div key={index} className="flex items-start mb-6 relative">
              {/* Vertical line */}
              {index < steps.length - 1 && (
                <div className="absolute left-4 top-8 h-full border-l-2 border-[#919EAB33] mt-1 mb-1" />
              )}
              {/* Step Circle */}
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full border-2 ${
                  index < activeStep || activeStep === steps.length - 1
                    ? "bg-active border-active text-white"
                    : index === activeStep
                    ? "bg-darkblue1 border-darkblue1 text-white"
                    : "bg-inactive border-inactive text-black"
                }`}
              >
                {index < activeStep || activeStep === steps.length - 1 ? (
                  <TickIcon />
                ) : index === 0 ? null : (
                  index
                )}
              </div>
              <div className="ml-10 flex-1">
                <div
                  className={`text-lg ${
                    index <= activeStep
                      ? "font-bold text-active1"
                      : "text-inactive1"
                  }`}
                >
                  {step.label}
                </div>
                {index === activeStep && (
                  <small className={`mt-1 text-sm text-inactive1-400`}>
                    {step.description}
                  </small>
                )}
                <div className="mt-2 flex space-x-2">
                  {step.withNext && index === activeStep && (
                    <button
                      onClick={handleNext}
                      className="px-2 py-1 bg-lightblue2 text-white rounded"
                    >
                      Continue
                    </button>
                  )}
                  {step.withBack && index === activeStep && (
                    <button
                      onClick={handleBack}
                      className="px-1 py-1 text-darkblue1 rounded"
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
