const Checkbox = ({ onChange, value, id }) => {
  return (
    <div className="flex items-center" key={id}>
      <input
        onChange={(e) => onChange(e)}
        value={value}
        checked={!!value}
        type="checkbox"
        className="m-4 form-check-input h-4 w-4 border   checked:bg-blue2 checked:border-blue2  cursor-pointer"
        id={id}
      />
    </div>
  );
};
export default Checkbox;
