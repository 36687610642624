import React, { useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const BarChart = ({
  data,
  positionLegend = "top",
  displayLegend = true,
  dataSet,
}) => {
  const chartRef = useRef(null);

  const labels = data.map((item) => item.label);

  const chartData = {
    labels: labels,
    datasets: dataSet.map((set, index) => ({
      label: set.label,
      data: data.map((item) => item.values[index]),
      backgroundColor: set.backgroundColor,
      borderColor: set.borderColor,
      borderWidth: 1,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend,
        position: positionLegend,
        labels: {
          boxWidth: 12,
          padding: 10,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    interaction: {
      intersect: false,
      mode: false,
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.update();
    }
  }, [data]);

  return (
    <Bar ref={chartRef} data={chartData} options={options} className="m-2" />
  );
};

export default BarChart;
