import React from "react";
import StepHeader from "../base/StepHeader";

function LandingStep({ invokeHelp, isOBD2Connected }) {
  return (
    <>
      <p className="font-bold text-[18px] mb-2">
        We will guide you through the process of collecting CAN data. Then
        you’ll be able to get report with accurate and detailed assessment of
        the car’s condition.
      </p>
      <div className="rounded bg-white flex-col">
        <StepHeader invokeHelp={invokeHelp} isOBD2Connected={isOBD2Connected} />
        <div className="relative rounded-b overflow-hidden">
          <span className="absolute top-5 left-1/2 transform -translate-x-1/2 p-4 text-darkblue1 text-center">
            Get Your OBD2 connecter ready and hop into the driver's seat
          </span>
          <img
            src="/steps/step1.png"
            alt="OBD2"
            className="w-full h-[700px] object-cover"
          />
        </div>
      </div>
    </>
  );
}

export default LandingStep;
