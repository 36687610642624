import React from "react";
import StepHeader from "../base/StepHeader";

function OBD2ConnectStep({ invokeHelp, isOBD2Connected }) {
  return (
    <>
      <div className="rounded bg-white flex-col">
        <StepHeader invokeHelp={invokeHelp} isOBD2Connected={isOBD2Connected} />
        <div className="w-full bg-gray-100 h-0.5 mb-2"></div>
        <div className=" rounded-b overflow-hidden">
          <div className="p-2">
            <p className="text-darkblue1 text-xl">
              Plug the OBD2 connector into the vehicle's port located under the
              driver's side of the dashboard.
            </p>
          </div>
          <img
            src="/steps/step2.png"
            alt="OBD2"
            className="w-full h-[700px] object-cover mt-2"
          />
        </div>
      </div>
    </>
  );
}

export default OBD2ConnectStep;
