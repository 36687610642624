import React from "react";

function BasketIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_114_1412)">
        <path
          d="M16.6663 18.3334C17.1266 18.3334 17.4997 17.9603 17.4997 17.5C17.4997 17.0398 17.1266 16.6667 16.6663 16.6667C16.2061 16.6667 15.833 17.0398 15.833 17.5C15.833 17.9603 16.2061 18.3334 16.6663 18.3334Z"
          stroke="#282F75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.50033 18.3334C7.96056 18.3334 8.33366 17.9603 8.33366 17.5C8.33366 17.0398 7.96056 16.6667 7.50033 16.6667C7.04009 16.6667 6.66699 17.0398 6.66699 17.5C6.66699 17.9603 7.04009 18.3334 7.50033 18.3334Z"
          stroke="#282F75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.833008 0.833313H4.16634L6.39967 11.9916C6.47588 12.3753 6.6846 12.7199 6.9893 12.9652C7.29399 13.2105 7.67526 13.3408 8.06634 13.3333H16.1663C16.5574 13.3408 16.9387 13.2105 17.2434 12.9652C17.5481 12.7199 17.7568 12.3753 17.833 11.9916L19.1663 4.99998H4.99967"
          stroke="#282F75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_1412">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BasketIcon;
