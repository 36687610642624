import { Fragment } from "react";
export function AddCarScreen(props) {
  return <Fragment>{props.addCar}</Fragment>;
}

export function EditCarScreen(props) {
  return <Fragment>{props.editCar}</Fragment>;
}

export function DisplayCars(props) {
  return <Fragment>{props.cars}</Fragment>;
}

export function AIToolsViewScreen(props) {
  return <Fragment>{props.aitools}</Fragment>;
}
