import React, { useEffect, useState } from "react";
import ConfigureCampaign from "./ConfigureCampaign";
import TargetPopulation from "./TargetPopulation";
import BudgetConfigure from "./BudgetConfigure";
import Insight from "./Insight";
import Published from "./Published";
import HeaderCampaignStepper from "./headerStepper";
import useMediaQuery from "../../../hooks/useMediaQuery";

function BaseStepper({
  activeStep,
  chosenConfiguration,
  handleOpenChooseConfigurationModal,
  handleGoToAnalytics,
}) {
  const isSmallerThanLarge = useMediaQuery(1280);
  const renderStepper = () => {
    switch (activeStep) {
      case 1:
        return <ConfigureCampaign />;
      case 2:
        return (
          <TargetPopulation
            chosenConfiguration={chosenConfiguration}
            handleOpenChooseConfigurationModal={
              handleOpenChooseConfigurationModal
            }
          />
        );
      case 3:
        return <BudgetConfigure />;
      case 4:
        return <Insight />;
      case 5:
        return <Published handleGoToAnalytics={handleGoToAnalytics} />;
      default:
        return <ConfigureCampaign />;
    }
  };
  return (
    <div>
      {!isSmallerThanLarge && <HeaderCampaignStepper activeStep={activeStep} />}
      <div className="rounded bg-white border pr-2">{renderStepper()}</div>
    </div>
  );
}

export default BaseStepper;
