import React from "react";

function CarPlateNumber({ plateNumber, countryCode }) {
  return (
    <div className="flex justify-between items-center border-2 border-grey5 rounded gap-5">
      <div>
        <img className="w-8" src="/flags/union-europe.png" />
        <div className="bg-[#003399] text-center text-white w-8">
          {countryCode ?? "F"}
        </div>
      </div>
      <h1 className="font-bold text-xl pr-4"> {plateNumber}</h1>
    </div>
  );
}

export default CarPlateNumber;
