import React from "react";

function CreatedIcon({ width, height }) {
  return (
    <svg
      width={width ?? "64"}
      height={height ?? "64"}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0002 63.6664C14.5191 63.6472 0.352694 49.4808 0.333496 31.9997V31.3664C0.681612 13.9641 15.0097 0.104926 32.414 0.335852C49.8182 0.566778 63.7735 14.8012 63.6598 32.2066C63.5461 49.6121 49.406 63.6629 32.0002 63.6664ZM17.4652 30.7014L13.0002 35.1664L25.6668 47.8331L51.0002 22.4997L46.5352 18.0031L25.6668 38.8714L17.4652 30.7014Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default CreatedIcon;
