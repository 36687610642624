import React from "react";

function AskIcon({ width, height }) {
  return (
    <svg
      width={width ?? "38"}
      height={height ?? "38"}
      viewBox="0 0 38 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 63.6665H12.6667V54.1665H22.1667V63.6665ZM22.1667 47.8332H12.6667V47.811C12.6667 42.586 12.6667 38.0735 14.7947 34.9132C16.4502 32.8149 18.5102 31.0705 20.8525 29.7832C21.9102 29.1055 22.9077 28.4627 23.7468 27.7945C27.4245 24.9479 29.0781 20.2006 27.9648 15.6852C26.2033 11.474 21.721 9.08452 17.2428 9.96925C12.7646 10.854 9.52778 14.7685 9.5 19.3332H0C0 8.83977 8.50659 0.333094 19 0.333094C26.3 0.311249 32.9759 4.44651 36.2077 10.9922C39.1105 17.6616 37.963 25.4018 33.25 30.9422C31.8171 32.5302 30.2087 33.9505 28.4557 35.176C26.9217 36.2341 25.5223 37.4751 24.2883 38.8715C22.5444 41.5142 21.7928 44.6891 22.1667 47.8332Z"
        fill="#5B85D8"
      />
    </svg>
  );
}

export default AskIcon;
