import Registration from "../Pages/Auth/register";
import ForgetPassword from "../Pages/Auth/forgetpwd";
import ResetPassword from "../Pages/Auth/resetpassword";
import VerifyAccount from "../Pages/Auth/verifyaccount";
import Auth from "../Pages/Auth/login";
import Home from "../Pages/Home";
import CarsDisplay from "../Components/carsDisplay";
import DisplayCars from "../Pages/Cars/displayCars";
import Notifications from "../Components/notifications";
import { NotificationsScreen } from "../Layout/Container/NotificationsScreen";
import AddMember from "../Components/addmember";
import { AddMemberScreen } from "../Layout/Container/AddMemberScreen";
import Member from "../Components/member";
import { MemberScreen } from "../Layout/Container/MemberScreen";
import CarForm from "../Components/car";
import {
  AddCarScreen,
  AIToolsViewScreen,
  EditCarScreen,
} from "../Layout/Container/CarsScreens";
import Profile from "../Pages/Profile";
import { ProfileScreen } from "../Layout/Container/Profile";
import AIToolsView from "../Components/AiTools";
import UserList from "../Pages/Users/list";
import { Navigate } from "react-router-dom";
import { UserRole } from "../Constants/users";
import SubscriptionsList from "../Pages/Subscriptions/list";
import DisplayMarketing from "../Pages/Marketing";
import DisplaySales from "../Pages/Sales";
import DisplayVehicleSheet from "../Pages/VehicleSheet";
import DisplayInsights from "../Pages/Insights";
import DisplayAnalytics from "../Pages/Analytics";
import Os from "../Pages/AgentAi/Os";
import Chat from "../Pages/AgentAi/Chat";
import ContinueChat from "../Pages/AgentAi/ContinueChat";

export const AUTH_ROUTES = [
  {
    path: "/",
    component: <Auth />,
  },
  {
    path: "/Registration",
    component: <Registration />,
  },
  {
    path: "/forgetpassword",
    component: <ForgetPassword />,
  },
  {
    path: "/change-password/:token",
    component: <ResetPassword />,
  },
  {
    path: "/verify-account/:token",
    component: <VerifyAccount />,
  },
];

export const MAIN_ROUTES = [
  {
    path: "/",
    component: <Home />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Overview",
  },
  {
    path: "/cars",
    component: <DisplayCars cars={<CarsDisplay />} />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Car table",
  },
  {
    path: "/verify-account",
    component: <VerifyAccount />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
  },
  {
    path: "/notifications",
    component: <NotificationsScreen notifications={<Notifications />} />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Notifications",
  },
  {
    path: "/addmember",
    component: <AddMemberScreen addmember={<AddMember />} />,
    roles: [UserRole.OWNER],
    title: "Member",
  },
  {
    path: "/member",
    component: <MemberScreen member={<Member />} />,
    roles: [UserRole.OWNER],
    title: "Member",
  },
  {
    path: "/addCar",
    component: <AddCarScreen addCar={<CarForm />} />,
    roles: [UserRole.OWNER],
    title: "Cars",
  },
  {
    path: "/editCar/:id",
    component: <EditCarScreen editCar={<CarForm isEdit />} />,
    roles: [UserRole.OWNER],
    title: "Cars",
  },
  {
    path: "/profile",
    component: <ProfileScreen profile={<Profile />} />,
    roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.SUPER_ADMIN],
    title: "Settings",
  },
  {
    path: "/viewCar/:id",
    component: <AIToolsViewScreen aitools={<AIToolsView />} />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Cars",
  },
  {
    path: "/",
    component: <Navigate to="/users" />,
    roles: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    path: "/users",
    component: <UserList />,
    roles: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
    title: "Users",
  },
  {
    path: "/subscriptions",
    component: <SubscriptionsList />,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
    title: "Subscriptions",
  },
  {
    path: "/marketing/multi-diffusion",
    component: <DisplayMarketing />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Marketing",
  },
  {
    path: "/sales/dashboard",
    component: <DisplaySales />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Sales",
  },
  {
    path: "/sales/vehicle-sheet",
    component: <DisplayVehicleSheet />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Vehicle sheet",
  },
  {
    path: "/insights",
    component: <DisplayInsights />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Insights",
  },
  {
    path: "/sales/analytics",
    component: <DisplayAnalytics />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Analytics",
  },
  {
    path: "/steer-ai",
    component: <Chat />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Steer AI chat",
  },
  {
    path: "/steer-ai/continue/:id",
    component: <ContinueChat />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Steer AI chat",
  },
  {
    path: "/steer-ai/os",
    component: <Os />,
    roles: [UserRole.OWNER, UserRole.MEMBER],
    title: "Steer AI os",
  },
];
