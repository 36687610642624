import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const TopoJsonMap = ({ topoJson, pins }) => {
  return (
    <ComposableMap
      projectionConfig={{
        scale: 3500,
        center: [5, 46.5],
      }}
      style={{ width: "100%", height: "100%" }}
    >
      <Geographies geography={topoJson}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <React.Fragment key={geo.rsmKey}>
              <Geography
                geography={geo}
                style={{
                  default: {
                    fill: "#DEE7F7",
                    stroke: "#282F75",
                    strokeWidth: 0.5,
                  },
                  hover: {
                    fill: "#F53",
                    stroke: "#282F75",
                    strokeWidth: 1.5,
                  },
                }}
              />
              {pins.map((location, index) => (
                <Marker key={index} coordinates={location.coordinates}>
                  <image href={location.brandUrl} height={40} width={40} />
                </Marker>
              ))}
            </React.Fragment>
          ))
        }
      </Geographies>
    </ComposableMap>
  );
};

export default TopoJsonMap;
