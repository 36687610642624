import { useState, createRef, useMemo } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import deleteIcon from "../../../Assets/coolicon.svg";
import { useSelector, useDispatch } from "react-redux";
import jose from "node-jose";
import {
  deleteCarDamageImage,
  uploadCarDamageImages,
  uploadFinishedCarDamageImages,
} from "../../../Redux/cars/actions";
import { Spinner } from "./subComponents/spinner";
import { DamagedImageSamples } from "../../../Constants/cars";
import "../style.css";

const DamageImagesUpload = ({ isEdit }) => {
  const dispatch = useDispatch();
  const carId = useSelector((state) => state).cars?.car?._id;
  const damagedCarImages = useSelector((state) => state).cars.damagedCarImages;

  const [fileName, setFileName] = useState("");
  const fileInputs = Array(50)
    .fill("")
    .map((a, index) => index + 1)
    .map((id) => createRef());
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseleave", Swal.close);
    },
  });
  const uploadedSuccessfully = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseleave", Swal.close);
    },
  });
  const selectFile = (id) => {
    fileInputs[id].current.click();
  };

  const list = useMemo(() => {
    return DamagedImageSamples.map((item) => {
      return {
        ...item,
        isLoading: !!damagedCarImages.find(
          (file) => file.filename === item.namingRule
        ),
        location: damagedCarImages.find(
          (file) => file.filename === item.namingRule
        )?.image_link,
      };
    }).sort((a, b) => a.id - b.id);
  }, [damagedCarImages]);
  const encryptJwt = (existingJwt, secret, callback) => {
    jose.JWK.asKey({
      k: jose.util.base64url.encode(secret),
      kty: "oct",
    }).then((jwtKey) => {
      jose.JWE.createEncrypt({ format: "compact" }, jwtKey)
        .update(existingJwt)
        .final()
        .then((result) => {
          callback(result);
        });
    });
  };
  function getFileExtensionFromMimeType(mimeType) {
    const mimeTypes = {
      "image/jpeg": ".jpg",
      "image/png": ".png",
      "image/gif": ".gif",
    };

    return mimeTypes[mimeType] || null;
  }

  const handleAddImages = async (evnt, filename) => {
    dispatch(uploadCarDamageImages(filename));

    let file = evnt.target.files[0];
    const fileExtension = getFileExtensionFromMimeType(file.type);
    let data = new FormData();
    data.append("image", file);
    data.append("fileName", filename);
    data.append("bucketName", process.env.REACT_APP_S3_CDD_BUCKET_NAME);

    let saveCarDamageImage = {
      method: "post",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/uploadDamagedCarImage/${carId}`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    };

    const ImagesUploadingAndProcessing = await axios(saveCarDamageImage).catch(
      (err) => console.log(err)
    );

    dispatch(
      uploadFinishedCarDamageImages(
        filename,
        ImagesUploadingAndProcessing.data.image_link
      )
    );

    uploadedSuccessfully.fire({
      icon: "success",
      title: `L'Image ${fileName} a éte chargée avec succée!`,
    });
  };

  const handleRemove = (el) => async (url) => {
    dispatch(deleteCarDamageImage(el.namingRule));
    const bucketParams = {
      Bucket: process.env.REACT_APP_S3_CDD_BUCKET_NAME,
      Delete: {
        Objects: [
          {
            Key: `${el.namingRule}.png`,
          },
          {
            Key: `${el.namingRule}.jpeg`,
          },
          {
            Key: `${el.namingRule}`,
          },
        ],
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_S3_SERVICE}/api/car/s3-service/deleteOne`,
        bucketParams
      )
      .then((res) =>
        Toast.fire({
          icon: "success",
          iconColor: "#4368B1",
          title: "Image supprimer avec Succès",
        })
      );
  };
  const getImage = (el) =>
    damagedCarImages[
      damagedCarImages.findIndex((item) => item.filename === el.namingRule)
    ]
      ? `${
          damagedCarImages[
            damagedCarImages.findIndex(
              (item) => item.filename === el.namingRule
            )
          ]?.image_link
        }?${global.Date.now()}`
      : null;
  return (
    <>
      {list.map((el, index) => (
        <div
          key={index}
          className=" box border  border-grey2  bg-grey3 rounded-md   flex justify-around items-center m-2 p-2 "
        >
          <div className="relative flex items-center w-[100px] bg-white0 border-none">
            {el.isLoading ? (
              !el.location ? (
                <div className="flex bg-lightblue1 h-[82px] w-full py-3 items-center space-x-3 rounded-md justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="relative group w-full h-[82px]">
                  <img
                    key={index}
                    src={el.location}
                    alt="uploaded-file"
                    className="object-cover tablet:w-[161px] w-full h-[82px]"
                  />
                  <button
                    className="hidden group-hover:block absolute right-1 top-1 bg-white0 p-1 rounded rounded-full"
                    onClick={handleRemove(el)}
                  >
                    <img src={deleteIcon} alt="delete-icon" />
                  </button>
                  <>
                    <input
                      name={index}
                      type="file"
                      ref={fileInputs[index]}
                      onChange={(event) =>
                        handleAddImages(event, el.namingRule)
                      }
                      className="hidden"
                      accept="image/jpeg,image/png"
                    />
                    <button
                      onClick={() =>
                        !el.isLoading && setFileName(el.namingRule)
                      }
                      className="absolute top-0 right-10 bottom-0 hover:bg-gray-400 text-gray-800 font-bold  rounded inline-flex items-center"
                    >
                      {!getImage(el) && (
                        <svg
                          width="26"
                          height="24"
                          viewBox="0 0 26 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => selectFile(index)}
                        >
                          <path
                            d="M3.3913 3.42857V0H5.65217V3.42857H9.04348V5.71429H5.65217V9.14286H3.3913V5.71429H0V3.42857H3.3913ZM6.78261 10.2857V6.85714H10.1739V3.42857H18.087L20.1557 5.71429H23.7391C24.9826 5.71429 26 6.74286 26 8V21.7143C26 22.9714 24.9826 24 23.7391 24H5.65217C4.4087 24 3.3913 22.9714 3.3913 21.7143V10.2857H6.78261ZM14.6957 20.5714C17.8157 20.5714 20.3478 18.0114 20.3478 14.8571C20.3478 11.7029 17.8157 9.14286 14.6957 9.14286C11.5757 9.14286 9.04348 11.7029 9.04348 14.8571C9.04348 18.0114 11.5757 20.5714 14.6957 20.5714ZM11.0783 14.8571C11.0783 16.88 12.6948 18.5143 14.6957 18.5143C16.6965 18.5143 18.313 16.88 18.313 14.8571C18.313 12.8343 16.6965 11.2 14.6957 11.2C12.6948 11.2 11.0783 12.8343 11.0783 14.8571Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </button>
                    <input
                      name={index}
                      type="file"
                      ref={fileInputs[index]}
                      onChange={(event) =>
                        handleAddImages(event, el.namingRule)
                      }
                      className="hidden"
                      accept="image/jpeg,image/png"
                    />
                  </>
                </div>
              )
            ) : (
              <>
                <img
                  key={index}
                  src={getImage(el)}
                  alt=""
                  className=" object-cover tablet:w-[161px] w-full h-[82px]"
                />
                <input
                  name={index}
                  type="file"
                  ref={fileInputs[index]}
                  onChange={(event) => handleAddImages(event, el.namingRule)}
                  className="hidden"
                  accept="image/jpeg,image/png"
                />
                <button
                  onClick={() => !el.isLoading && setFileName(el.namingRule)}
                  className="absolute  top-0 right-10 bottom-0  hover:bg-gray-400 text-gray-800 font-bold  rounded inline-flex items-center"
                >
                  <svg
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => selectFile(index)}
                  >
                    <path
                      d="M3.3913 3.42857V0H5.65217V3.42857H9.04348V5.71429H5.65217V9.14286H3.3913V5.71429H0V3.42857H3.3913ZM6.78261 10.2857V6.85714H10.1739V3.42857H18.087L20.1557 5.71429H23.7391C24.9826 5.71429 26 6.74286 26 8V21.7143C26 22.9714 24.9826 24 23.7391 24H5.65217C4.4087 24 3.3913 22.9714 3.3913 21.7143V10.2857H6.78261ZM14.6957 20.5714C17.8157 20.5714 20.3478 18.0114 20.3478 14.8571C20.3478 11.7029 17.8157 9.14286 14.6957 9.14286C11.5757 9.14286 9.04348 11.7029 9.04348 14.8571C9.04348 18.0114 11.5757 20.5714 14.6957 20.5714ZM11.0783 14.8571C11.0783 16.88 12.6948 18.5143 14.6957 18.5143C16.6965 18.5143 18.313 16.88 18.313 14.8571C18.313 12.8343 16.6965 11.2 14.6957 11.2C12.6948 11.2 11.0783 12.8343 11.0783 14.8571Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <input
                  name={index}
                  type="file"
                  ref={fileInputs[index]}
                  onChange={(event) => handleAddImages(event, el.namingRule)}
                  className="hidden"
                  accept="image/jpeg,image/png"
                />
              </>
            )}
          </div>

          <div className="grid grid-cols-1">
            <p className="text-[16px] font-bold">{el.namingRule}</p>
            <div className="flex justify-center">
              <img src={el.image} alt="damaged-part" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default DamageImagesUpload;
