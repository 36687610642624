import React, { useState } from "react";
import { useDnD } from "./DndContext";
import { ReactComponent as TriggerIcon } from "./../../../Assets/agent-ai/trigger.svg";
import { ReactComponent as ActionIcon } from "./../../../Assets/agent-ai/action.svg";
import { ReactComponent as IconIcon } from "./../../../Assets/agent-ai/icon.svg";
import { ReactComponent as SidebarIcon } from "./../../../Assets/agent-ai/sidebar.svg";
import { ReactComponent as TriggerSmall } from "./../../../Assets/agent-ai/trigger-small.svg";
import { ReactComponent as ActionSmallIcon } from "./../../../Assets/agent-ai/action-small.svg";
import { ReactComponent as ConditionSmallIcon } from "./../../../Assets/agent-ai/condition-small.svg";
import { ReactComponent as PriceEstimatorIconIcon } from "./../../../Assets/agent-ai/price-estimator.svg";
import { ReactComponent as DamageDetectorIcon } from "./../../../Assets/agent-ai/damage-detector.svg";
import FramerIcon from "../../../Assets/agent-ai/framer.svg";
import GmailImg from "../../../Assets/agent-ai/gmail.png";
import LeBonCoinIcon from "../../../Assets/agent-ai/leboncoin.svg";
import LaCentrale from "../../../Assets/agent-ai/lacentrale.svg";
import HubspotIcon from "../../../Assets/agent-ai/Hubspot.svg";
import SearchIcon from "../../vehicleSheet/icons/SearchIcon";

const items = {
  triggerItem: {
    id: "trigger",
    icon: TriggerIcon,
    executorPlaceholder: "Empty trigger",
    title: "Select trigger",
    options: [
      { id: 1, text: "Trigger 1" },
      { id: 2, text: "Trigger 2" },
    ],
  },
  actionItem: {
    id: "action",
    icon: ActionIcon,
    executorPlaceholder: "Empty trigger",
    title: "Select action",
    options: [
      { id: 1, text: "Action 1" },
      { id: 2, text: "Action 2" },
    ],
  },
  conditionItem: {
    id: "condition",
    icon: ConditionSmallIcon,
    executorPlaceholder: "Empty condition",
    title: "Select condition",
    options: [
      { id: 1, text: "Condition 1" },
      { id: 2, text: "Condition 2" },
    ],
  },
};

const executors = [
  {
    id: "car-damage",
    name: "Détection des défauts de carrosserie",
    annotation: "Véhicule",
    icon: DamageDetectorIcon,
    nodeType: "simpleNode",
    type: "tools",
  },
  {
    id: "price-estimator",
    name: "Estimateur de prix",
    annotation: "Véhicule",
    icon: PriceEstimatorIconIcon,
    nodeType: "simpleNode",
    type: "tools",
  },
  {
    id: "framer",
    name: "Framer",
    annotation: "Website builder",
    img: FramerIcon,
    nodeType: "simpleNode",
    type: "apps",
  },
  {
    id: "gmail",
    name: "Gmail",
    annotation: "Website builder",
    img: GmailImg,
    nodeType: "simpleNode",
    type: "apps",
  },
  {
    id: "leboncoin",
    name: "Leboncoin.com",
    annotation: "Déposer une annoncer",
    img: LeBonCoinIcon,
    nodeType: "simpleNode",
    type: "apps",
  },
  {
    id: "lacentrale",
    name: "Lacentrale.fr",
    annotation: "Déposer une annonce",
    img: LaCentrale,
    nodeType: "simpleNode",
    type: "apps",
  },
  {
    id: "hubspot",
    name: "Hubspot",
    annotation: "Ajouter une série Hubspot",
    img: HubspotIcon,
    nodeType: "simpleNode",
    type: "apps",
  },
];
export default () => {
  const [showSideBar, setShowSideBar] = useState(true);
  const { setType, setData } = useDnD();
  const [executorsState, setExecutorsState] = useState(executors);
  const [searchQuery, setSearchQuery] = useState();
  const [tab, setTab] = useState(0);

  const onDragStart = (event, nodeType, id, item = {}) => {
    setType(nodeType);
    setData({
      id,
      ...item,
    });
    event.dataTransfer.effectAllowed = "move";
  };

  const handleTabClick = (tabIndex) => {
    setTab(tabIndex);
    switch (tabIndex) {
      case 0:
        setExecutorsState(executors);
        break;
      case 1:
        setExecutorsState(
          executors.filter((executor) => executor.type === "apps")
        );

        break;
      case 2:
        setExecutorsState(
          executors.filter((executor) => executor.type === "tools")
        );
        break;

      default:
        break;
    }
  };
  const handleChangeSearchQuery = (e) => {
    const keyword = e.target.value;
    setExecutorsState(
      executorsState.filter(
        (executor) =>
          executor.annotation.toLowerCase().includes(keyword.toLowerCase()) ||
          executor.name.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };
  if (showSideBar) {
    return (
      <aside>
        <div className="flex flex-row justify-center w-full items-center mb-2">
          <IconIcon />
        </div>
        <SidebarIcon
          className="cursor-pointer"
          onClick={() => setShowSideBar((show) => !show)}
        />
        <div className="w-full flex flex-col mb-2">
          <p className="my-2 font-bold text-sm">Shortcuts</p>
          <div className="flex justify-between flex-wrap flex-grow">
            <div
              onDragStart={(event) =>
                onDragStart(
                  event,
                  "custom",
                  items.triggerItem.id,
                  items.triggerItem
                )
              }
              draggable
              className="rounded cursor-pointer bg-[#4368B1] px-1 py-2 flex flex-col items-center w-[90px] justify-center"
            >
              <TriggerSmall />
              <p className="text-white0 font-bold text-tiny">Trigger</p>
            </div>
            <div
              onDragStart={(event) =>
                onDragStart(
                  event,
                  "custom",
                  items.actionItem.id,
                  items.actionItem
                )
              }
              draggable
              className="rounded cursor-pointer bg-[#ECB22E] px-1 py-2 flex flex-col items-center w-[90px] justify-center"
            >
              <ActionSmallIcon />
              <p className="text-white0 font-bold text-tiny">Action</p>
            </div>
            <div
              onDragStart={(event) =>
                onDragStart(
                  event,
                  "custom",
                  items.conditionItem.id,
                  items.conditionItem
                )
              }
              draggable
              className="rounded cursor-pointer bg-[#5B85D8] px-1 py-2 flex flex-col items-center w-[90px] justify-center"
            >
              <ConditionSmallIcon />
              <p className="text-white0 font-bold text-tiny">Condition</p>
            </div>
          </div>
        </div>

        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li>
              <a
                href="#"
                onClick={() => handleTabClick(0)}
                className={`me-3 inline-block p-1 border-b-2 rounded-t-lg ${
                  tab === 0
                    ? "text-black border-black"
                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }`}
              >
                Recents
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => handleTabClick(1)}
                className={`me-3 inline-block p-1 border-b-2 rounded-t-lg ${
                  tab === 1
                    ? "text-black border-black"
                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }`}
              >
                Apps
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => handleTabClick(2)}
                className={`me-3 inline-block p-1 border-b-2 rounded-t-lg ${
                  tab === 2
                    ? "text-black border-black"
                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }`}
              >
                Tools
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div className="flex items-center gap-3 p-2 rounded-lg border-grey3 border-2 my-2">
            <SearchIcon />
            <input
              className="border-none bg-transparent w-full focus:outline-none placeholder-black"
              placeholder="Rechercher"
              value={searchQuery}
              onChange={handleChangeSearchQuery}
            />
          </div>
        </div>
        <div className="flex flex-col items-center h-[380px] overflow-auto cursor-pointer">
          {executorsState.map((executor) => (
            <div
              onDragStart={(event) =>
                onDragStart(event, "simple", executor.id, {
                  name: executor.name,
                  annotation: executor.annotation,
                  img: executor.img,
                  icon: executor.icon,
                  nodeType: executor.nodeType,
                })
              }
              draggable
              className="w-full border-2 border-grey3 px-2 py-2 rounded-lg mb-2 flex flex-row gap-2 items-center hover:bg-[#5B85D8]"
            >
              <div>
                {executor.icon && <executor.icon />}
                {executor.img && (
                  <img src={executor.img} className="w-[60px] h-[48px]" />
                )}
              </div>
              <div className="flex flex-col w-full">
                <p className="font-bold text-md"> {executor.name}</p>
                <p className="text-sm">{executor.annotation}</p>
              </div>
            </div>
          ))}
        </div>
      </aside>
    );
  } else {
    return (
      <div className="py-10 px-10">
        <SidebarIcon
          className="cursor-pointer"
          onClick={() => setShowSideBar((show) => !show)}
        />
      </div>
    );
  }
};
