import React from "react";

function Fuel() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H2.84211C3.10737 0 3.34421 0.10421 3.51474 0.274737L5.48526 2.25474L6.24316 1.50632C6.63158 1.13684 7.10526 0.947368 7.57895 0.947368H13.2632C13.7368 0.947368 14.2105 1.13684 14.5989 1.50632L15.5463 2.45368C15.9158 2.84211 16.1053 3.31579 16.1053 3.78947V16.1053C16.1053 16.6078 15.9056 17.0897 15.5503 17.445C15.195 17.8004 14.713 18 14.2105 18H4.73684C4.23433 18 3.75239 17.8004 3.39706 17.445C3.04173 17.0897 2.84211 16.6078 2.84211 16.1053V5.68421C2.84211 5.21053 3.03158 4.73684 3.40105 4.34842L4.14947 3.59053L2.45368 1.89474H0V0ZM7.57895 2.84211V4.73684H13.2632V2.84211H7.57895ZM7.96737 8.52632L6.07263 6.63158H4.73684V7.96737L6.63158 9.86211V12.8747L4.73684 14.7695V16.1053H6.07263L7.96737 14.2105H10.98L12.8747 16.1053H14.2105V14.7695L12.3158 12.8747V9.86211L14.2105 7.96737V6.63158H12.8747L10.98 8.52632H7.96737ZM8.52632 10.4211H10.4211V12.3158H8.52632V10.4211Z"
        fill="#282F75"
      />
    </svg>
  );
}

export default Fuel;
