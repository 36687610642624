import * as type from "./types";
const initialState = {
  notifAmount: 0,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case type.NOTIF_AMOUNT:
      return {
        notifAmount: action.notifAmount,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
