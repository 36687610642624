import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import Select from "react-select";
import {RoleOptions, UserRole} from "../../Constants/users";
import axios from "axios";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import ClipLoader  from "react-spinners/ClipLoader";

export const UserDetailDialog = ({
  selectedUser,
  open,
  onClose,
}) => {
  const authUser = useSelector((state) => state.profile).profile;
  const isEditing = !!selectedUser;
  const [loading, setLoading] = useState(false);
  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const handleChangeField = (e) => {
    setUserForm({
      ...userForm,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeRole = (option) => {
    setUserForm({
      ...userForm,
      role: option.value
    })
  };

  const handleSubmit = () => {
    setLoading(true);
    const config = {
      method: isEditing ? "put" : "post",
      url: `${process.env.REACT_APP_AUTH_SERVICE}/api/users${isEditing ? `/${selectedUser._id}` : ''}`,
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
      data: userForm,
    };

    axios(config)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: "Suprimer!",
          text: isEditing ? "User updated successfully!" : "User created successfully",
          iconColor: "#4368B1",
          icon: "success",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "Finir",
        });
        onClose({ refresh: true });
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          text: isEditing ? "Failed to update user" : "Failed to create user",
          icon: "warning",
          iconColor: "#4368B1",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "quitter",
        });
        onClose();
      })
  };

  useEffect(() => {
    setUserForm({
      ...selectedUser
    });
  }, [selectedUser]);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white0 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center w-full sm:mt-0 sm:ml-4 sm:text-left">
                        <h2 className="text-[24px] text-center font-bold pb-4 ">
                          {isEditing ? 'Edit User' : 'Add User'}
                        </h2>

                        <div className="flex flex-col gap-4">
                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">First Name</p>
                            <input
                              disabled={authUser.role !== UserRole.SUPER_ADMIN}
                              value={userForm.firstName}
                              name="firstName"
                              className="flex-1 box box-border w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>

                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">Last Name</p>
                            <input
                              disabled={authUser.role !== UserRole.SUPER_ADMIN}
                              value={userForm.lastName}
                              name="lastName"
                              className="flex-1 box box-border w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>

                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">Email</p>
                            <input
                              disabled={!!isEditing || authUser.role !== UserRole.SUPER_ADMIN}
                              value={userForm.email}
                              name="email"
                              className="flex-1 box box-border w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
                              onChange={handleChangeField}
                            />
                          </div>

                          <div className="flex items-center gap-4">
                            <p className="w-[90px] flex-shrink-0">Role</p>
                            <Select
                              isDisabled={authUser.role !== UserRole.SUPER_ADMIN}
                              className="w-full"
                              value={userForm.role && RoleOptions.find((option) => option.value === userForm.role)}
                              options={RoleOptions.filter((role) => role.value !== UserRole.MEMBER)}
                              onChange={handleChangeRole}
                              closeMenuOnSelect={false}
                              placeholder=''
                              isSearchable={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-darkblue1 text-white0 px-4 py-2 text-base font-medium shadow-sm hover:bg-blue1  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    >
                      Save
                      {loading && <ClipLoader className="ml-2" size={25} color="#fff" />}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-blue1 bg-white px-4 py-2 text-base font-medium text-darkblue1 shadow-sm hover:bg-darkblue1 hover:text-white0 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};