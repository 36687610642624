import React from "react";

function WalletIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1389 14.3958H17.0906C15.6042 14.3949 14.3993 13.191 14.3984 11.7045C14.3984 10.2181 15.6042 9.01416 17.0906 9.01324H21.1389"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.549 11.6429H17.2373"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.24766 3H15.8911C18.7892 3 21.1388 5.34951 21.1388 8.24766V15.4247C21.1388 18.3229 18.7892 20.6724 15.8911 20.6724H7.24766C4.34951 20.6724 2 18.3229 2 15.4247V8.24766C2 5.34951 4.34951 3 7.24766 3Z"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.53516 7.53814H11.9341"
        stroke="#282F75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default WalletIcon;
