import React from "react";
import ImageRenderer from "./Image";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { ReactComponent as ChatIcon } from "../../../Assets/agent-ai/chat.svg";

function Message({ message, type }) {
  const state = useSelector((state) => state?.profile).profile;
  const firstName = state?.firstName;
  const lastName = state?.lastName;
  const pseudo = `${firstName?.charAt(0).toUpperCase()}${lastName
    ?.charAt(0)
    .toUpperCase()}`;
  if (type === "simple") {
    return (
      <div className={`flex ${message.me ? "justify-end" : "justify-start"}`}>
        <div
          className={`w-[95%] flex flex-row min-w-[500px] items-start gap-2`}
        >
          <div className="mt-10 rounded-full bg-white0 px-2 py-1 border-2 border-[#CBD5E1] ">
            {pseudo}
          </div>
          <div className="flex flex-col gap-1 flex-1">
            {message.me && <p className="font-bold">You</p>}

            <div className="p-2 rounded-lg border-2 border-lightblue1 bg-[#E9F0FF]">
              <div className="flex flex-col w-full">
                <p className="ml-2">{message.message}</p>
                <div>
                  {message.files.map((file) => (
                    <div key={file.id} className="flex items-center">
                      <ImageRenderer image={file} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-row justify-end">
                <p className="text-tiny">{message.time}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (type === "html") {
    return (
      <div className={`flex justify-start`}>
        <div
          className={`w-[95%] flex flex-row min-w-[500px] items-start gap-2`}
        >
          <span
            className={
              "cursor-pointer bg-white0 border-2 border-[#CBD5E1] p-2 rounded-full"
            }
          >
            <ChatIcon />
          </span>
          <div className="flex flex-col gap-1 flex-1">
            <div className="p-2 rounded-lg border-2 border-lightblue1 bg-[#E9F0FF]">
              <div className="flex flex-col w-full">
                <div> {parse(DOMPurify.sanitize(message.message))}</div>
              </div>
              <div className="flex flex-row justify-end">
                <p className="text-tiny">{message.time}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div>Censored</div>;
}

export default Message;
