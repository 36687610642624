import { useState, createRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import FadeLoader from "react-spinners/FadeLoader";
import photo from "../../../../Assets/pictures/photo.png";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../../Assets/coolicon.svg";

const ImagesOverview = () => {
  const riseLoaderCss = {
    position: "relative",
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile).profile;
  const overviewImgs = useSelector((state) => state).formReducer.overviewImgs;
  const adNumber = useSelector((state) => state).formReducer.adNumber;
  const [count, setCount] = useState(1);
  const [BoxPhotoSize, setBoxPhotoSize] = useState(0);
  const fileInput = createRef();
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState("");
  const selectFile = () => {
    fileInput.current.click();
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleMultipleImages = async (evnt) => {
    var file = evnt.target.files[0];
    setCount(count + 1);
    let data = new FormData();
    data.append("image", file);
    const location =
      user._id +
      "/" +
      "overview" +
      "/" +
      adNumber +
      "/" +
      fileName +
      "-" +
      +new Date();
    data.append("customFileName", location);
    data.append("bucketName", process.env.REACT_APP_S3_CARS_OVERVIEW_IMAGES);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/s3-service/uploadOne`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    };
    setIsUploading(true);
    const uploadImage = await axios(config).catch((err) => {
      Toast.fire({
        icon: "error",
        iconColor: "#4368B1",
        title: "Erreur de chargement de l'image",
      });
    });
    setIsUploading(false);
    Toast.fire({
      icon: "success",
      iconColor: "#4368B1",
      title: "Image Téléchargé avec Succès",
    });
    dispatch({
      type: "OVERVIEW_IMGS",
      payload: { url: uploadImage.data.Location, filename: fileName },
    });

    setBoxPhotoSize(1);
  };

  const handleRemove = (name) => () => {
    const image = overviewImgs.find((a) => a.filename === name);
    const { filename } = image;
    const bucketParams = {
      Bucket: process.env.REACT_APP_S3_CARS_OVERVIEW_IMAGES,
      Delete: {
        Objects: [
          {
            Key: `${filename}.png`,
          },
          {
            Key: `${filename}.jpeg`,
          },
          {
            Key: `${filename}`,
          },
        ],
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_S3_SERVICE}/api/s3-service/deleteOne`,
        bucketParams
      )
      .then((res) =>
        Toast.fire({
          icon: "success",
          iconColor: "#4368B1",
          title: "Image supprimer avec Succès",
        })
      );

    dispatch({
      type: "REMOVE_IMG",
      payload: filename,
    });
  };

  return (
    <div className="grid gap-4 grid-cols-2">
      {(overviewImgs || []).map((e, index) => (
        <div
          key={index}
          className="relative flex space-x-2 flex-1 flex justify-center"
        >
          <img
            src={e.url}
            alt="..."
            className="object-cover w-full h-[170px]"
          />
          <button
            className="absolute right-1 top-1 bg-white0 p-1 rounded rounded-full w-[20px] h-[20px] flex justify-center items-center"
            onClick={handleRemove(e.filename)}
          >
            <img src={deleteIcon} alt="delete-icon" />
          </button>
        </div>
      ))}
      {!overviewImgs?.length ? (
        <div
          onClick={() => setFileName(`dd0${count}_es00_is00`)}
          className="col-span-2 flex-1 box box-border w-full h-[170px] border-2 p-3 border-[#D2D8FF] space-y-4 rounded-lg flex flex-col justify-center items-center "
        >
          <button onClick={selectFile}>
            <div className="flex justify-center">
              {isUploading ? (
                <div className="box box-border w-full h-[170px] space-y-4 rounded-lg flex flex-col justify-center items-center">
                  <div className="flex flex-col flex-wrap justify-center items-center ">
                    <FadeLoader cssOverride={riseLoaderCss}></FadeLoader>
                  </div>
                </div>
              ) : (
                <div
                  className="box box-border w-full h-[170px] space-y-4 rounded-lg flex flex-col justify-center items-center "
                  onClick={() => setFileName(`dd0${count}_es00_is00`)}
                >
                  <div className="flex flex-col flex-wrap justify-center items-center ">
                    <input
                      type="file"
                      ref={fileInput}
                      onChange={handleMultipleImages}
                      className="invisible h-0"
                      accept="image/jpeg,image/png"
                    />

                    <img src={photo} alt="" className="w-[26px] h-[24px]" />
                  </div>
                  <p className="text-[16px] font-bold">Ajouter une Photo</p>
                </div>
              )}
            </div>
          </button>
        </div>
      ) : isUploading ? (
        <div className="box box-border w-full h-[170px] rounded-lg flex flex-col justify-center items-center">
          <div className="flex justify-center items-center ">
            <FadeLoader cssOverride={riseLoaderCss}></FadeLoader>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setFileName(`dd0${count}_es00_is00`)}
          className="w-full flex-1 box box-border w-full h-[170px] border-2 border-[#D2D8FF] space-y-4 rounded-lg flex flex-col justify-center items-center "
        >
          <button onClick={selectFile}>
            <div className="flex flex-col flex-wrap justify-center items-center ">
              <input
                type="file"
                ref={fileInput}
                onChange={handleMultipleImages}
                className="invisible h-0"
                accept="image/jpeg"
              />
              <button onClick={() => setFileName(`dd0${count}_es00_is00`)}>
                <img src={photo} alt="" className="w-[26px] h-[24px]" />
              </button>
              <p className="text-[16px] font-bold">Ajouter une Photo</p>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default ImagesOverview;
