import React from "react";

function DisconnectedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99692 20C7.34485 20.0024 4.8007 18.9496 2.9254 17.0739C1.05009 15.1981 -0.00238199 12.6533 4.04814e-06 10.0006V9.80059C0.0817669 5.79305 2.54702 2.22119 6.26443 0.724184C9.98185 -0.772822 14.2337 0.0940544 17.0687 2.927C19.9303 5.78691 20.7868 10.0899 19.2384 13.828C17.6901 17.5661 14.0421 20.0024 9.99692 20ZM9.99693 11.4105L12.5861 14.0003L13.9957 12.5904L11.4065 10.0006L13.9957 7.41073L12.5861 6.00082L9.99693 8.59066L7.40772 6.00082L5.99816 7.41073L8.58736 10.0006L5.99816 12.5904L7.40772 14.0003L9.99693 11.4115V11.4105Z"
        fill="#F8A527"
      />
    </svg>
  );
}

export default DisconnectedIcon;
