export function getCurrentWeekLabels() {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);

  const labels = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    labels.push(`${month.charAt(0).toUpperCase() + month.slice(1)} ${day}`);
  }

  return labels;
}
