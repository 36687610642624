import React from "react";
function TextChooser({ chosenText, setChosenText, texts, setTexts }) {
  return (
    <div className="flex flex-col flex-shrink">
      <div className="border rounded border-grey3 flex justify-between items-center p-2">
        <input
          type="text"
          className="border-0 outline-none"
          value={chosenText}
          onChange={({ target }) => setChosenText(target.value)}
        />
        <div
          onClick={() =>
            setTexts((prevLocations) => [...prevLocations, chosenText])
          }
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 0.8125C3.35918 0.8125 0.8125 3.35918 0.8125 6.5C0.8125 9.64082 3.35918 12.1875 6.5 12.1875C9.64082 12.1875 12.1875 9.64082 12.1875 6.5C12.1875 3.35918 9.64082 0.8125 6.5 0.8125ZM8.9375 6.80469C8.9375 6.86055 8.8918 6.90625 8.83594 6.90625H6.90625V8.83594C6.90625 8.8918 6.86055 8.9375 6.80469 8.9375H6.19531C6.13945 8.9375 6.09375 8.8918 6.09375 8.83594V6.90625H4.16406C4.1082 6.90625 4.0625 6.86055 4.0625 6.80469V6.19531C4.0625 6.13945 4.1082 6.09375 4.16406 6.09375H6.09375V4.16406C6.09375 4.1082 6.13945 4.0625 6.19531 4.0625H6.80469C6.86055 4.0625 6.90625 4.1082 6.90625 4.16406V6.09375H8.83594C8.8918 6.09375 8.9375 6.13945 8.9375 6.19531V6.80469Z"
              fill="#282F75"
            />
          </svg>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-5 mb-5">
        {texts.map((location) => (
          <div
            className="rounded-xl text-center flex justify-around items-center bg-blue1 cursor-pointer py-1 px-2"
            onClick={() => setChosenText(location)}
          >
            <small className="text-white">{location}</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TextChooser;
