import { useState, useEffect } from "react";
import parse from "html-react-parser";
import React from "react";
import axios from "axios";
import moment from "moment";
import emptySign from "../../Assets/emptySign.svg";
import frLocale from "moment/locale/fr";
import { notifAmountAction } from "../../Redux/notification/actions";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Spinner } from "../car/common/subComponents/spinner";
import BottomNav from "./subComponents/bottomNab";
const NotificationMobileVersion = ({ ...notifs }) => {
  return (
    <div className=" md:hidden h-[calc(100%-25px)] overflow-y-scroll ">
      {notifs.notifs.length ? (
        notifs.notifs.map((notification, index) => {
          return (
            <div key={index} className="flex flex-col  p-2">
              <p className="text-[16px] text-black1">
                {parse(notification.body)}
              </p>
              <p className="text-[12px] whitespace-no-wrap text-grey1">
                {"Crée " +
                  moment(notification.createdAt).endOf("today").fromNow()}
              </p>
            </div>
          );
        })
      ) : (
        <div className="lg:flex p-10">
          <div className="flex flex-col justify-center items-center">
            <img src={emptySign} alt="empty-sign" />
            <p className="text-[18px] font-bold">Pas encore de Notifications</p>
          </div>
        </div>
      )}
    </div>
  );
};
const NotificationWebVersion = ({ ...notifs }) => {
  return (
    <div className="md:flex hidden max-h-[calc(100%-25px)] overflow-y-scroll ">
      {notifs.notifs.length ? (
        <table className=" leading-normal ">
          <thead>
            <tr>
              <th className="px-10 py-3 text-left  font-bold text-[19px]">
                Date
              </th>
              <th className="px-20 py-3 text-left  font-bold text-[19px]">
                Notifications
              </th>
            </tr>
          </thead>
          <tbody>
            {notifs.notifs.map((notification) => {
              return (
                <tr key={notification._id} className="   text-black1">
                  <td className="px-10 py-5  text-sm ">
                    <p className=" whitespace-no-wrap  text-black1">
                      {"Crée " +
                        moment(notification.createdAt)
                          .startOf("hour")
                          .fromNow()}
                    </p>
                  </td>
                  <td className="px-20   text-sm ">
                    <p className="whitespace-no-wrap   text-black1">
                      {parse(notification.body)}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col justify-center items-center p-10 w-full ">
          <img src={emptySign} alt="" />
          <p className="text-[18px] font-bold">Pas de Notifications</p>
        </div>
      )}
    </div>
  );
};
const Notifications = () => {
  moment.locale("fr", [frLocale]);
  const [notifications, setNotifs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNotifCount, setTotalNotifCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  async function getNotifications(pageSize, page) {
    try {
      var getNotificationsConfig = {
        method: "get",
        url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/getUserNotifications/?pageSize=${pageSize}&page=${page}`,
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      };
      var NotificationReadConfig = {
        method: "post",
        url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/notifRead`,
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      };
      var totalNotificationCountConfig = {
        method: "get",
        url: `${process.env.REACT_APP_NOTIFICATION_SERVICE}/api/notification/totalNotificationCount/`,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      };

      await axios(getNotificationsConfig).then(function (response) {
        setNotifs(response.data);
        axios(totalNotificationCountConfig).then(function (response) {
          setTotalNotifCount(response.data);
        });
        axios(NotificationReadConfig);
      });
    } catch (error) {
      Swal.fire({
        text: "fonctionalité en maintenance",
        icon: "warning",
        iconColor: "#4368B1",
        confirmButtonColor: "#4368B1",
        confirmButtonText: "quitter",
      });
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    getNotifications(pageSize, page);
    dispatch(notifAmountAction(0));
    // eslint-disable-next-line
  }, [pageSize, page]);

  const totalPages = totalNotifCount / pageSize;

  return (
    <>
      <h3 className="font-bold text-[24px] mb-6 hidden md:block">
        Notifications
      </h3>
      <div className="bg-white0 h-[calc(100%-30px)] rounded-md  sm:border  sm:border-grey2">
        <div className=" h-4/5 sm:rounded-lg  md:h-[calc(100%-50px)]">
          {/* Upper navigation MOBILE*/}

          <div className="flex flex-col h-full ">
            {/* Web View */}
            {notifications ? (
              <>
                <NotificationWebVersion
                  notifs={notifications}
                ></NotificationWebVersion>
                <NotificationMobileVersion
                  notifs={notifications}
                ></NotificationMobileVersion>
              </>
            ) : (
              <div className="flex justify-center">
                <p>Chargement des notifications ..</p>
                <Spinner color={"#4368B1"}></Spinner>
              </div>
            )}
            {/* Web View */}
          </div>
          <BottomNav
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalNotifCount={totalNotifCount}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          ></BottomNav>
        </div>
      </div>
    </>
  );
};
export default Notifications;
