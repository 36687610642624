export const Chip = ({
  verified,
  children,
}) => {
  return (
    <div className={`rounded-full text-white0 px-1 w-fit text-center py-[2px] px-[12px] ${verified ? "bg-[#0FA958]" : "bg-[#EE8101]"}`}>
      {children}
    </div>
  )
};
