import React from "react";

function ImageRenderer({ image, canDeleteImage, handleDelete }) {
  const imageSource =
    typeof image.source === "string"
      ? image.source
      : URL.createObjectURL(image.source);

  return (
    <div className="relative inline-block m-2">
      <img
        src={imageSource}
        alt="image"
        className="block w-[150px] h-[100px] object-fill rounded-md"
      />

      {canDeleteImage && (
        <button
          onClick={() => handleDelete(image.id)}
          className="absolute -top-2 -left-2 text-back bg-white0 border-2 border-[#CBD5E1] rounded-full w-6 h-6 cursor-pointer"
        >
          X
        </button>
      )}
    </div>
  );
}

export default ImageRenderer;
