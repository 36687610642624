import React from "react";

const ConfirmUpload = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-20">
      <div className="bg-white rounded shadow-lg w-[450px]">
        <div className="flex flex-col justify-center p-10">
          <h2 className="text-black font-bold text-xl mb-4">Congras! 🎉</h2>
          <h2 className="text-black font-bold text-xl mb-4">
            The car was added successfully!
          </h2>

          <div className={`flex items-center justify-center mb-4 mt-4`}>
            <svg
              width="54"
              height="51"
              viewBox="0 0 54 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 51C12.0883 51 0 39.5833 0 25.5C0 11.4167 12.0883 0 27 0C41.9117 0 54 11.4167 54 25.5C53.9836 39.5769 41.9049 50.9845 27 51ZM26.9568 45.9H27C38.9251 45.8887 48.5844 36.7524 48.5784 25.4898C48.5724 14.2272 38.9035 5.10001 26.9784 5.10001C15.0533 5.10001 5.38437 14.2272 5.3784 25.4898C5.37244 36.7524 15.0317 45.8887 26.9568 45.9ZM21.6 38.25L10.8 28.05L14.607 24.4545L21.6 31.0335L39.393 14.229L43.2 17.85L21.6 38.25Z"
                fill="#282F75"
              />
            </svg>
          </div>
        </div>
        <div className="w-full flex border">
          <button
            onClick={() => window.location.replace("/cars")}
            className={`h-20 font-bold w-full px-2 py-1 text-lightblue2 w-20 border-r rounded`}
          >
            Back to Tables
          </button>

          <button
            onClick={onClose}
            className={` font-bold w-full px-2 py-1 text-lightblue2 w-20 border-r rounded`}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmUpload;
