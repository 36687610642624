export const ADD_CAR = "ADD_CAR";
export const GET_CARS = "GET_CARS";
export const GET_CAR = "GET_CAR";
export const GET_CARS_COUNT = "GET_CARS_COUNT";
export const EDIT_CAR = "EDIT_CAR";
export const CAR_EDIT_COUNT = "CAR_EDIT_COUNT";
export const ADD_CAR_COUNT = "ADD_CAR_COUNT";
export const ADD_CAR_SUCCESS = "ADD_CAR_SUCCESS";
export const EDIT_CAR_SUCCESS = "EDIT_CAR_SUCCESS";
export const OVERVIEW_IMGS = "OVERVIEW_IMGS";
export const REMOVE_IMG = "REMOVE_IMG";
export const CDD_IMAGE = "CDD_IMAGE";
export const ANALYZED_CARS_IMAGES_LOADING = "ANALYZED_CARS_IMAGES_LOADING";
export const ANALYZED_CARS_IMAGES = "ANALYZED_CARS_IMAGES";
export const ANALYZED_CARS_IMAGES_ERROR = "ANALYZED_CARS_IMAGES_ERROR";
export const ANALYZED_CARS_IMAGES_FINISHED = "ANALYZED_CARS_IMAGES_FINISHED";
export const ERROR = "ERROR";
export const EDIT_CDD_IMAGE = "EDIT_CDD_IMAGE";
export const EDIT_OVERVIEW_IMGS = "EDIT_OVERVIEW_IMGS";
export const FORM_INFO_EDIT = "FORM_INFO_EDIT";
export const CLEARSTORE = "CLEARSTORE";
export const IS_NEXT_CONFIRMED = "IS_NEXT_CONFIRMED";
export const DAMAGED_CAR_IMAGE = "DAMAGED_CAR_IMAGE";
export const DELETE_DAMAGED_CAR_IMAGE = "DELETE_DAMAGED_CAR_IMAGE";
export const UPDATE_DAMAGED_CAR_IMAGE = "UPDATE_DAMAGED_CAR_IMAGE";
export const UPLOAD_START_DAMAGED_CAR_IMAGE = "UPLOAD_START_DAMAGED_CAR_IMAGE";
export const UPLOAD_FINISH_DAMAGED_CAR_IMAGE =
  "UPLOAD_FINISH_DAMAGED_CAR_IMAGE";
export const CLEAR_DAMAGED_CAR_IMAGE = "CLEAR_DAMAGED_CAR_IMAGE";
export const DAMAGED_CAR_IMAGE_TEMPORARY = "DAMAGED_CAR_IMAGE_TEMPORARY";
export const ADD_CAR_NUMBER = "ADD_CAR_NUMBER";
export const REMAINING_ANALYSIS_RETRY_NUMBER =
  "REMAINING_ANALYSIS_RETRY_NUMBER";
