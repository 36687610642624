import StatsCubes from "../../Components/statsCubes";
import Chart from "../../Components/Chart/";
import { Fragment } from "react";
import Stats from "../Stats";
function Home() {
  return (
    <Fragment>
      <Stats StatsCubes={<StatsCubes />} Chart={<Chart></Chart>}></Stats>
    </Fragment>
  );
}

export default Home;
