import * as types from "./actionTypes";

const initialState = {
  message: [],
  loading: false,
};

export const registerUser = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case types.REGISTER_USER_SUCCESSFUL:
      return {
        message: payload.success,
        loading: true,
      };
    case types.REGISTER_USER_EXISTS:
      return {
        error: payload,
        loading: true,
      };
    case types.FIELDS_ERRORS:
      return {
        errors: payload,
        loading: true,
      };
    default:
      return state;
  }
};
export default registerUser;
