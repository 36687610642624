import React from "react";
import NeedHelp from "../needHelp/NeedHelp";
import BarChart from "../chartjs/BarChart";

const statisticCards = [
  {
    title: "Total des Annonces publiées",
    value: 500,
    smallValue: false,
  },
  {
    title: "Total des impressions",
    value: 999,
    smallValue: false,
  },
  {
    title: "Taux de conversion",
    value: "15%",
    smallValue: false,
  },
  {
    title: "Dernier refresh",
    value: "21/08/2024 15:00",
    smallValue: true,
  },
];
const barData = [
  {
    label: "Facebook",
    values: [16000, 9000, 2500],
  },
  {
    label: "Instagram",
    values: [10000, 3000, 600],
  },
  {
    label: "Snapchat",
    values: [4000, 400, 700],
  },
  {
    label: "TikTok",
    values: [2000, 1300, 300],
  },
];
const barDataSet = [
  {
    label: "Impressions",
    backgroundColor: "rgba(40, 47, 117, 0.8)",
    borderColor: "rgba(40, 47, 117, 1)",
  },
  {
    label: "Cliques",
    backgroundColor: "rgba(40, 47, 117, 0.3)",
    borderColor: "rgba(40, 47, 117, 0.5)",
  },
  {
    label: "Conversions",
    backgroundColor: "rgb(67, 104, 177, 0.8)",
    borderColor: "rgb(67, 104, 177,1)",
  },
];
const tableData = [
  {
    title: "Renault Megane 2017 12 000€",
    platform: "Facebook",
    impressions: 1599,
    clicks: 28,
    conversions: 5,
  },
  {
    title: "Peugeot 208 2018 10 500€",
    platform: "Instagram",
    impressions: 1450,
    clicks: 34,
    conversions: 7,
  },
  {
    title: "Volkswagen Golf 2016 13 800€",
    platform: "Snapchat",
    impressions: 900,
    clicks: 22,
    conversions: 4,
  },
  {
    title: "BMW X1 2019 25 000€",
    platform: "TikTok",
    impressions: 2000,
    clicks: 50,
    conversions: 10,
  },
  {
    title: "Citroën C3 2020 11 200€",
    platform: "Leboncoin",
    impressions: 1300,
    clicks: 19,
    conversions: 3,
  },
];

function Analytics() {
  return (
    <div className="overflow-y-scroll">
      <h1 className="pl-2 md:p-0 font-bold text-xl mb-5 position-fixed">
        Marketing: Analytiques
      </h1>
      <div className="xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col bg-white p-4 gap-5 mt-2">
        <NeedHelp />
        <h1 className="font-bold text-xl mb-5">Tableau de board</h1>
        <p className="text-sm ">
          Ce Dahsboard contient toutes les compagnes et les informations
          confondues - merci d’utiliser les filtres pour les besoins spécifique
        </p>
        <div className="flex flex-wrap items-center justify-center sm:justify-between gap-3 md:gap-2 mt-2">
          {statisticCards.map((card, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center p-4 gap-2 border-2 border-grey2 bg-background rounded-md w-full sm:w-[250px] h-[100px]"
            >
              <p className="text-l">{card.title}</p>
              <p
                className={`${
                  card.smallValue ? "text-sm w-[30%]" : "text-xl font-bold"
                }  text-center`}
              >
                {card.value}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-2 flex flex-col border-2 border-grey2 bg-background rounded-md p-4 w-full h-full ">
          <h1 className="ml-10 my-8 font-bold text-xl">
            Performances par Plateformes
          </h1>
          <div className="h-[500px]">
            <BarChart
              data={barData}
              dataSet={barDataSet}
              positionLegend={"bottom"}
              displayLegend
            />
          </div>
        </div>
        <div className="mt-2 flex flex-col border-2 border-grey2 bg-background rounded-md p-4 w-full h-full ">
          <h1 className="ml-10 my-8 font-bold text-xl">Meilleures Annonces</h1>
          <table class="table-auto">
            <thead>
              <tr>
                <th className="text-start font-normal">Title</th>
                <th className="text-start font-normal">Plateforme</th>
                <th className="text-start font-normal">Impressions</th>
                <th className="text-start font-normal">Cliques</th>
                <th className="text-start font-normal">Conversions</th>
              </tr>
              <div className="my-4"></div>
              <tr>
                <td colSpan="5" className="border-b-2 border-black"></td>
              </tr>
              <div className="my-4"></div>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td className="text-sm">{row.title}</td>
                  <td className="text-sm">{row.platform}</td>
                  <td className="text-sm">{row.impressions}</td>
                  <td className="text-sm">{row.clicks}</td>
                  <td className="text-sm">{row.conversions}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
