import React from "react";

function WarningIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2654 20.9981H2.7324C2.37514 20.9981 2.04502 20.8075 1.86639 20.4981C1.68776 20.1887 1.68777 19.8075 1.8664 19.4981L11.1324 3.49811C11.3112 3.18923 11.641 2.99902 11.9979 2.99902C12.3548 2.99902 12.6846 3.18923 12.8634 3.49811L22.1294 19.4981C22.3079 19.8074 22.308 20.1883 22.1297 20.4977C21.9513 20.807 21.6215 20.9977 21.2644 20.9981H21.2654ZM10.9994 15.9981V17.9981H11.9324H11.9974H12.0624H12.9974V15.9981H10.9994ZM10.9994 8.99811V13.9981H12.9994V8.99811H10.9994Z"
        fill="#FFAB00"
      />
    </svg>
  );
}

export default WarningIcon;
