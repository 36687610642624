import React from "react";
import { ReactComponent as FlowBuilderIcon } from "../../../Assets/agent-ai/flow-builder.svg";
import { ReactComponent as ChatIcon } from "../../../Assets/agent-ai/chat.svg";
import { useLocation, useNavigate } from "react-router";
export const SteerAiRouter = {
  chat: 1,
  flowBuilder: 2,
};
function SteerAiSwitcher() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const isChat =
    pathname === "/steer-ai" || pathname.startsWith("/steer-ai/continue");
  const isOs = pathname === "/steer-ai/os";
  const onSwitch = (handler) => () => {
    if (handler === SteerAiRouter.chat) {
      navigate("/steer-ai");
    }
    if (handler === SteerAiRouter.flowBuilder) {
      navigate("/steer-ai/os");
    }
    return;
  };
  return (
    <div className="flex flex-row gap-4 items-center">
      <span
        className={`cursor-pointer ${
          isOs && "bg-white0 border-2 border-[#CBD5E1] p-1 rounded-full"
        }`}
        onClick={onSwitch(SteerAiRouter.flowBuilder)}
      >
        <FlowBuilderIcon />
      </span>
      <span
        className={`cursor-pointer ${
          isChat && "bg-white0 border-2 border-[#CBD5E1] p-1 rounded-full"
        }`}
        onClick={onSwitch(SteerAiRouter.chat)}
      >
        <ChatIcon />
      </span>
    </div>
  );
}

export default SteerAiSwitcher;
