import React from "react";
import VerticalStepper from "../../stepper/verticalStepper/verticalStepper";
import { steps } from "../common/steps";
import BaseStepper from "../CampaignStepper/baseStepper";
import ConfirmChosenCar from "../CampaignStepper/modals/ConfirmChosenCar";
import useMediaQuery from "../../../hooks/useMediaQuery";
import HeaderCampaignStepper from "../CampaignStepper/headerStepper";

function ConfigureCampaign({
  goBack,
  chosenConfiguration,
  handleOpenChooseConfigurationModal,
  campaignStep,
  handleStepBack,
  handleStepNext,
  handleGoToAnalytics,
  chosenCars,
}) {
  const isSmallerThanLarge = useMediaQuery(1280);
  return (
    <div className="flex flex-col xl:flex-row  justify-between gap-8">
      {isSmallerThanLarge && (
        <HeaderCampaignStepper activeStep={campaignStep} marginBottom={2} />
      )}
      <div className="bg-white0 w-full h-fit  mx-auto xl:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 xxl:overflow-hidden">
        <VerticalStepper
          steps={steps}
          stepAnalysis={campaignStep}
          handleBack={handleStepBack}
          handleNext={handleStepNext}
          goBack={goBack}
        />
      </div>
      <div className="sm:p-2 max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col pb-20">
        <BaseStepper
          activeStep={campaignStep}
          chosenConfiguration={chosenConfiguration}
          handleOpenChooseConfigurationModal={
            handleOpenChooseConfigurationModal
          }
          handleGoToAnalytics={handleGoToAnalytics}
        />
      </div>
      <ConfirmChosenCar
        isOpen={campaignStep === 0}
        onContinue={handleStepNext}
        onBack={goBack}
        cars={chosenCars}
      />
    </div>
  );
}

export default ConfigureCampaign;
